import { SubscriptionStatus } from "@ppc/webcore/dist/data/api/app/paidServices/getLocationSubscriptionsApiResponse";
import {
  NewPurchaseInfo,
  ServicePlans,
  Subscriptions,
  UpdatePurchaseInfo,
  UserSubscriptionTransactions
} from "@ppc/webcore/dist/data/services/subscriptionsService";
import WebCore from "./webcoreService";
import {
  GetUserPaymentProfilesApiResponse,
  PaymentProfileType
} from "@ppc/webcore/dist/data/api/app/paidServices/getUserPaymentProfilesApiResponse";
import { ApiResponseBase } from "@ppc/webcore/dist/data/models/apiResponseBase";
import { brandingService } from "./brandingService";

class SubscriptionsService {

  /**
   * Gets currently authenticated user's subscriptions.
   * @param {SubscriptionStatus} status Service plan status filter.
   * @returns {Promise<Subscriptions>}
   */
  public getUserSubscriptions(status?: SubscriptionStatus): Promise<Subscriptions> {
    // TODO cache for 1 min
    return WebCore.services.subscriptions.getCurrentUserSubscriptions(status, true);
  }

  public getSubscription(subscriptionId: number, locationId?: number) {
    return WebCore.services.subscriptions.getLocationSubscriptions(locationId,
      undefined,
      subscriptionId,
      undefined,
      true);
  }

  /**
   * Gets specified location/user subscriptions information. Mostly used by administrators.
   * Non-admin users will not be allowed to get information for other users.
   * @param {number} [locationId] Get plan on this location.
   * @param {SubscriptionStatus} [status] Service plan status filter.
   * @param {boolean} [getCard] Retrieve payment card information from the payment provider.
   * @returns {Promise<Subscriptions>}
   */
  public getLocationSubscriptions(locationId?: number,
                                  status?: SubscriptionStatus,
                                  getCard?: boolean
  ): Promise<Subscriptions> {
    return WebCore.services.subscriptions
      .getLocationSubscriptions(locationId, undefined, undefined, status, getCard);
  }

  /**
   * Gets available service plans for the user at specified location.
   * @param {number} locationId Required to get data for specific location.
   * @returns {Promise<ServicePlans>}
   */
  getServicePlans(locationId: number): Promise<ServicePlans> {
    return WebCore.services.subscriptions
      .getAvailableServicePlans(locationId, undefined, undefined, brandingService.currentBrand.id);
  }

  /**
   * Gets payment transactions history for the specified subscription (service plan).
   * @param {number} servicePlanId Subscription ID to get information for.
   * @param {number} [locationId] ID of the particular location to get information for.
   * @param {boolean} [upgradeDowngradeEventsOnly] Flag indicating we want to get only uprade/downgrade events history.
   * @returns {Promise<UserSubscriptionTransactions>}
   */
  getSubscriptionTransactions(servicePlanId: number,
                              locationId?: number,
                              upgradeDowngradeEventsOnly?: boolean): Promise<UserSubscriptionTransactions> {
    return WebCore.services.subscriptions.getSubscriptionTransactions(servicePlanId, locationId, upgradeDowngradeEventsOnly);
  }

  /**
   * Returns existing payment profiles if related customer already exists in Chargify and have any valid payment profiles.
   * The customer in Chargify is uniquely identified by the user ID (specified in the parameters or identified by the API key),
   * however, you can specify the "customerId" if you know it and want to conduct additional verification.
   * @param params Request parameters.
   * @param {string} [params.customerId] Customer ID from external payment service (if known)
   * @param {boolean} [params.includeDisabled] Include disabled profiles into the result. Default is false.
   */
  getUserPaymentProfiles(params?: { customerId?: string, includeDisabled?: boolean })
    : Promise<GetUserPaymentProfilesApiResponse> {
    return WebCore.services.subscriptions.getUserPaymentProfiles({
      ...(params || {}),
      paymentType: PaymentProfileType.Chargify,
      appName: brandingService.currentBrand.id
    });
  }

  /**
   * Provide purchase information like a subscription ID or a receipt or a nonce, which proofs that the purchase has been made.
   * @param {NewPurchaseInfo} purchaseInfo Purchase information
   * @param {number} priceId Service plan price ID
   * @param {number} locationId Location ID
   * @param {number} [userId] Purchase as specific user
   * @param {boolean} [sandbox] Set to true, if need to test the process on sandbox payment provider service
   * @returns {Promise<ApiResponseBase>}
   */
  public provideNewPurchaseInfo(purchaseInfo: NewPurchaseInfo,
                                priceId: number,
                                locationId: number,
                                userId?: number,
                                sandbox?: boolean)
    : Promise<ApiResponseBase> {
    return WebCore.services.subscriptions.provideNewPurchaseInfo(purchaseInfo, priceId, locationId, userId, sandbox);
  }

  /**
   * Cancels specified subscription for the specified location.
   * @param {number} servicePlanId The Service Plan ID to cancel.
   * @param {number} locationId Location ID to cancel the service plan.
   * @returns {Promise<ApiResponseBase>}
   */
  public cancelSubscription(servicePlanId: number, locationId: number): Promise<ApiResponseBase> {
    return WebCore.services.subscriptions.cancelSubscription(servicePlanId, locationId);
  }

  /**
   * Upgrades the specified user plan (subscription) to the target plan (subscription).
   * @param {number} userPlanId Existing paid plan ID
   * @param {number} targetPlanId New service plan ID
   * @returns {Promise<ApiResponseBase>}
   */
  public upgradeSubscription(userPlanId: number, targetPlanId: number): Promise<ApiResponseBase> {
    return WebCore.services.subscriptions.upgradeSubscription(userPlanId, targetPlanId);
  }

  /**
   * Update purchase information like a receipt or a subscription ID for an existing user service plan,
   *   or update payment profile for existing subscription if 'paymentToken' is specified.
   * @param {UpdatePurchaseInfo} updatePurchaseInfo Subscription details.
   * @param {number} userPlanId The User Service Plan ID to update.
   * @param {number} locationId Location ID.
   * @returns {Promise<ApiResponseBase>}
   */
  public updatePurchaseInfo(updatePurchaseInfo: UpdatePurchaseInfo,
                            userPlanId: number,
                            locationId: number)
    : Promise<ApiResponseBase> {
    return WebCore.services.subscriptions.updatePurchaseInfo(updatePurchaseInfo, userPlanId, locationId);
  }
}

export const subscriptionsService = new SubscriptionsService();
