import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import FormController, { FieldController } from "../../../env/FormController";
import { Alert, Button, Input, InputGroup, Text } from "sancho";
import AuthService from "../../../services/authService";
import UserService from "../../../services/userService";
import Utils from "../../../services/utils";
import WebCore from "../../../services/webcoreService";
import 'react-phone-number-input/style.css';
import { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input/mobile';
import PhoneInput from 'react-phone-number-input/input';
import FlagComponent from 'react-phone-number-input/modules/Flag';
import theme from "../../../theme";
import ButtonContainer from "../../structural/ButtonContainer/ButtonContainer";
import { brandingService } from "../../../services/brandingService";

const RESEND_PASSCODE_INTERVAL = 60;
const DEFAULT_PHONE_COUNTRY = "US";

export class UsernamePasscodeForm extends React.Component<UsernamePasscodeFormProps, UsernamePasscodeFormState> {

  private readonly usernameForm: FormController;
  private readonly passcodeForm: FormController;

  private usernameInputRef = React.createRef<HTMLInputElement>();
  private passcodeInputRef = React.createRef<HTMLInputElement>();

  private showResendButtonTimeoutIndex?: number;

  private secondsUntilNextPasscodeSend: number = RESEND_PASSCODE_INTERVAL;

  constructor(props: UsernamePasscodeFormProps) {
    super(props);

    const {t, user} = this.props;

    this.onUsernameSubmit = this.onUsernameSubmit.bind(this);
    this.onPasscodeSubmit = this.onPasscodeSubmit.bind(this);

    this.handleUsernameSubmit = this.handleUsernameSubmit.bind(this);
    this.handlePasscodeSubmit = this.handlePasscodeSubmit.bind(this);

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasscodeChange = this.handlePasscodeChange.bind(this);

    this.handleResendPasscodeButtonClick = this.handleResendPasscodeButtonClick.bind(this);
    this.handleChangeUsernameButtonClick = this.handleChangeUsernameButtonClick.bind(this);

    this.state = {
      loading: false,
      uiState: "username",
      allowPasscodeSend: true,
      country: DEFAULT_PHONE_COUNTRY,
      resendCountdown: 0
    };

    this.usernameForm = new FormController({
      onSubmit: this.onUsernameSubmit,
      validators: [],
      fields: {
        username: {
          value: user || "",
          validators: [
            {
              message: t("login.validation.passcodeUsername.empty"),
              validate: (fieldCtrl: FieldController<string>) => !!fieldCtrl.value
            },
            {
              message: t("login.validation.passcodeUsername.invalid"),
              validate: (fieldCtrl: FieldController<string>) => isPossiblePhoneNumber(fieldCtrl.value)
            }
          ]
        }
      }
    });

    this.passcodeForm = new FormController({
      onSubmit: this.onPasscodeSubmit,
      validators: [],
      fields: {
        passcode: {
          value: "",
          validators: [
            {
              message: t("login.validation.passcode.empty"),
              validate: (fieldCtrl: FieldController<string>) => !!fieldCtrl.value
            }
          ]
        }
      }
    });
  }

  componentDidMount() {
    //this.usernameInputRef.current!.focus();
    this.initPasscodeTimeout();
  }

  public focus() {
    setTimeout(() => {
      if (this.state.uiState === "username") {
        this.usernameInputRef.current && this.usernameInputRef.current.focus();
      } else if (this.state.uiState === "passcode") {
        this.passcodeInputRef.current && this.passcodeInputRef.current.focus();
      }
    });
  }

  private initPasscodeTimeout() {
    let timeout = parseInt(WebCore.services.localStorage.get("passcodeTimeout"));
    if (timeout && timeout > 0) {
      this.secondsUntilNextPasscodeSend = timeout;
      return;
    }

    UserService.getUserOrSystemPropertyValue('ppc.account.passcode.timeout')
      .then((data: string) => {
        let timeout = parseInt(data);
        if (timeout && timeout > 0) {
          this.secondsUntilNextPasscodeSend = timeout;
          WebCore.services.localStorage.set("passcodeTimeout", timeout);
        }
      });
  }

  componentWillUnmount() {
    this.stopResendCountdown();
  }

  private onUsernameSubmit() {
    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
      error: undefined
    });
    this.props.onStartLoading && this.props.onStartLoading();
    this.stopResendCountdown();

    AuthService.sendPasscode(this.usernameForm.fields.username.value, brandingService.currentBrand.id)
      .then(() => {
        this.setState({
          uiState: "passcode",
          error: undefined
        });
        this.focus();
      })
      .catch((err) => {
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState({
          error: {
            title: errTitle,
            desc: errDesc
          }
        });
      })
      .then(() => {
        this.setState({
          allowPasscodeSend: true,
          loading: false
        });
        this.startResendCountdown();
        this.props.onEndLoading && this.props.onEndLoading();
      });
  }

  private stopResendCountdown() {
    if (this.showResendButtonTimeoutIndex != null) {
      clearInterval(this.showResendButtonTimeoutIndex);
      this.showResendButtonTimeoutIndex = undefined;
    }
  }

  private startResendCountdown() {
    this.stopResendCountdown();
    this.setState({resendCountdown: this.secondsUntilNextPasscodeSend});

    // @ts-ignore
    this.showResendButtonTimeoutIndex = setInterval(() => {
      this.setState(prevState => {
        if (prevState.resendCountdown <= 0) {
          this.stopResendCountdown();
          return ({
            ...prevState,
            resendCountdown: 0
          });
        }
        return ({
          ...prevState,
          resendCountdown: prevState.resendCountdown - 1
        });
      });
    }, 1000);
  }

  private onPasscodeSubmit() {
    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
      error: undefined
    });
    this.props.onStartLoading && this.props.onStartLoading();

    AuthService.loginByPasscode(this.usernameForm.fields.username.value, this.passcodeForm.fields.passcode.value)
      .then(() => {
        this.stopResendCountdown();
        this.setState({
          loading: false
        });
        this.props.onEndLoading && this.props.onEndLoading();
        this.props.onLogin();
      })
      .catch((err) => {
        let errTitle, errDesc;
        if (err && err.resultCode === 12) {
          errTitle = "login.error.wrongPasscode";
          errDesc = "";
        } else {
          [errTitle, errDesc] = Utils.parseApiError(err);
        }

        this.setState({
          error: {
            title: errTitle,
            desc: errDesc
          },
          loading: false
        });
        this.props.onEndLoading && this.props.onEndLoading();
      });
  }

  private handleUsernameSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (this.state.loading || this.props.disabled) {
      return;
    }
    this.usernameForm.submit(event);
    this.forceUpdate();
  }

  private handleResendPasscodeButtonClick() {
    if (this.state.loading || this.props.disabled) {
      return;
    }
    this.onUsernameSubmit();
  }

  private handleChangeUsernameButtonClick() {
    if (this.state.loading || this.props.disabled) {
      return;
    }
    this.stopResendCountdown();
    this.passcodeForm.drop();
    this.setState(prevState => ({
      ...prevState,
      uiState: "username",
      error: undefined
    }));
    this.focus();
  }

  private handlePasscodeSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (this.state.loading || this.props.disabled) {
      return;
    }
    this.passcodeForm.submit(event);
    this.forceUpdate();
  }

  private handleUsernameChange(value: string) {
    this.usernameForm.fields.username.value = value;

    try {
      const phoneNumber = parsePhoneNumber(value);
      if (phoneNumber) {
        this.setState({country: phoneNumber.country || DEFAULT_PHONE_COUNTRY});
      }
    } catch (e) {
      this.setState({country: DEFAULT_PHONE_COUNTRY});
    }

    this.forceUpdate()
  }

  private handlePasscodeChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.passcodeForm.fields.passcode.value = (event.target.value || '').toUpperCase();
    this.forceUpdate();
  }

  render() {
    let {t} = this.props;

    let error;
    if (this.state.error) {
      error = (
        <Alert
          style={{marginTop: theme.spaces.md}}
          elevation="xs"
          intent="danger"
          title={t(this.state.error.title)}
          subtitle={t(this.state.error.desc)}/>
      );
    }

    if (this.state.uiState === "username") {
      return (
        <form className="UsernamePasscodeForm"
              onSubmit={this.handleUsernameSubmit}
              aria-disabled={this.state.loading || this.props.disabled}>

          <Text gutter={false}>
            {t("login.form.passcodeHelpText")}
          </Text>

          <InputGroup hideLabel
                      error={this.usernameForm.submitted && this.usernameForm.fields.username.validationMessages[0]}
                      label={t("login.form.passcodeUsername.label")}>

            <div className="PhoneInputContainer">
              <FlagComponent country={this.state.country}
                             countryName={this.state.country}
                             flagUrl="/assets/images/country-flag-icons/{XX}.svg"/>

              <PhoneInput name="passcodeUsername"
                          className="PhoneInput"
                          value={this.usernameForm.fields.username.value}
                          ref={this.usernameInputRef}
                          onChange={this.handleUsernameChange}
                          defaultCountry={DEFAULT_PHONE_COUNTRY}
                          placeholder={t("login.form.passcodeUsername.label")}
                          disabled={this.state.loading || this.props.disabled}/>
            </div>
          </InputGroup>

          <ButtonContainer>
            <Button size="lg"
                    component="button"
                    type="submit"
                    intent="primary"
                    disabled={this.state.loading || this.props.disabled || !this.state.allowPasscodeSend}
                    loading={this.state.loading}>
              {t("login.form.sendPasscodeButton")}
            </Button>
            <Button size="lg"
                    variant="ghost"
                    disabled={this.state.loading || this.props.disabled}
                    onClick={() => this.props.onGoToPassword && this.props.onGoToPassword()}>
              {t("login.form.goToPasswordButton")}
            </Button>
          </ButtonContainer>

          {error}

        </form>
      );
    } else if (this.state.uiState === "passcode") {
      return (
        <form className="UsernamePasscodeForm"
              onSubmit={this.handlePasscodeSubmit}
              aria-disabled={this.state.loading || this.props.disabled}>

          <Text gutter={false}>
            {t("login.form.passcodeSendHelpText", {phone: `**${this.usernameForm.fields.username.value.slice(-2)}`})}
          </Text>

          <InputGroup hideLabel
                      error={this.passcodeForm.submitted && this.passcodeForm.fields.passcode.validationMessages[0]}
                      label={t("login.form.passcode.label")}>
            {/*
                  // @ts-ignore TypeScript don't see ref in sancho's Input */}
            <Input ref={this.passcodeInputRef}
                   inputSize="lg"
                   type="text"
                   name="passcode"
                   autoComplete="off"
                   value={this.passcodeForm.fields.passcode.value}
                   onChange={this.handlePasscodeChange}
                   placeholder={t("login.form.passcode.label")}
                   disabled={this.state.loading || this.props.disabled}/>
          </InputGroup>

          <ButtonContainer>
            <Button size="lg"
                    component="button"
                    type="submit"
                    intent="primary"
                    disabled={this.state.loading || this.props.disabled}
                    loading={this.state.loading}>
              {t("login.form.signInButton")}
            </Button>

            {this.state.allowPasscodeSend && !this.state.loading && (
              <>
                {!!this.state.resendCountdown && this.state.resendCountdown > 0 && (
                  <Button size="lg"
                          variant="ghost"
                          disabled={true}>
                    {t("login.form.resendPasscodeIn", {count: this.state.resendCountdown})}
                  </Button>
                )}

                {this.state.resendCountdown <= 0 && (
                  <Button size="lg"
                          variant="ghost"
                          disabled={this.state.loading || this.props.disabled}
                          onClick={() => this.handleResendPasscodeButtonClick()}>
                    {t("login.form.resendPasscode")}
                  </Button>
                )}
              </>
            )}

            <Button size="lg"
                    variant="ghost"
                    disabled={this.state.loading || this.props.disabled}
                    onClick={() => this.handleChangeUsernameButtonClick()}>
              {t("login.form.changeUsernameButton")}
            </Button>
          </ButtonContainer>

          {error}

        </form>
      );
    }

    return null;
  }
}

interface UsernamePasscodeFormProps extends WithTranslation {
  user?: string;
  onLogin: () => void;
  onStartLoading?: () => void;
  onEndLoading?: () => void;
  onGoToPassword?: () => void;
  disabled?: boolean;
}

interface UsernamePasscodeFormState {
  loading: boolean;
  country: string;
  uiState: 'username' | 'passcode';
  allowPasscodeSend: boolean;
  resendCountdown: number;
  error?: {
    title: string,
    desc: string
  }
}

export default withTranslation(undefined, {withRef: true})(UsernamePasscodeForm)
