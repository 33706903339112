import React, { Component } from "react";
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import { WithTranslation, withTranslation } from "react-i18next";
import QueryService from "../../services/queryService";
import { CommonUrlParams } from "../../env/models/CommonUrlParams";
import { Alert, Button, Spinner, Text, Container } from "sancho";
import PasswordRenewResultForm from "../../components/login/PasswordRenewResultForm/PasswordRenewResultForm";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { LoginUrlParams } from "../Login/LoginPage";
import CenterPageContent from "../../components/structural/CenterPageContent/CenterPageContent";
import ButtonContainer from "../../components/structural/ButtonContainer/ButtonContainer";

class PasswordRenewResultPage extends Component<PasswordRenewResultPageProps, PasswordRenewResultPageState> {

  private readonly urlParams: PasswordRenewResultPageUrlParams;

  constructor(props: PasswordRenewResultPageProps) {
    super(props);

    this.state = {
      uiState: "initial"
    };

    this.onPasswordRenew = this.onPasswordRenew.bind(this);

    this.urlParams = QueryService.getQueryParams(true) as PasswordRenewResultPageUrlParams;
  }

  componentDidMount() {
    this.processUrlParams();
  }

  processUrlParams() {
    //if (!this.urlParams.tempKey) {
    // TODO redirect to "/password" if no key provided like said in docs
    //}

    let errorMsg: ResultCodeError | undefined;
    if (!this.urlParams.resultCode || !this.urlParams.tempKey) {
      // empty result code
      errorMsg = "linkNotValid";
    } else if (this.urlParams.resultCode === '1') {
      // temporary unavailable
      errorMsg = "temporaryUnavailable"
    } else if (this.urlParams.resultCode === '2') {
      // link expired
      errorMsg = "linkExpired";
    } else if (this.urlParams.resultCode !== '0') {
      // unknown result code
      errorMsg = "linkNotValid";
    }

    if (errorMsg) {
      this.setState(prevState => ({
        ...prevState,
        uiState: "resultCodeError",
        resultCodeError: errorMsg!
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        uiState: "renewPassword",
        tempApiKey: this.urlParams.tempKey
      }));
    }
  }

  onPasswordRenew() {
    this.setState(prevState => ({
      ...prevState,
      uiState: "renewResult"
    }));
    this.handleRedirect();
  }

  handleRedirect() {
    if (this.urlParams && this.urlParams.redirectTo) {
      this.props.history.push(this.urlParams.redirectTo);
    }
  }

  private goToLogin() {
    let {history} = this.props;
    let params: LoginUrlParams = {
      user: this.urlParams.user || undefined
    };
    history.push(`/login${QueryService.encodeQueryParams(params)}`);
  }

  private gotoResetPassword() {
    let {history} = this.props;
    let params: LoginUrlParams = {
      user: this.urlParams.user || undefined
    };
    history.push(`/password${QueryService.encodeQueryParams(params)}`);
  }

  render() {
    const {t} = this.props;
    let ui;

    if (this.state.uiState === "initial") {
      ui = (
        <Spinner center delay={100} size="xl"/>
      );
    } else if (this.state.uiState === "resultCodeError" && this.state.resultCodeError) {
      ui = (
        <>
          <div>
            <Alert intent="danger"
                   title={t(`passwordRenewResult.errors.${this.state.resultCodeError}.header`)}
                   subtitle={t(`passwordRenewResult.errors.${this.state.resultCodeError}.desc`)}
                   elevation="xs"/>
            <ButtonContainer>
              <Button size="lg"
                      variant="ghost"
                      onClick={() => this.gotoResetPassword()}>
                {t("passwordRenewResult.startAgainBtn")}
              </Button>
            </ButtonContainer>
          </div>
        </>
      );
    } else if (this.state.uiState === "renewResult") {
      ui = (
        <>
          <Alert intent="success"
                 title={t(`passwordRenewResult.messages.success.header`)}
                 subtitle={t(`passwordRenewResult.messages.success.desc`)}
                 elevation="xs"/>
          <Container>
            <Text>{t(`passwordRenewResult.messages.success.helper`)}</Text>
          </Container>
        </>
      );
    } else if (this.state.uiState === "renewPassword" && this.state.tempApiKey) {
      ui = (
        <>
          <PasswordRenewResultForm onRenew={this.onPasswordRenew} apiKey={this.state.tempApiKey!}/>
        </>
      );
    } else {
      ui = (
        <Alert intent="danger"
               title={t(`passwordRenewResult.errors.unknown.header`)}
               subtitle={t(`passwordRenewResult.errors.unknown.desc`)}
               elevation="xs"/>
      );
    }

    return (
      <BrandedPageShell withLogo>
        <CenterPageContent>
          {ui}
        </CenterPageContent>
      </BrandedPageShell>
    );
  }
}

interface PasswordRenewResultPageProps extends WithTranslation, RouteComponentProps {

}

interface PasswordRenewResultPageState {
  tempApiKey?: string;
  uiState: UiState;
  resultCodeError?: ResultCodeError;
}

type ResultCodeError = 'linkExpired' | 'temporaryUnavailable' | 'linkNotValid';
type UiState = 'initial' | 'resultCodeError' | 'renewPassword' | 'renewResult';

interface PasswordRenewResultPageUrlParams extends CommonUrlParams {
  /**
   * number, mandatory
   * 'resultCode' from API response
   *   0 - success
   *   1 - temporary unavailable
   *   2 - link expired
   */
  resultCode: string;

  /**
   * Username for login
   */
  user?: string;

  /**
   * URL ro redirect to after password reset
   */
  redirectTo?: string;
}

export default withTranslation()(withRouter(PasswordRenewResultPage));
