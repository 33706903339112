import React, { Component } from "react";
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import { WithTranslation, withTranslation } from "react-i18next";
import QueryService from "../../services/queryService";
import { CommonUrlParams } from "../../env/models/CommonUrlParams";
import Story from "../../components/Story/Story";
import CenterPageContent from "../../components/structural/CenterPageContent/CenterPageContent";

class TermsOfServicePage extends Component<TermsOfServicePageProps, TermsOfServicePageState> {

  private readonly urlParams: TermsOfServicePageUrlParams;

  constructor(props: TermsOfServicePageProps) {
    super(props);
    this.urlParams = QueryService.getQueryParams() as TermsOfServicePageUrlParams;
  }

  componentDidMount() {

  }

  render() {
    const {i18n} = this.props;
    return (
      <BrandedPageShell>
        <CenterPageContent>
          <Story lang={i18n.language} storyType={40}/>
        </CenterPageContent>
      </BrandedPageShell>
    );
  }
}

interface TermsOfServicePageProps extends WithTranslation {

}

interface TermsOfServicePageState {

}

interface TermsOfServicePageUrlParams extends CommonUrlParams {

}

export default withTranslation()(TermsOfServicePage);
