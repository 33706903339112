import React, { KeyboardEvent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import FormController, { FieldController } from "../../../env/FormController";
import AuthService from "../../../services/authService";
import Utils from "../../../services/utils";
import { Alert, Button, Input, InputGroup, Link, Text } from "sancho";
import { Link as ReactRouterLink } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { withRouterAndRef } from "../../../env/HOCs/withRouterAndRef.js";
import theme from "../../../theme";
import ButtonContainer from "../../structural/ButtonContainer/ButtonContainer";

export class UsernamePasswordForm extends React.Component<UsernamePasswordFormProps, UsernamePasswordFormState> {

  private readonly form: FormController;

  private usernameInputRef = React.createRef<HTMLInputElement>();
  private passwordInputRef = React.createRef<HTMLInputElement>();

  constructor(props: UsernamePasswordFormProps) {
    super(props);

    const {t, user} = this.props;

    this.onSubmit = this.onSubmit.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleUsernameKeyPress = this.handleUsernameKeyPress.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);

    this.state = {
      loading: false
    };

    this.form = new FormController({
      onSubmit: this.onSubmit,
      validators: [],
      fields: {
        username: {
          value: user || "",
          validators: [
            {
              message: t("login.validation.username.empty"),
              validate: (fieldCtrl: FieldController<string>) => !!fieldCtrl.value
            }
          ]
        },
        password: {
          value: "",
          validators: [
            {
              message: t("login.validation.password.empty"),
              validate: (fieldCtrl: FieldController<string>) => !!fieldCtrl.value
            }
          ]
        }
      }
    });
  }

  focus() {
    setTimeout(() => {
      if (this.props.user) {
        this.passwordInputRef.current && this.passwordInputRef.current!.focus();
      } else {
        this.usernameInputRef.current && this.usernameInputRef.current!.focus();
      }
    });
  }

  componentDidMount() {

  }

  private onSubmit() {
    if (this.state.loading) {
      return;
    }

    this.setState(prevState => ({
      ...prevState,
      loading: true,
      error: undefined
    }));
    this.props.onStartLoading && this.props.onStartLoading();

    AuthService.login(this.form.fields.username.value, this.form.fields.password.value)
      .then(() => {
        this.props.onEndLoading && this.props.onEndLoading();
        this.props.onLogin && this.props.onLogin();
      }, (err: any) => {
        this.props.onEndLoading && this.props.onEndLoading();
        console.warn(err);
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState(prevState => ({
          ...prevState,
          loading: false,
          error: {
            title: errTitle,
            desc: errDesc
          }
        }));
      });
  }

  private handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (this.state.loading || this.props.disabled) {
      return;
    }
    this.form.submit(event);
    this.forceUpdate();
  }

  private handleUsernameKeyPress(event: KeyboardEvent<HTMLInputElement>) {
    if (Utils.ifEnterPressed(event)) {
      if (this.passwordInputRef.current && this.passwordInputRef.current.focus && (!event.currentTarget || event.currentTarget.value)) {
        this.passwordInputRef.current.focus();
      }
    }
  }

  private handleUsernameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.form.fields.username.value = event.target.value;
    this.forceUpdate()
  }

  private handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.form.fields.password.value = event.target.value;
    this.forceUpdate()
  }

  render() {
    let {t, location} = this.props;

    let error;
    if (this.state.error) {
      error = (
        <Alert style={{marginTop: theme.spaces.md}}
               elevation="xs"
               intent="danger"
               title={t(this.state.error.title)}
               subtitle={t(this.state.error.desc)}/>
      );
    }

    let forgotPwdLink = `password?redirectTo=${encodeURIComponent(location.pathname + location.search)}`;
    if (this.form.fields.username.value) {
      forgotPwdLink = `${forgotPwdLink}&user=${encodeURIComponent(this.form.fields.username.value)}`;
    }

    return (
      <form className="UsernamePasswordForm"
            onSubmit={this.handleFormSubmit}
            aria-disabled={this.state.loading || this.props.disabled}>

        <InputGroup hideLabel
                    className="InputGroup"
                    error={this.form.submitted && this.form.fields.username.validationMessages[0]}
                    label={t("login.form.username.label")}>
          {/*
                  // @ts-ignore TypeScript don't see ref in sancho's Input */}
          <Input ref={this.usernameInputRef}
                 className="Input Username"
                 inputSize="lg"
                 type="text"
                 name="username"
                 value={this.form.fields.username.value}
                 onChange={this.handleUsernameChange}
                 onKeyPress={this.handleUsernameKeyPress}
                 placeholder={t("login.form.username.label")}
                 disabled={this.state.loading || this.props.disabled}/>
        </InputGroup>

        <InputGroup hideLabel
                    className="InputGroup"
                    error={this.form.submitted && this.form.fields.password.validationMessages[0]}
                    label={t("login.form.password.label")}>
          {/*
                  // @ts-ignore TypeScript don't see ref in sancho's Input */}
          <Input ref={this.passwordInputRef}
                 className="Input Password"
                 inputSize="lg"
                 type="password"
                 name="password"
                 value={this.form.fields.password.value}
                 onChange={this.handlePasswordChange}
                 placeholder={t("login.form.password.label")}
                 disabled={this.state.loading || this.props.disabled}/>
        </InputGroup>

        <div className="ForgotPasswordLinkContainer">
          <Text gutter={false} variant="lead">
            <Link component={ReactRouterLink} to={forgotPwdLink}>
              {t("login.form.forgotPassword")}
            </Link>
          </Text>
        </div>

        <ButtonContainer>
          <Button size="lg"
                  component="button"
                  type="submit"
                  intent="primary"
                  disabled={this.state.loading || this.props.disabled}
                  loading={this.state.loading}>
            {t("login.form.signInButton")}
          </Button>
          <Button size="lg"
                  variant="ghost"
                  disabled={this.state.loading || this.props.disabled}
                  onClick={() => this.props.onGoToPasscode && this.props.onGoToPasscode()}>
            {t("login.form.goToPasscodeButton")}
          </Button>
        </ButtonContainer>

        {error}

      </form>
    );
  }
}

interface UsernamePasswordFormProps extends WithTranslation, RouteComponentProps {
  user?: string;
  onLogin: () => void;
  onStartLoading?: () => void;
  onEndLoading?: () => void;
  onGoToPasscode?: () => void;
  disabled?: boolean;
}

interface UsernamePasswordFormState {
  loading: boolean;
  error?: {
    title: string,
    desc: string
  }
}

// @ts-ignore
export default withTranslation(undefined, {withRef: true})(withRouterAndRef(UsernamePasswordForm));
