import React, { Component, RefObject } from 'react';
import { Chargify } from "./Chargify";
import "./ChargifyForm.scss";
import { Alert, Button, Skeleton, Text } from "sancho";
import { withTranslation, WithTranslation } from "react-i18next";
import ButtonContainer from "../../structural/ButtonContainer/ButtonContainer";
import userService from "../../../services/userService";
import Utils from "../../../services/utils";
import theme from '../../../theme';

const CHARGIFY_JS_PUBLIC_KEY_PREFIX = "chargify-js-public-key.";

class ChargifyForm extends Component<ChargifyFormProps, ChargifyFormState> {

  private readonly chargifyForm: RefObject<HTMLFormElement>;
  private chargify: Chargify;
  private chargifyPublicKey?: string;

  constructor(props: ChargifyFormProps) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.chargifyForm = React.createRef<HTMLFormElement>();

    // @ts-ignore
    this.chargify = new window.Chargify();

    this.state = {
      initialLoading: true,
      loading: false,
      uiState: "main"
    };
  }

  componentDidMount() {
    let {t} = this.props;

    if (!this.props.chargifySite) {
      this.setState({
        initialLoading: false,
        uiState: "error",
        error: {
          title: t('purchase.chargifyForm.noChargifySiteDetected'),
          desc: ""
        }
      });
      return;
    }

    this.initChargifyConfig()
      .then(() => this.initChargify())
      .then(() => {
        this.setState({initialLoading: false});
      })
      .catch((err) => {
        if (!err) {
          return;
        }
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState({
          uiState: "error",
          loading: false,
          initialLoading: false,
          error: {
            title: errTitle,
            desc: errDesc
          }
        });
      });
  }

  componentWillUnmount() {
    this.chargify.unload();
  }

  private initChargifyConfig(): Promise<void> {
    return Promise.all([
      userService.getUserOrSystemPropertyValue(`${CHARGIFY_JS_PUBLIC_KEY_PREFIX}${this.props.chargifySite}`)
    ])
      .then(props => {
        this.chargifyPublicKey = props[0];
      });
  }

  private initChargify(): Promise<void> {
    let {t} = this.props;
    return new Promise((resolve, reject) => {
      if (!this.chargifyPublicKey) {
        this.setState({
          loading: false,
          initialLoading: false,
          uiState: "error",
          error: {
            title: t('purchase.chargifyForm.noChargifyPublicKey', {chargifySubdomain: this.props.chargifySite}),
            desc: ""
          }
        });
        reject();
        return;
      }

      try {
        let promise: Promise<void> = this.chargify.load({
          // selector, where the iframe will be included on your page
          // optional, if you have a `selector` for every field ('fields' option)
          selector: '#host-form',

          // (i.e. '1a2cdsdn3lkn54lnlkn')
          publicKey: this.chargifyPublicKey,
          //securityToken: TODO for Server API to generate signed JWT

          // form type (possible values: 'card', 'bank' or 'gocardless')
          type: 'card',

          // points to your Chargify site
          serverHost: `https://${this.props.chargifySite}.chargify.com`,

          // flag to show/hide the credit card image
          // true: hides the credit card image
          // visible otherwise
          hideCardImage: false,

          // optional label/translation (i.e. '(optional field)') for optional fields
          // Especially useful if you use 'fields' option
          optionalLabel: '(optional)',

          // required label/translation (i.e. '*') for required fields
          // Especially useful if you use 'fields' option
          requiredLabel: '*',

          // Enable 3D secure
          // threeDSecure: true,
          // onThreeDsConfigError: (error: any) => {
          //   console.error(error);
          //   this.setState({
          //     loading: false,
          //     initialLoading: false,
          //     uiState: "error",
          //     error: {
          //       title: t('purchase.chargifyForm.unexpectedError'),
          //       desc: error?.errors?.toString() || JSON.stringify(error)
          //     }
          //   });
          //   reject();
          // },

          // optional global styles that include iframe styles,
          // styles for fields, inputs, labels and messages
          style: {
            // to style an iframe, use the iframe's container selector
            // '#chargify-form': {border: '1px dashed #ffc0cb57'},

            // `field` is the container for each field
            field: {
              // backgroundColor: '#ffdfdf',
              padding: '3px',
              borderRadius: '5px',
              margin: '0',
              height: '80px'
            },

            // `input` is the input HTML element
            input: {
              // backgroundColor: '#fdfde1',
              fontFamily: theme.fonts.base,
              fontSize: theme.fontSizes[2],
              paddingTop: '0.75rem',
              paddingBottom: '0.8rem',
              height: '46px',
              lineHeight: '1.5',
              color: theme.colors.text.default,
            },

            // `label` is the label container
            label: {
              fontFamily: theme.fonts.base,
              fontSize: theme.fontSizes[0],
              fontWeight: '400',
              paddingTop: '2px',
              paddingBottom: '1px',
              margin: '0'
            },

            // `message` is the invalid message container
            message: {
              display: 'none',
              // paddingTop: '2px',
              // paddingBottom: '1px'
            }
          },

          // use this option if you want to include each field
          // in the separate iframe
          fields: {

            firstName: {
              // selector, where the iframe with this field will be included
              // on your page
              selector: '#chargify-firstName',

              // ot overrides default label
              label: t('card.firstName'),

              // it overrides default placeholder
              placeholder: 'John',

              // if a given field is optional by default, you can make it required
              required: true,

              // it overrides default error message
              message: t('purchase.chargifyForm.fieldNotValid'),

              // it overrides or defines max length
              maxlength: '30',

              // it overrides global styles for this field only
              // style: {}
            },

            lastName: {
              selector: '#chargify-lastName',
              label: t('card.lastName'),
              placeholder: 'Doe',
              required: true,
              message: t('purchase.chargifyForm.fieldNotValid'),
              maxlength: '30'
            },

            number: {
              selector: '#chargify-number',
              label: t('card.cardNumber'),
              placeholder: 'XXXX XXXX XXXX XXXX',
              required: true,
              message: t('purchase.chargifyForm.fieldNotValid'),
              style: {
                field: {
                  marginLeft: '0.3em'
                },
                input: {
                  paddingLeft: '2.8em',
                  marginTop: '-0.3em',
                  marginLeft: '-0.3em'
                },
                label: {
                  marginBottom: '0.3em',
                  marginLeft: '-0.3em'
                }
              }
            },

            month: {
              selector: '#chargify-month',
              label: t('card.month'),
              required: true,
              placeholder: 'mm',
              message: t('purchase.chargifyForm.fieldNotValid')
            },

            year: {
              selector: '#chargify-year',
              label: t('card.year'),
              required: true,
              placeholder: 'yyyy',
              message: t('purchase.chargifyForm.fieldNotValid')
            },

            cvv: {
              selector: '#chargify-cvv',
              label: t('card.cvv'),
              placeholder: '123',
              required: true,
              message: t('purchase.chargifyForm.fieldNotValid')
            },

            address: {
              selector: '#chargify-address',
              label: t('card.address'),
              placeholder: '1234 Hill St',
              required: true,
              message: t('purchase.chargifyForm.fieldNotValid'),
              maxlength: '70'
            },

            address2: {
              selector: '#chargify-address2',
              label: t('card.address2'),
              placeholder: '1234 Hill St',
              required: false,
              message: t('purchase.chargifyForm.fieldNotValid'),
              maxlength: '70'
            },

            city: {
              selector: '#chargify-city',
              label: t('card.city'),
              placeholder: 'Austin',
              required: true,
              message: t('purchase.chargifyForm.fieldNotValid'),
              maxlength: '30'
            },

            state: {
              selector: '#chargify-state',
              label: t('card.state'),
              placeholder: 'TX',
              required: true,
              message: t('purchase.chargifyForm.fieldNotValid'),
              maxlength: '2'
            },

            zip: {
              selector: '#chargify-zip',
              label: t('card.zip'),
              placeholder: '10001',
              required: true,
              message: t('purchase.chargifyForm.fieldNotValid'),
              maxlength: '5'
            },

            country: {
              selector: '#chargify-country',
              label: t('card.country'),
              placeholder: 'US',
              required: true,
              message: t('purchase.chargifyForm.fieldNotValid'),
              maxlength: '30'
            }
          }
        });
        if (promise && promise.then) {
          promise.then(() => {
            // at this point all IFrames should be loaded

            let iframes = document.querySelectorAll("#host-form iframe");
            for (let i = 0; i < iframes.length; i++) {
              const iframe = iframes[i];
              iframe.setAttribute("scrolling", "no"); // Add tag attribute to remove scrollbars
            }

            resolve();
          }, (err) => {
            console.error(err);
            this.setState({
              initialLoading: false,
              uiState: "error",
              error: {
                title: t('purchase.chargifyForm.unexpectedError'),
                desc: err?.message || ""
              }
            });
            reject();
          });
        } else {
          // just in case Chargify will change its API again
          resolve();
        }
      } catch (e) {
        this.setState({
          initialLoading: false,
          uiState: "error",
          error: {
            title: t('purchase.chargifyForm.unexpectedError'),
            desc: e?.message || ""
          }
        });
        reject();
        return;
      }
    });
  }

  private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.setState({
      loading: true,
      error: undefined
    });

    this.chargify.token(
      this.chargifyForm.current!,

      (token) => {
        console.log('Received Chargify.js token: ', token);
        this.props.onTokenReceived && this.props.onTokenReceived(token);
        this.setState({
          //loading: false,
          error: undefined
        });
      },

      (error) => {
        console.warn('Chargify.js error!');
        console.warn(error);

        this.setState({
          loading: false,
          error: {
            title: (error && error.message) ? error.message : this.props.t("purchase.chargifyForm.unexpectedError"),
            desc: ""
          }
        });
      }
    );
  }

  render() {
    let {t} = this.props;

    if (this.state.uiState === "error") {
      return (
        <>
          <Alert intent="danger"
                 title={this.state.error?.title}
                 subtitle={this.state.error?.desc}
                 elevation="xs"/>
          <ButtonContainer>
            <Button size="lg"
                    variant="ghost"
                    onClick={() => this.props.onBack && this.props.onBack()}>
              {t('purchase.chargifyForm.backBtn')}
            </Button>
          </ButtonContainer>
        </>
      );
    }

    let chargifyInputSkeleton = (
      <div className="ChargifySkeleton">
        <div className="ChargifyLabelSkeleton">
          <Skeleton animated/>
        </div>
        <div className="ChargifyInputSkeleton">
          <Skeleton animated/>
        </div>
      </div>
    );

    return (
      <div className="ChargifyForm">
        <Text variant="uppercase" style={{marginBottom: theme.spaces.sm}}>
          {this.props.header || t('purchase.chargifyForm.header')}
        </Text>
        <form className="host-form"
              id='host-form'
              onSubmit={this.handleSubmit}
              ref={this.chargifyForm}>

          {this.state.initialLoading && (
            <>
              {chargifyInputSkeleton}
              <div className="row wrap">
                {chargifyInputSkeleton}
                {chargifyInputSkeleton}
              </div>
              <div className="row">
                {chargifyInputSkeleton}
                {chargifyInputSkeleton}
              </div>
              {chargifyInputSkeleton}
              {chargifyInputSkeleton}
              {chargifyInputSkeleton}
              {chargifyInputSkeleton}
              {chargifyInputSkeleton}
              {chargifyInputSkeleton}
              {chargifyInputSkeleton}
            </>
          )}

          <div className={this.state.initialLoading ? "Hidden" : ""} id="chargify-number"/>
          <div className="row wrap">
            <div className={this.state.initialLoading ? "Hidden" : ""} id="chargify-firstName"/>
            <div className={this.state.initialLoading ? "Hidden" : ""} id="chargify-lastName"/>
          </div>
          <div className="row">
            <div className={this.state.initialLoading ? "Hidden" : ""} id="chargify-month"/>
            <div className={this.state.initialLoading ? "Hidden" : ""} id="chargify-year"/>
          </div>
          <div className={this.state.initialLoading ? "Hidden" : ""} id="chargify-cvv"/>
          <div className={this.state.initialLoading ? "Hidden" : ""} id="chargify-address"/>
          <div className={this.state.initialLoading ? "Hidden" : ""} id="chargify-address2"/>
          <div className={this.state.initialLoading ? "Hidden" : ""} id="chargify-city"/>
          <div className={this.state.initialLoading ? "Hidden" : ""} id="chargify-country"/>
          <div className={this.state.initialLoading ? "Hidden" : ""} id="chargify-state"/>
          <div className={this.state.initialLoading ? "Hidden" : ""} id="chargify-zip"/>

          {this.state.error && (
            <Alert intent="danger"
                   style={{marginTop: '1em', marginBottom: '1em'}}
                   elevation="xs"
                   title={this.state.error?.title}
                   subtitle={this.state.error?.desc}/>
          )}

          <ButtonContainer>
            <Button size="lg"
                    component="button"
                    type="submit"
                    disabled={this.state.initialLoading || this.state.loading}
                    intent="primary">
              {t('purchase.chargifyForm.submitBtn')}
            </Button>
            <Button size="lg"
                    variant="ghost"
                    disabled={this.state.loading}
                    onClick={() => this.props.onBack && this.props.onBack()}>
              {t('purchase.chargifyForm.backBtn')}
            </Button>
          </ButtonContainer>

        </form>
      </div>
    );
  }
}

interface ChargifyFormProps extends WithTranslation {
  onTokenReceived?: (token: string) => void;
  onBack?: () => void;
  header?: string;
  chargifySite: string;
}

interface ChargifyFormState {
  uiState: 'main' | 'error';
  initialLoading: boolean;
  loading: boolean;
  error?: {
    title: string,
    desc: string
  };
}

export default withTranslation()(ChargifyForm);
