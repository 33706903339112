import React, { Component } from "react";
import { Alert, Button, Input, InputGroup, Text } from "sancho";
import { WithTranslation, withTranslation } from "react-i18next";
import FormController, { FieldController } from "../../../env/FormController";
import AuthService from "../../../services/authService";
import Utils, { EMAIL_REGEX } from "../../../services/utils";
import theme from "../../../theme";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import ButtonContainer from "../../structural/ButtonContainer/ButtonContainer";
import { brandingService } from "../../../services/brandingService";

class PasswordRenewRequestForm extends Component<PasswordRenewRequestFormProps, PasswordRenewRequestFormState> {

  private form: FormController;
  private emailInputRef = React.createRef<HTMLInputElement>();

  constructor(props: PasswordRenewRequestFormProps) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    const {t, user} = this.props;

    this.form = new FormController({
      onSubmit: this.onSubmit,
      validators: [],
      fields: {
        email: {
          value: user || "",
          validators: [
            { // required
              message: t("passwordRenewRequest.validation.empty"),
              validate: (fieldCtrl: FieldController<string>) => !!fieldCtrl.value
            },
            { // email validation
              message: t("passwordRenewRequest.validation.email"),
              validate: (fieldCtrl: FieldController<string>) => {
                return EMAIL_REGEX.test((fieldCtrl.value || "").toLowerCase());
              }
            }
          ]
        }
      }
    });

    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    this.emailInputRef.current!.focus();
  }

  private onSubmit() {
    this.setState(prevState => ({
      ...prevState,
      loading: true,
      error: undefined
    }));

    AuthService.recoverPassword(this.form.fields.email.value, brandingService.currentBrand.id)
      .then(() => {
        if (this.props.onRenew) {
          this.props.onRenew();
        }
      }, (err: any) => {
        console.warn(err);
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState(prevState => ({
          ...prevState,
          loading: false,
          error: {
            title: errTitle,
            desc: errDesc
          }
        }));
      });
  }

  private handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (this.state.loading) {
      return;
    }
    this.form.submit(event);
    this.forceUpdate();
  }

  private handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.form.fields.email.value = event.target.value;
    this.forceUpdate()
  }

  private goBack() {
    this.props.history.goBack();
  }

  render() {
    let {t} = this.props;

    let error;
    if (this.state.error) {
      error = (
        <Alert
          style={{marginTop: theme.spaces.md}}
          elevation="xs"
          intent="danger"
          title={t(this.state.error.title)}
          subtitle={t(this.state.error.desc)}/>
      );
    }

    return (
      <form className="PasswordRenewRequestForm"
            onSubmit={this.handleFormSubmit}
            aria-disabled={this.state.loading}>

        <Text gutter={false}>
          {t("passwordRenewRequest.form.header")}
        </Text>

        <InputGroup hideLabel
                    error={this.form.submitted && this.form.fields.email.validationMessages[0]}
                    label={t("passwordRenewRequest.form.email.label")}>
          {/*
           // @ts-ignore TypeScript don't see ref in sancho's Input */}
          <Input ref={this.emailInputRef}
                 inputSize="lg"
                 type="text"
                 name="email"
                 value={this.form.fields.email.value}
                 onChange={this.handleEmailChange}
                 placeholder={t("passwordRenewRequest.form.email.label")}
                 disabled={this.state.loading}/>
        </InputGroup>

        <ButtonContainer>
          <Button size="lg"
                  component="button"
                  type="submit"
                  intent="primary"
                  loading={this.state.loading}>
            {t("passwordRenewRequest.form.submitButton")}
          </Button>
          <Button size="lg"
                  variant="ghost"
                  loading={this.state.loading}
                  onClick={() => this.goBack()}>
            {t("passwordRenewRequest.form.backButton")}
          </Button>
        </ButtonContainer>

        {error}

      </form>
    );
  }
}

interface PasswordRenewRequestFormState {
  loading: boolean;

  error?: {
    title: string,
    desc: string
  }
}

interface PasswordRenewRequestFormProps extends WithTranslation, RouteComponentProps {
  onRenew?: () => void;
  user?: string;
}

export default withTranslation()(withRouter(PasswordRenewRequestForm));
