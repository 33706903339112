import { WebCore } from '@ppc/webcore';

const webCoreInstance = new WebCore();

// This is NodeJS environment
//let envName = process.env.NODE_ENV;

//window['environment'] = 'dev';
webCoreInstance.setEnvironment('dev');
webCoreInstance.addConfig('dev', {
  localStoragePrefix: process.env.REACT_APP_LOCAL_STORAGE_PREFIX
});
// webCoreInstance.services.auth.logoutFromThisBrowser();

export default webCoreInstance;
