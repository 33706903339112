import React from 'react';
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import { WithTranslation, withTranslation } from "react-i18next";
import AuthService from "../../services/authService";
import authService from "../../services/authService";
import UserService from "../../services/userService";
import { UserInformation } from "@ppc/webcore/dist/data/services/userService";
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from "react-router";
import QueryService from "../../services/queryService";
import { LoginUrlParams } from "../Login/LoginPage";
import { Alert, Button, Input, InputGroup, Text } from "sancho";
import theme from "../../theme";
import FormController, { FieldController } from "../../env/FormController";
import Utils from "../../services/utils";
import { CommonUrlParams } from "../../env/models/CommonUrlParams";
import CenterPageContent from "../../components/structural/CenterPageContent/CenterPageContent";
import ButtonContainer from "../../components/structural/ButtonContainer/ButtonContainer";
import { brandingService } from "../../services/brandingService";

const RESEND_PASSCODE_INTERVAL = 60;

class EditPasswordPage extends React.Component<EditPasswordPageProps, EditPasswordPageState> {

  private readonly form: FormController;
  private readonly passcodeForm: FormController;

  private userInfo?: UserInformation;

  private pwdRegex: RegExp = new RegExp("^.{8,}$");

  private readonly urlParams: EditPasswordPageUrlParams;

  private secondsUntilNextPasscodeSend: number = RESEND_PASSCODE_INTERVAL;
  private showResendButtonTimeoutIndex?: number;

  constructor(props: EditPasswordPageProps) {
    super(props);

    this.state = {
      uiState: 'initial',
      loading: true,
      allowPasscodeSend: true,
      resendCountdown: 0
    };

    this.urlParams = QueryService.getQueryParams() as EditPasswordPageUrlParams;

    const {t} = this.props;
    this.form = new FormController({
      onSubmit: () => this.onSubmit(),
      validators: [],
      fields: {
        oldPwd: {
          value: "",
          validators: [
            {
              message: t("editPassword.validation.oldPwd.empty"),
              validate: (fieldCtrl: FieldController<string>) => !!fieldCtrl.value
            }
          ]
        },
        newPwd: {
          value: "",
          validators: [
            { // required
              message: t("editPassword.validation.newPwd.empty"),
              validate: (fieldCtrl: FieldController<string>) => !!fieldCtrl.value
            },
            { // Password Strength
              message: t("editPassword.validation.newPwd.passwordStrength"),
              validate: (fieldCtrl: FieldController<string>) => {
                return !!fieldCtrl.value && this.pwdRegex.test(fieldCtrl.value);
              }
            }
          ]
        },
        newPwdRepeat: {
          value: "",
          validators: [
            { // required
              message: t("editPassword.validation.newPwdRepeat.empty"),
              validate: (fieldCtrl: FieldController<string>) => !!fieldCtrl.value
            },
            { // Password equality
              message: t("editPassword.validation.newPwdRepeat.notEqual"),
              validate: (fieldCtrl: FieldController<string>, formCtrl) => {
                return formCtrl.fields.newPwdRepeat.value === formCtrl.fields.newPwd.value;
              }
            }
          ]
        }
      }
    });

    this.passcodeForm = new FormController({
      onSubmit: () => this.onPasscodeSubmit(),
      validators: [],
      fields: {
        passcode: {
          value: "",
          validators: [
            {
              message: t("login.validation.passcode.empty"),
              validate: (fieldCtrl: FieldController<string>) => !!fieldCtrl.value
            }
          ]
        }
      }
    });
  }

  componentDidMount(): void {
    Promise.all([
      this.initUser(),
      this.initPwdRegex()
    ])
      .then(() => {
        this.setState({
          loading: false,
          uiState: "main"
        });
      });
  }

  componentWillUnmount() {
    this.stopResendCountdown();
  }

  private initPwdRegex() {
    return AuthService.getPasswordRegex()
      .then((regex) => {
        if (regex) {
          try {
            this.pwdRegex = new RegExp(regex);
          } catch (e) {
            console.warn(e);
          }
        }
      }, (err) => {
        console.warn(err);
      });
  }

  private initUser(): Promise<any> {
    if (AuthService.isAuthenticated()) {
      return UserService.getCurrentUser()
        .then((userInfo: UserInformation) => {
          this.userInfo = userInfo;
        }, () => {
          this.redirectToLoginPage();
          return Promise.reject();
        });
    } else {
      this.redirectToLoginPage();
      return Promise.reject();
    }
  }

  private onPasscodeSubmit() {
    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
      error: undefined
    });

    authService.setNewPassword({
      oldPassword: this.form.fields.oldPwd.value,
      newPassword: this.form.fields.newPwd.value,
      passcode: this.passcodeForm.fields.passcode.value,
      brand: brandingService.currentBrand.id
    })
      .then(() => {
        this.stopResendCountdown();
        this.setState({
          loading: false,
          uiState: "success"
        });
        authService.logout();
      })
      .catch((err) => {
        this.startResendCountdown();
        if (err && err.resultCode === 12) {
          this.setState({
            loading: false,
            error: {
              title: "editPassword.wrongPasscode",
              desc: ""
            }
          });
          return;
        }
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState({
          loading: false,
          error: {
            title: errTitle,
            desc: errDesc
          }
        });
      });
  }

  private sendPasscode() {
    this.setState({loading: true});
    this.stopResendCountdown();
    return authService.sendPasscode(
      this.userInfo!.user.userName! || this.userInfo!.user.altUsername!,
      brandingService.currentBrand.id
    )
      .then(() => {
        this.setState({
          allowPasscodeSend: true,
          loading: false,
          error: undefined
        });
        this.startResendCountdown();
      })
      .catch((err) => {
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState({
          loading: false,
          error: {
            title: errTitle,
            desc: errDesc
          }
        });
        this.startResendCountdown();
      });
  }

  private stopResendCountdown() {
    if (this.showResendButtonTimeoutIndex != null) {
      clearInterval(this.showResendButtonTimeoutIndex);
      this.showResendButtonTimeoutIndex = undefined;
    }
  }

  private startResendCountdown() {
    this.stopResendCountdown();
    this.setState({resendCountdown: this.secondsUntilNextPasscodeSend});

    // @ts-ignore
    this.showResendButtonTimeoutIndex = setInterval(() => {
      this.setState(prevState => {
        if (prevState.resendCountdown <= 0) {
          this.stopResendCountdown();
          return ({
            ...prevState,
            resendCountdown: 0
          });
        }
        return ({
          ...prevState,
          resendCountdown: prevState.resendCountdown - 1
        });
      });
    }, 1000);
  }

  private redirectToLoginPage() {
    let {location, history} = this.props;
    let params: LoginUrlParams = {
      redirectTo: location.pathname + location.search,
    };
    history.push(`/login${QueryService.encodeQueryParams(params)}`);
  }

  private handleOldPwdChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.form!.fields.oldPwd.value = event.target.value;
    this.forceUpdate()
  }

  private handleNewPwdChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.form!.fields.newPwd.value = event.target.value;
    this.forceUpdate()
  }

  private handleNewPwdRepeatChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.form!.fields.newPwdRepeat.value = event.target.value;
    this.forceUpdate()
  }

  private handlePasscodeChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.passcodeForm.fields.passcode.value = (event.target.value || '').toUpperCase();
    this.forceUpdate();
  }

  private handleBackButtonClick() {
    this.stopResendCountdown();
    this.props.history.goBack()
  }

  private handleOkButtonClick() {
    let {history} = this.props;
    this.setState({loading: true});
    authService.logout();
    history.push(`/login`);
  }

  private handleResendPasscodeButtonClick() {
    if (this.state.loading) {
      return;
    }

    this.sendPasscode();
  }

  private handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (this.state.loading) {
      return;
    }
    this.form!.submit(event);
    this.forceUpdate();
  }

  private handlePasscodeSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (this.state.loading) {
      return;
    }
    this.passcodeForm.submit(event);
    this.forceUpdate();
  }

  private onSubmit() {
    this.setState({loading: true});

    authService.setNewPassword({
      oldPassword: this.form.fields.oldPwd.value,
      newPassword: this.form.fields.newPwd.value,
      brand: brandingService.currentBrand.id
    })
      .then(() => {
        this.stopResendCountdown();
        this.setState({
          loading: false,
          uiState: "success"
        });
        authService.logout();
      })
      .catch((err) => {
        if (err && err.resultCode === 17) {
          this.setState({
            loading: false,
            uiState: 'passcode'
          });
          this.startResendCountdown();
          return;
        }
        if (err && err.resultCode === 12) {
          this.setState({
            loading: false,
            error: {
              title: "editPassword.wrongPassword",
              desc: ""
            }
          });
          return;
        }
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState({
          loading: false,
          error: {
            title: errTitle,
            desc: errDesc
          }
        });
      });
  }

  render() {
    const {loading, uiState} = this.state;
    const {t} = this.props;

    let error;
    if (this.state.error) {
      error = (
        <Alert
          style={{marginTop: theme.spaces.md}}
          elevation="xs"
          intent="danger"
          title={t(this.state.error.title)}
          subtitle={t(this.state.error.desc)}/>
      );
    }

    let ui;

    if (uiState === "initial") {
      ui = null;
      // todo Skeleton
    } else if (uiState === "main") {
      ui = (
        <form className="EditPasswordForm"
              onSubmit={(event) => this.handleFormSubmit(event)}
              aria-disabled={loading}>

          <InputGroup hideLabel
                      error={this.form.submitted && this.form.fields.oldPwd.validationMessages[0]}
                      label={t("editPassword.oldPwd.label")}>

            <Input inputSize="lg"
                   type="password"
                   name="oldPwd"
                   value={this.form.fields.oldPwd.value}
                   onChange={(event) => this.handleOldPwdChange(event)}
                   placeholder={t("editPassword.oldPwd.label")}
                   disabled={loading}/>
          </InputGroup>

          <InputGroup hideLabel
                      error={this.form.submitted && this.form.fields.newPwd.validationMessages[0]}
                      label={t("editPassword.newPwd.label")}>

            <Input inputSize="lg"
                   type="password"
                   name="newPwd"
                   value={this.form.fields.newPwd.value}
                   onChange={(event) => this.handleNewPwdChange(event)}
                   placeholder={t("editPassword.newPwd.label")}
                   disabled={loading}/>
          </InputGroup>

          <InputGroup hideLabel
                      error={this.form.submitted && this.form.fields.newPwdRepeat.validationMessages[0]}
                      label={t("editPassword.newPwdRepeat.label")}>

            <Input inputSize="lg"
                   type="password"
                   name="newPwdRepeat"
                   value={this.form.fields.newPwdRepeat.value}
                   onChange={(event) => this.handleNewPwdRepeatChange(event)}
                   placeholder={t("editPassword.newPwdRepeat.label")}
                   disabled={loading}/>
          </InputGroup>

          <ButtonContainer>
            <Button size="lg"
                    component="button"
                    type="submit"
                    intent="primary"
                    disabled={this.form.pristine || (this.form.submitted && !this.form.valid)}
                    loading={loading}>
              {t("editPassword.btn.submit")}
            </Button>
            <Button size="lg"
                    variant="ghost"
                    onClick={() => this.handleBackButtonClick()}
                    loading={loading}>
              {t("editPassword.btn.back")}
            </Button>
          </ButtonContainer>

          {error}

        </form>
      );
    } else if (uiState === "passcode") {
      ui = (
        <form className="PasscodeForm"
              onSubmit={(event) => this.handlePasscodeSubmit(event)}
              aria-disabled={this.state.loading}>

          <Text gutter={false}>
            {t("editPassword.passcodeSendHelpText", {phone: `${this.userInfo?.user.phone}`})}
          </Text>

          <InputGroup hideLabel
                      error={this.passcodeForm.submitted && this.passcodeForm.fields.passcode.validationMessages[0]}
                      label={t("editPassword.passcode.label")}>

            <Input autoComplete="off"
                   inputSize="lg"
                   type="text"
                   name="passcode"
                   value={this.passcodeForm.fields.passcode.value}
                   onChange={(event) => this.handlePasscodeChange(event)}
                   placeholder={t("editPassword.passcode.label")}
                   disabled={this.state.loading}/>
          </InputGroup>

          {error}

          <ButtonContainer>
            <Button size="lg"
                    component="button"
                    type="submit"
                    intent="primary"
                    disabled={this.state.loading}
                    loading={this.state.loading}>
              {t("editPassword.btn.sendPasscode")}
            </Button>
            {this.state.allowPasscodeSend && !this.state.loading && (
              <>
                {!!this.state.resendCountdown && this.state.resendCountdown > 0 && (
                  <Button size="lg"
                          variant="ghost"
                          disabled={true}>
                    {t("editPassword.resendPasscodeIn", {count: this.state.resendCountdown})}
                  </Button>
                )}

                {this.state.resendCountdown <= 0 && (
                  <Button size="lg"
                          variant="ghost"
                          disabled={this.state.loading}
                          onClick={() => this.handleResendPasscodeButtonClick()}>
                    {t("editPassword.resendPasscode")}
                  </Button>
                )}
              </>
            )}
            <Button size="lg"
                    variant="ghost"
                    loading={loading}
                    onClick={() => this.handleBackButtonClick()}>
              {t("editPassword.btn.back")}
            </Button>
          </ButtonContainer>

        </form>
      );
    } else if (uiState === "success") {
      ui = (
        <>
          <Alert intent="success"
                 title={t('editPassword.success.header')}
                 subtitle={t('editPassword.success.desc')}
                 elevation="xs"/>
          <ButtonContainer>
            <Button size="lg"
                    component="button"
                    type="submit"
                    intent="primary"
                    onClick={() => this.handleOkButtonClick()}
                    loading={loading}>
              {t("editPassword.btn.ok")}
            </Button>
          </ButtonContainer>
        </>
      );
    }

    return (
      <BrandedPageShell>
        <CenterPageContent>
          <div className="EditPasswordPage">
            <Text variant="display3">{t('editPassword.header')}</Text>
            {ui}
          </div>
        </CenterPageContent>
      </BrandedPageShell>
    );
  }
}

interface EditPasswordPageProps extends WithTranslation, RouteComponentProps {

}

interface EditPasswordPageState {
  uiState: 'initial' | 'main' | 'passcode' | 'success'
  loading: boolean;
  allowPasscodeSend: boolean;
  resendCountdown: number;
  error?: {
    title: string,
    desc: string
  }
}

interface EditPasswordPageUrlParams extends CommonUrlParams {

}

export default withTranslation()(withRouter(EditPasswordPage));
