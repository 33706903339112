import React, { Component } from "react";
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import { WithTranslation, withTranslation } from "react-i18next";
import QueryService from "../../services/queryService";
import { CommonUrlParams } from "../../env/models/CommonUrlParams";
import Story from "../../components/Story/Story";
import CenterPageContent from "../../components/structural/CenterPageContent/CenterPageContent";

class CookiesPolicyPage extends Component<CookiesPolicyPageProps, CookiesPolicyPageState> {

  private readonly urlParams: CookiesPolicyPageUrlParams;

  constructor(props: CookiesPolicyPageProps) {
    super(props);
    this.urlParams = QueryService.getQueryParams() as CookiesPolicyPageUrlParams;
  }

  componentDidMount() {

  }

  render() {
    const {i18n} = this.props;
    return (
      <BrandedPageShell>
        <CenterPageContent>
          <Story lang={i18n.language} storyType={43}/>
        </CenterPageContent>
      </BrandedPageShell>
    );
  }
}

interface CookiesPolicyPageProps extends WithTranslation {

}

interface CookiesPolicyPageState {

}

interface CookiesPolicyPageUrlParams extends CommonUrlParams {

}

export default withTranslation()(CookiesPolicyPage);
