import React from "react";
import "./LoginForm.scss";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  default as UsernamePasswordForm,
  UsernamePasswordForm as UsernamePasswordFormClass
} from "../UsernamePasswordForm/UsernamePasswordForm";
import {
  default as UsernamePasscodeForm,
  UsernamePasscodeForm as UsernamePasscodeFormClass
} from "../UsernamePasscodeForm/UsernamePasscodeForm";

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {

  private usernamePasswordFormRef = React.createRef<UsernamePasswordFormClass>();
  private usernamePasscodeFormRef = React.createRef<UsernamePasscodeFormClass>();

  constructor(props: LoginFormProps) {
    super(props);

    this.state = {
      pwdLoading: false,
      passcodeLoading: false,
      activeTab: "sms"
    };
  }

  componentDidMount() {
    this.focus(this.state.activeTab);
  }

  onLogin() {
    this.props.onLogin && this.props.onLogin();
  }

  focus(tab: LoginFormState["activeTab"]) {
    // Wait until DOM changes and animation will finish. And only then focus the component.
    // "The right way" with componentDidUpdate just not working.
    // componentDidUpdate called before the input become focusable (browser animation issues)
    setTimeout(() => {
      if (tab === 'password') {
        this.usernamePasswordFormRef.current && this.usernamePasswordFormRef.current.focus();
      } else if (tab === 'sms') {
        this.usernamePasscodeFormRef.current && this.usernamePasscodeFormRef.current.focus();
      }
    });
  }

  changeTab(tab: LoginFormState["activeTab"]) {
    this.setState({
      activeTab: tab
    });
    this.focus(tab);
  }

  render() {
    // let {t} = this.props;

    return (
      <>
        {/* Note: Do not use Sancho UI Pager. It not works in Edge and some other native mobile browsers. Also it has issues with animation on Chrome */}

        <div style={{display: this.state.activeTab !== "password" ? "none" : undefined}}>
          {/*
              // @ts-ignore */}
          <UsernamePasswordForm user={this.props.user}
                                ref={this.usernamePasswordFormRef}
                                onLogin={() => this.onLogin()}
                                disabled={this.state.passcodeLoading || this.state.activeTab !== "password"}
                                onStartLoading={() => this.setState({pwdLoading: true})}
                                onEndLoading={() => this.setState({pwdLoading: false})}
                                onGoToPasscode={() => this.changeTab("sms")}/>
        </div>

        <div style={{display: this.state.activeTab !== 'sms' ? "none" : undefined}}>
          {/*
              // @ts-ignore */}
          <UsernamePasscodeForm ref={this.usernamePasscodeFormRef}
                                user={this.props.user}
                                onLogin={() => this.onLogin()}
                                disabled={this.state.pwdLoading || this.state.activeTab !== 'sms'}
                                onStartLoading={() => this.setState({passcodeLoading: true})}
                                onEndLoading={() => this.setState({passcodeLoading: false})}
                                onGoToPassword={() => this.changeTab("password")}/>
        </div>
      </>
    );
  }

}

interface LoginFormProps extends WithTranslation {
  onLogin: () => void;
  user?: string;
}

interface LoginFormState {
  activeTab: 'sms' | 'password';

  pwdLoading: boolean;
  passcodeLoading: boolean;

  error?: {
    title: string,
    desc: string
  }
}

export default withTranslation()(LoginForm);
