import React, { Component, KeyboardEvent } from "react";
import { Alert, Button, Input, InputGroup, Text } from "sancho";
import { WithTranslation, withTranslation } from "react-i18next";
import FormController, { FieldController } from "../../../env/FormController";
import AuthService from "../../../services/authService";
import Utils from "../../../services/utils";
import theme from "../../../theme";
import QueryService from "../../../services/queryService";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import ButtonContainer from "../../structural/ButtonContainer/ButtonContainer";
import { brandingService } from "../../../services/brandingService";

const RESEND_PASSCODE_INTERVAL = 60;

class PasswordRenewResultForm extends Component<PasswordRenewResultFormProps, PasswordRenewResultFormState> {

  private readonly form: FormController;
  private readonly passcodeForm: FormController;
  private passwordInputRef = React.createRef<HTMLInputElement>();
  private passcodeInputRef = React.createRef<HTMLInputElement>();
  private passwordRepeatInputRef = React.createRef<HTMLInputElement>();
  private pwdRegex: RegExp = new RegExp("^.{8,}$");
  private showResendButtonTimeoutIndex?: number;
  private secondsUntilNextPasscodeSend: number = RESEND_PASSCODE_INTERVAL;

  private backNavigationListenerDestructor?: () => void;

  private uiStateHistory: Array<PasswordRenewResultFormState["uiState"]> = [];

  constructor(props: PasswordRenewResultFormProps) {
    super(props);

    const {t} = this.props;

    this.form = new FormController({
      onSubmit: () => this.onSubmit(),
      validators: [],
      fields: {
        password: {
          value: "",
          validators: [
            { // required
              message: t("passwordRenewResult.validation.empty"),
              validate: (fieldCtrl: FieldController<string>) => !!fieldCtrl.value
            },
            { // Password Strength
              message: t("passwordRenewResult.validation.passwordStrength"),
              validate: (fieldCtrl: FieldController<string>) => {
                return !!fieldCtrl.value && this.pwdRegex.test(fieldCtrl.value);
              }
            }
          ]
        },
        passwordRepeat: {
          value: "",
          validators: [
            { // required
              message: t("passwordRenewResult.validation.emptyRepeat"),
              validate: (fieldCtrl: FieldController<string>) => !!fieldCtrl.value
            },
            { // Password equality
              message: t("passwordRenewResult.validation.notEqual"),
              validate: (fieldCtrl: FieldController<string>, formCtrl) => {
                return formCtrl.fields.passwordRepeat.value === formCtrl.fields.password.value;
              }
            }
          ]
        }
      }
    });

    this.passcodeForm = new FormController({
      onSubmit: () => this.onPasscodeSubmit(),
      validators: [],
      fields: {
        passcode: {
          value: "",
          validators: [
            {
              message: t("login.validation.passcode.empty"),
              validate: (fieldCtrl: FieldController<string>) => !!fieldCtrl.value
            }
          ]
        }
      }
    });

    this.state = {
      loading: true,
      uiState: 'initial',
      resendCountdown: 0
    };
  }

  componentDidMount() {
    this.backNavigationListenerDestructor = this.props.history.listen((newLocation, action) => {
      if (action === "POP") {
        if (this.uiStateHistory.length <= 1) {
          this.props.history.goBack();
        } else {
          this.uiStateHistory.pop();
          let lastState = this.uiStateHistory[this.uiStateHistory.length - 1];
          this.setState({uiState: lastState!});
        }
      }
    });

    AuthService.getPasswordRegex()
      .then((regex) => {
        if (regex) {
          this.pwdRegex = new RegExp(regex);
        }
      }, (err) => {
        console.warn(err);
      })
      .then(() => {
        this.setState(prevState => ({
          ...prevState,
          uiState: 'password',
          loading: false
        }));
        this.focus();
      })
  }

  componentWillUnmount() {
    this.backNavigationListenerDestructor && this.backNavigationListenerDestructor();
  }

  UNSAFE_componentWillUpdate(nextProps: Readonly<PasswordRenewResultFormProps>, nextState: Readonly<PasswordRenewResultFormState>, nextContext: any): void {
    let uiState = nextState.uiState;
    if (uiState !== 'initial' && this.state.uiState !== uiState && (this.uiStateHistory.length === 0 || this.uiStateHistory[this.uiStateHistory.length - 1] !== uiState)) {
      this.uiStateHistory.push(uiState);
      let {history, location} = this.props;
      history.push(`${location.pathname}${QueryService.encodeQueryParams({
        ...QueryService.getQueryParams()
      })}`);
    }
  }

  focus() {
    setTimeout(() => {
      if (this.state.uiState === "password") {
        this.passwordInputRef.current && this.passwordInputRef.current!.focus();
      } else if (this.state.uiState === "passcode") {
        this.passcodeInputRef.current && this.passcodeInputRef.current!.focus();
      }
    });
  }

  private onPasscodeSubmit() {
    if (this.state.loading) {
      return;
    }

    this.setState(prevState => ({
      ...prevState,
      loading: true,
      error: undefined
    }));

    AuthService.setNewPasswordByPasscode(
      this.form.fields.password.value,
      this.passcodeForm.fields.passcode.value,
      this.props.apiKey,
      brandingService.currentBrand.id)

      .then(() => {
        this.stopResendCountdown();
        this.setState({
          loading: false
        });
        if (this.props.onRenew) {
          this.props.onRenew();
        }
      })
      .catch((err) => {
        let errTitle, errDesc;
        if (err && err.resultCode === 12) {
          errTitle = "login.error.wrongPasscode";
          errDesc = "";
        } else {
          [errTitle, errDesc] = Utils.parseApiError(err);
        }

        this.setState({
          error: {
            title: errTitle,
            desc: errDesc
          },
          loading: false
        });
      });
  }

  private onSubmit() {
    this.setState(prevState => ({
      ...prevState,
      loading: true,
      error: undefined
    }));

    AuthService.setNewPasswordByTempKey(this.form.fields.password.value, this.props.apiKey, brandingService.currentBrand.id)
      .then(() => {
        if (this.props.onRenew) {
          this.props.onRenew();
        }
      }, (err: any) => {
        if (err && err.resultCode === 17) {
          this.setState(prevState => ({
            ...prevState,
            uiState: 'passcode',
            passcodeMsg: err.resultCodeMessage,
            loading: false,
            error: undefined
          }));
          this.startResendCountdown();
          return;
        }

        console.warn(err);
        this.setState(prevState => ({
          ...prevState,
          loading: false,
          error: {
            title: 'passwordRenewResult.errors.unknown.header',
            desc: 'passwordRenewResult.errors.unknown.desc'
          }
        }));
      });
  }

  private stopResendCountdown() {
    if (this.showResendButtonTimeoutIndex != null) {
      clearInterval(this.showResendButtonTimeoutIndex);
      this.showResendButtonTimeoutIndex = undefined;
    }
  }

  private startResendCountdown() {
    this.stopResendCountdown();
    this.setState({resendCountdown: this.secondsUntilNextPasscodeSend});

    // @ts-ignore
    this.showResendButtonTimeoutIndex = setInterval(() => {
      this.setState(prevState => {
        if (prevState.resendCountdown <= 0) {
          this.stopResendCountdown();
          return ({
            ...prevState,
            resendCountdown: 0
          });
        }
        return ({
          ...prevState,
          resendCountdown: prevState.resendCountdown - 1
        });
      });
    }, 1000);
  }

  handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (this.state.loading) {
      return;
    }
    this.form.submit(event);
    this.forceUpdate();
  }

  handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.form.fields.password.value = event.target.value;
    this.forceUpdate()
  }

  handlePasswordRepeatChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.form.fields.passwordRepeat.value = event.target.value;
    this.forceUpdate()
  }

  private handlePasswordInputKeyPress(event: KeyboardEvent<HTMLInputElement>) {
    if (Utils.ifEnterPressed(event)) {
      if (this.passwordRepeatInputRef.current && this.passwordRepeatInputRef.current.focus && (!event.currentTarget || event.currentTarget.value)) {
        this.passwordRepeatInputRef.current.focus();
      }
    }
  }

  private handlePasscodeChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.passcodeForm.fields.passcode.value = (event.target.value || '').toUpperCase();
    this.forceUpdate();
  }

  private handlePasscodeSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (this.state.loading) {
      return;
    }
    this.passcodeForm.submit(event);
    this.forceUpdate();
  }

  private handleResendPasscodeButtonClick() {
    if (this.state.loading) {
      return;
    }
    this.onSubmit();
  }

  render() {
    let {t} = this.props;

    let error;
    if (this.state.error) {
      error = (
        <Alert
          style={{marginTop: theme.spaces.md}}
          elevation="xs"
          intent="danger"
          title={t(this.state.error.title)}
          subtitle={t(this.state.error.desc)}/>
      );
    }

    if (this.state.uiState === 'passcode') {
      return (
        <form className="PasswordRenewResultForm"
              onSubmit={(event) => this.handlePasscodeSubmit(event)}>

          {error ? error : (
            <Text gutter={false}>
              {this.state.passcodeMsg}
            </Text>
          )}

          <InputGroup hideLabel
                      error={this.passcodeForm.submitted && this.passcodeForm.fields.passcode.validationMessages[0]}
                      label={t("login.form.passcode.label")}>
            {/*
                  // @ts-ignore TypeScript don't see ref in sancho's Input */}
            <Input ref={this.passcodeInputRef}
                   inputSize="lg"
                   type="text"
                   name="passcode"
                   autoComplete="off"
                   value={this.passcodeForm.fields.passcode.value}
                   onChange={(event) => this.handlePasscodeChange(event)}
                   placeholder={t("login.form.passcode.label")}
                   disabled={this.state.loading}/>
          </InputGroup>

          <ButtonContainer>
            <Button size="lg"
                    component="button"
                    type="submit"
                    intent="primary"
                    disabled={this.state.loading}
                    loading={this.state.loading}>
              {t("login.form.signInButton")}
            </Button>

            {!this.state.loading && (
              <>
                {!!this.state.resendCountdown && this.state.resendCountdown > 0 && (
                  <Button size="lg"
                          variant="ghost"
                          disabled={true}>
                    {t("login.form.resendPasscodeIn", {count: this.state.resendCountdown})}
                  </Button>
                )}

                {this.state.resendCountdown <= 0 && (
                  <Button size="lg"
                          variant="ghost"
                          disabled={this.state.loading}
                          onClick={() => this.handleResendPasscodeButtonClick()}>
                    {t("login.form.resendPasscode")}
                  </Button>
                )}
              </>
            )}
          </ButtonContainer>

        </form>
      );
    }

    return (
      <form className="PasswordRenewResultForm"
            onSubmit={(event) => this.handleFormSubmit(event)}>

        { error ? error : (
          <Text gutter={false}>
            {t("passwordRenewResult.form.header")}
          </Text>
        )}

        <InputGroup hideLabel
                    error={this.form.submitted && this.form.fields.password.validationMessages[0]}
                    label={t("passwordRenewResult.form.password.label")}
                    helpText={t("passwordRenewResult.form.password.help")}>
          {/*
           // @ts-ignore TypeScript don't see ref in sancho's Input */}
          <Input ref={this.passwordInputRef}
                 inputSize="lg"
                 type="password"
                 name="password"
                 onChange={(event) => this.handlePasswordChange(event)}
                 onKeyPress={(event) => this.handlePasswordInputKeyPress(event)}
                 placeholder={t("passwordRenewResult.form.password.label")}
                 disabled={this.state.loading}/>
        </InputGroup>

        <InputGroup hideLabel
                    error={this.form.submitted && this.form.fields.passwordRepeat.validationMessages[0]}
                    label={t("passwordRenewResult.form.passwordRepeat.label")}
                    helpText={t("passwordRenewResult.form.passwordRepeat.help")}>
          {/*
           // @ts-ignore TypeScript don't see ref in sancho's Input */}
          <Input ref={this.passwordRepeatInputRef}
                 inputSize="lg"
                 type="password"
                 name="passwordRepeat"
                 onChange={(event) => this.handlePasswordRepeatChange(event)}
                 placeholder={t("passwordRenewResult.form.passwordRepeat.label")}
                 disabled={this.state.loading}/>
        </InputGroup>

        <ButtonContainer>
          <Button size="lg"
                  component="button"
                  type="submit"
                  intent="primary"
                  loading={this.state.loading}>
            {t("passwordRenewResult.form.submitButton")}
          </Button>
        </ButtonContainer>

      </form>
    );
  }
}

interface PasswordRenewResultFormState {
  loading: boolean;
  uiState: 'initial' | 'password' | 'passcode' | 'done';
  resendCountdown: number;
  passcodeMsg?: string;
  error?: {
    title: string,
    desc: string
  }
}

interface PasswordRenewResultFormProps extends WithTranslation, RouteComponentProps {
  onRenew?: () => void;
  apiKey: string;
}

export default withTranslation()(withRouter(PasswordRenewResultForm));
