import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import StoriesService from "../../services/storiesService";
import { Alert, Spinner, Text } from "sancho";
import Utils from "../../services/utils";
import Markdown from "../Markdown/Markdown";
import { brandingService } from "../../services/brandingService";
import "./Story.scss";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import QueryService from "../../services/queryService";
import { StoryModel } from "../../env/models/StoryModel";

class Story extends Component<StoryProps, StoryState> {

  constructor(props: StoryProps) {
    super(props);
    this.state = {
      uiState: "initial"
    };
  }

  componentDidMount() {
    const {t, i18n} = this.props;

    if (!this.props.storyType && !this.props.storyId) {
      return;
    }

    // TODO make more adequate version of this
    let lng = this.props.lang || i18n.language;
    if (!["en"].includes(lng)) { // NOTE more supported languages for Stories here
      lng = "en"
    }

    StoriesService.getStories({
      brand: brandingService.currentBrand.id,
      lang: lng,
      storyType: this.props.storyType,
      storyId: this.props.storyId,
    })
      .then((data) => {
        if (!data || !data.stories || !data.stories[0]) {
          this.setState(prevState => ({
            ...prevState,
            uiState: "error",
            story: undefined,
            message: t("story.error.notFound"),
            messageDesc: undefined
          }));
          return;
        }

        let story = data.stories[0] as unknown as StoryModel;

        story.pages = (story.pages || [])
          .sort((a, b) => a.index - b.index);

        this.processStoryCustomActions(story);

        this.setState(prevState => ({
          ...prevState,
          uiState: "story",
          story: story
        }));
      }, (err) => {
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState(prevState => ({
          ...prevState,
          uiState: "error",
          story: undefined,
          message: errTitle,
          messageDesc: errDesc
        }));
      });
  }

  private processStoryCustomActions(story: StoryModel) {
    if (!story) {
      return;
    }
    let {history} = this.props;

    story.pages.forEach((page) => {
      let content = page.content || "";
      const regexp = /\[([^\]]*)\]\(pageAction\?index=([\d]*)\)/m;
      let match;
      do {
        match = content.match(regexp);
        if (!match) {
          break;
        }
        let fullMatch = match[0];
        let actionLabel = match[1] || "";
        let actionIndex = parseInt(match[2]);
        let matchIndex = match.index || 0;
        let matchLength = fullMatch.length;

        let action = page.actions?.find(a => a.index === actionIndex);
        if (!action || !action.storyId) {
          content = content.slice(0, matchIndex) + actionLabel + content.slice(matchIndex + matchLength);
          continue;
        }

        let actionUrl = history.createHref({
          pathname: "/story",
          search: QueryService.encodeQueryParams({
            storyId: action.storyId
          })
        });
        content = content.slice(0, matchIndex) + `[${actionLabel}](${actionUrl})` + content.slice(matchIndex + matchLength);
      } while (match);
      page.content = content;
    });
  }

  render() {
    //const {t} = this.props;

    let ui;

    if (this.state.uiState === "initial") {
      ui = (
        <Spinner center delay={100} size="xl"/>
      );
    } else if (this.state.uiState === "story" && this.state.story) {
      ui = (
        <div className="Story">
          <Text variant="h3">
            <Markdown src={this.state.story.title}/>
          </Text>
          {
            this.state.story.pages.map(page =>
              <div key={page.index}>
                <Text variant="h5">
                  <Markdown src={page.subtitle}/>
                </Text>
                <Text>
                  <Markdown src={page.content}/>
                </Text>
              </div>
            )
          }
        </div>
      );
    } else { // error
      ui = (
        <Alert intent="danger"
               title={this.state.message}
               subtitle={this.state.messageDesc}
               elevation="xs"/>
      );
    }

    return ui;
  }
}

interface StoryProps extends WithTranslation, RouteComponentProps {
  lang?: string,
  storyType?: number,
  storyId?: string
}

interface StoryState {
  story?: StoryModel;
  uiState: 'initial' | 'story' | 'error'
  message?: string;
  messageDesc?: string;
}

export default withTranslation()(withRouter(Story));
