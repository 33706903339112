export const brands: Brands = {
  defaultBrand: 'presencefamily',
  brands: [
    {
      id: 'presencefamily',
      className: 'presencefamilyBrand',
      hostname: 'app.peoplepowerfamily.com',
      urlParameter: 'presencefamily',
      name: 'People Power Family',
      logoUrl: '/assets/brands/presencefamily/logo.svg',
      logoLightUrl: '/assets/brands/presencefamily/logo-light.svg',
      logoSmUrl: '/assets/brands/presencefamily/logo-sm.svg',
      logoLightSmUrl: '/assets/brands/presencefamily/logo-light-sm.svg',
      appStoreHref: 'https://apps.apple.com/us/app/presence-family/id1454869628',
      googlePlayHref: 'https://play.google.com/store/apps/details?id=com.peoplepowerco.family',
      favicons: true,
      colors: {
        primary: '#3487ED',
        background: '#F2F5F7',
        theme: '#05274D'
      }
    },
    {
      id: 'duke',
      className: 'dukeBrand',
      hostname: 'familywise.peoplepowerco.com',
      urlParameter: 'duke',
      name: 'FamilyWise',
      logoUrl: '/assets/brands/duke/logo.svg',
      appStoreHref: 'https://testflight.apple.com/join/W7BjQT7O',
      googlePlayHref: 'https://appdistribution.firebase.dev/i/f98e1f032d9a21ac',
      favicons: false,
      colors: {
        primary: '#00789E',
        background: '#F2F5F7'
      }
    },
    {
      id: 'quil',
      className: 'quilBrand',
      hostname: 'app.peoplepowerfamily.com',
      urlParameter: 'quil',
      name: 'Quil Assure',
      logoUrl: '/assets/brands/quil/logo.svg',
      logoLightUrl: '/assets/brands/quil/logo-light.svg',
      logoSmUrl: '/assets/brands/quil/logo-sm.svg',
      logoLightSmUrl: '/assets/brands/quil/logo-light-sm.svg',
      appStoreHref: 'https://testflight.apple.com/join/kDD5mAUb',
      googlePlayHref: 'https://appdistribution.firebase.dev/i/3953f7f730ccb8bf',
      favicons: true,
      colors: {
        primary: '#00679F',
        background: '#F2F5F7'
      }
    },
    {
      id: 'pepco',
      className: 'pepcoBrand',
      hostname: 'pepco.peoplepowerco.com',
      urlParameter: 'pepco',
      name: 'Pepco Smart Home',
      logoUrl: '/assets/brands/pepco/logo.svg',
      logoLightUrl: '/assets/brands/pepco/logo-light.svg',
      appStoreHref: 'https://apps.apple.com/us/app/pepco-smart-home/id1444697563',
      googlePlayHref: 'https://play.google.com/store/apps/details?id=com.smarthome.pepco',
      favicons: true,
      colors: {
        primary: '#0067B1',
        background: '#F2F5F7'
      }
    },
    {
      id: 'delmarva',
      className: 'delmarvaBrand',
      hostname: 'dm.peoplepowerco.com',
      urlParameter: 'delmarva',
      name: 'Delmarva Smart Home',
      logoUrl: '/assets/brands/delmarva/logo.svg',
      logoLightUrl: '/assets/brands/delmarva/logo-light.svg',
      appStoreHref: 'https://apps.apple.com/us/app/delmarva-power-smart-home/id1444700347',
      googlePlayHref: 'https://play.google.com/store/apps/details?id=com.smarthome.delmarvapower',
      favicons: true,
      colors: {
        primary: '#0067B1',
        background: '#F2F5F7'
      }
    },
    {
      id: 'consumersenergy',
      className: 'consumersBrand',
      hostname: '',
      urlParameter: 'consumersenergy',
      name: 'Consumers Energy Smart Home',
      logoUrl: '/assets/brands/consumersenergy/logo.svg',
      logoLightUrl: '/assets/brands/consumersenergy/logo-light.svg',
      appStoreHref: 'https://apps.apple.com/us/app/consumers-energy-smart-home/id1497769698',
      googlePlayHref: 'https://play.google.com/store/apps/details?id=com.smarthome.energy',
      favicons: false,
      colors: {
        primary: '#4493b7',
        background: '#F2F5F7'
      }
    }
  ]
};

export const favicons: Favicon[] = [
  {
    filename: 'apple-touch-icon.png',
    rel: 'apple-touch-icon',
    sizes: '180x180'
  },
  {
    filename: 'favicon-32x32.png',
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32'
  },
  {
    filename: 'favicon-16x16.png',
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16'
  },
  {
    filename: 'site.webmanifest',
    rel: 'manifest'
  },
  {
    filename: 'safari-pinned-tab.svg',
    rel: 'mask-icon',
    //color: ''
  },
  {
    filename: 'favicon.ico',
    rel: 'shortcut icon'
  }
];

export interface Favicon {
  filename: string;
  rel: string;
  type?: string;
  sizes?: string;
}

export interface Brand {
  /**
   * Brand id (to distinguish between brands)
   */
  id: string;

  /**
   * Class name applied to application root when brand is active
   */
  className: string;

  /**
   * Domain name to detect if current brand is applicable. E.g. pepco.peoplepowerco.com for pepco
   */
  hostname: string;

  /**
   * This string is used to detect brand from query parameter.
   * You can change brand by adding query parameter ?brand=urlParameter to the URL
   */
  urlParameter: string;

  /**
   * Brand name to use in HTML markup - in headers, texts, etc.
   */
  name: string;

  /**
   * Meta description (optional, otherwise equal to name)
   */
  description?: string;

  /**
   * Logo URL
   */
  logoUrl?: string;

  /**
   * Logo URL for landing page
   */
  logoLightUrl?: string;

  /**
   * Logo URL for small screens
   */
  logoSmUrl?: string;

  /**
   * Logo URL for landing page for small screens
   */
  logoLightSmUrl?: string;

  /**
   * Standard favicons META links
   * Files generated by https://realfavicongenerator.net/ should be located in `public/assets/brands/{brand id}/favicon` folder
   * Example: <link rel="icon" type="image/png" sizes="32x32" href="/assets/brands/presencefamily/favicon/favicon-32x32.png">
   */
  favicons?: boolean;

  /**
   * Mobile application page on App Store
   */
  appStoreHref: string;

  /**
   * Mobile application page on Google Play
   */
  googlePlayHref: string;

  /**
   * Colors for the branded theme
   */
  colors: {
    primary: string;
    background: string;
    theme?: string;
  }
}

export interface Brands {
  defaultBrand: string;
  brands: Brand[];
}
