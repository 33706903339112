import React, { useEffect } from "react";
import { useToast } from "sancho";
import { toastService } from "../../services/toastService";

// -----------------------------
// NOTE: For env use only! To raise toasts - use `ToastService.show(...)` instead
// -----------------------------

export const ToastProvider: React.FunctionComponent = () => {
  const toast = useToast();
  const [, updateState] = React.useState<{}>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    const onToastShowUnsubscribe = toastService.onToastShow((toastConfig) => {
      toast(toastConfig);
      forceUpdate();
    });

    return () => { // It's a component destructor in hook form
      onToastShowUnsubscribe?.();
    }
  }, [toast, forceUpdate]);

  return (
    <>
    </>
  );

}
