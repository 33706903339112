import React from 'react';
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import { WithTranslation, withTranslation } from "react-i18next";
import AuthService from "../../services/authService";
import UserService from "../../services/userService";
import userService from "../../services/userService";
import { UserInformation } from "@ppc/webcore/dist/data/services/userService";
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from "react-router";
import QueryService from "../../services/queryService";
import { LoginUrlParams } from "../Login/LoginPage";
import { Alert, Button, Input, InputGroup, Text } from "sancho";
import theme from "../../theme";
import FormController, { FieldController } from "../../env/FormController";
import { isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input/mobile";
import Utils, { EMAIL_REGEX } from "../../services/utils";
import FlagComponent from "react-phone-number-input/modules/Flag";
import PhoneInput from "react-phone-number-input/input";
import { UserModel } from "@ppc/webcore/dist/data/api/app/userAccounts/updateUserApiResponse";
import { CommonUrlParams } from "../../env/models/CommonUrlParams";
import ButtonContainer from "../../components/structural/ButtonContainer/ButtonContainer";
import CenterPageContent from "../../components/structural/CenterPageContent/CenterPageContent";
import UserInfo from "../../components/UserInfo/UserInfo";

const DEFAULT_PHONE_COUNTRY = "US";

class EditAccountPage extends React.Component<EditAccountPageProps, EditAccountPageState> {

  private form?: FormController;

  private userInfo?: UserInformation;

  private readonly urlParams: EditAccountPageUrlParams;

  constructor(props: EditAccountPageProps) {
    super(props);

    this.urlParams = QueryService.getQueryParams(true) as EditAccountPageUrlParams;

    this.state = {
      initialLoading: true,
      loading: false,
      country: DEFAULT_PHONE_COUNTRY
    };

  }

  componentDidMount(): void {
    this.initUser()
      .then(() => {
        this.setState({
          initialLoading: false
        });
      });
  }

  private initForm() {
    const {t} = this.props;
    let userInfo = this.userInfo;
    if (!userInfo) {
      return;
    }

    let phone = "";
    if (userInfo.user.phone) {
      try {
        let phoneNumber = parsePhoneNumber(userInfo.user.phone);
        if (!phoneNumber) {
          phoneNumber = parsePhoneNumber("+" + userInfo.user.phone);
        }
        if (phoneNumber) {
          phone = phoneNumber.number;
          this.setState({country: phoneNumber.country || DEFAULT_PHONE_COUNTRY});
        }
      } catch (e) {
        phone = "";
      }
    }

    this.form = new FormController({
      onSubmit: () => this.onSubmit(),
      validators: [],
      fields: {
        phone: {
          value: phone,
          validators: [
            {
              message: t("account.edit.form.validation.phone.empty"),
              validate: (fieldCtrl: FieldController<string>) => !!fieldCtrl.value
            },
            {
              message: t("account.edit.form.validation.phone.invalid"),
              validate: (fieldCtrl: FieldController<string>) => isPossiblePhoneNumber(fieldCtrl.value)
            }
          ]
        },
        firstName: {
          value: userInfo.user.firstName || "",
          validators: [
            { // required
              message: t("account.edit.form.validation.firstName.empty"),
              validate: (fieldCtrl: FieldController<string>) => !!fieldCtrl.value
            }
          ]
        },
        lastName: {
          value: userInfo.user.lastName || "",
          validators: [
            { // required
              message: t("account.edit.form.validation.lastName.empty"),
              validate: (fieldCtrl: FieldController<string>) => !!fieldCtrl.value
            }
          ]
        },
        email: {
          value: userInfo.user.email?.email || "",
          validators: [
            { // required
              message: t("account.edit.form.validation.email.empty"),
              validate: (fieldCtrl: FieldController<string>) => !!fieldCtrl.value
            },
            { // required
              message: t("account.edit.form.validation.email.invalid"),
              validate: (fieldCtrl: FieldController<string>) => {
                return EMAIL_REGEX.test((fieldCtrl.value || "").toLowerCase());
              }
            }
          ]
        }
      }
    });
  }

  private initUser(force?: boolean): Promise<any> {
    if (AuthService.isAuthenticated() && !this.urlParams.tempKey) {
      return UserService.getCurrentUser(force)
        .then((userInfo: UserInformation) => {
          this.userInfo = userInfo;
          this.initForm();
        }, () => {
          this.redirectToLoginPage();
          return Promise.reject();
        });
    } else {
      this.redirectToLoginPage();
      return Promise.reject();
    }
  }

  private redirectToLoginPage() {
    let {location, history} = this.props;
    let redirectTo = location.pathname + QueryService.encodeQueryParams({
      ...this.urlParams,
      k: undefined,
      tempKey: undefined
    });
    let params: LoginUrlParams = {
      redirectTo: redirectTo,
      tempKey: this.urlParams.tempKey || undefined,
      user: this.urlParams.user || undefined
    };
    history.push(`/login${QueryService.encodeQueryParams(params)}`);
  }

  private gotoChangePassword() {
    let {history} = this.props;
    history.push(`/editpwd`);
  }

  private handlePhoneChange(value: string) {
    this.form!.fields.phone.value = value;

    try {
      const phoneNumber = parsePhoneNumber(value);
      if (phoneNumber) {
        this.setState({country: phoneNumber.country || DEFAULT_PHONE_COUNTRY});
      }
    } catch (e) {
      this.setState({country: DEFAULT_PHONE_COUNTRY});
    }

    this.forceUpdate()
  }

  private handleFirstNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.form!.fields.firstName.value = event.target.value;
    this.forceUpdate()
  }

  private handleLastNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.form!.fields.lastName.value = event.target.value;
    this.forceUpdate()
  }

  private handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.form!.fields.email.value = event.target.value;
    this.forceUpdate()
  }

  private handleBackButtonClick() {
    this.props.history.goBack();
  }

  private handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (this.state.loading) {
      return;
    }
    this.form!.submit(event);
    this.forceUpdate();
  }

  private onSubmit() {
    if (this.state.loading || this.state.initialLoading) {
      return;
    }

    let updateUserObj: UserModel = {};
    let somethingToSave = false;
    (Object.keys(this.form!.fields) as Array<keyof UserModel>)
      .forEach((fieldName) => {
        if (!this.form!.fields[fieldName].pristine) {
          updateUserObj[fieldName] = this.form!.fields[fieldName].value;
          somethingToSave = true;
        }
      });
    if (!somethingToSave) {
      return;
    }

    this.setState({loading: true});
    userService.updateCurrentUser(updateUserObj)
      .then(() => this.initUser(true))
      .then(() => {
        this.setState({
          loading: false,
          successMessage: true
        });
      })
      .catch((err) => {
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState({
          loading: false,
          error: {
            title: errTitle,
            desc: errDesc
          }
        });
      })
  }

  render() {
    const {loading, initialLoading, successMessage} = this.state;
    const {t} = this.props;

    if (initialLoading || !this.userInfo || !this.form) {
      // todo skeleton
      return null;
    }

    let error;
    if (this.state.error) {
      error = (
        <Alert
          style={{marginTop: theme.spaces.md}}
          elevation="xs"
          intent="danger"
          title={t(this.state.error.title)}
          subtitle={t(this.state.error.desc)}/>
      );
    }

    return (
      <BrandedPageShell>
        <CenterPageContent>
          <div className="EditAccountPage">
            <UserInfo/>

            {/* Removed due to https://presence.atlassian.net/browse/EVO-52 */}
            {/*<ButtonContainer>*/}
            {/*  <Button size="lg"*/}
            {/*          component="button"*/}
            {/*          intent="primary"*/}
            {/*          onClick={() => this.gotoChangePassword()}>*/}
            {/*    {t('account.edit.btn.changePassword')}*/}
            {/*  </Button>*/}
            {/*</ButtonContainer>*/}

            <div className="EditAccountFormContainer">
              <Text variant="uppercase">
                {t("account.edit.header")}
              </Text>
              <form className="EditAccountForm"
                    onSubmit={(event) => this.handleFormSubmit(event)}
                    aria-disabled={loading}>

                <InputGroup hideLabel
                            error={this.form.submitted && this.form.fields.phone.validationMessages[0]}
                            label={t("account.edit.form.phone.label")}>

                  <div className="PhoneInputContainer">
                    <FlagComponent country={this.state.country}
                                   countryName={this.state.country}
                                   flagUrl="/assets/images/country-flag-icons/{XX}.svg"/>

                    <PhoneInput name="passcodeUsername"
                                className="PhoneInput"
                                value={this.form.fields.phone.value}
                                onChange={(value: string) => this.handlePhoneChange(value)}
                                defaultCountry={DEFAULT_PHONE_COUNTRY}
                                placeholder={t("account.edit.form.phone.label")}
                                disabled={loading}/>
                  </div>
                </InputGroup>

                <InputGroup hideLabel
                            error={this.form.submitted && this.form.fields.firstName.validationMessages[0]}
                            label={t("account.edit.form.firstName.label")}>

                  <Input inputSize="lg"
                         name="firstName"
                         value={this.form.fields.firstName.value}
                         onChange={(event) => this.handleFirstNameChange(event)}
                         placeholder={t("account.edit.form.firstName.label")}
                         disabled={loading}/>
                </InputGroup>

                <InputGroup hideLabel
                            error={this.form.submitted && this.form.fields.lastName.validationMessages[0]}
                            label={t("account.edit.form.lastName.label")}>

                  <Input inputSize="lg"
                         name="lastName"
                         value={this.form.fields.lastName.value}
                         onChange={(event) => this.handleLastNameChange(event)}
                         placeholder={t("account.edit.form.lastName.label")}
                         disabled={loading}/>
                </InputGroup>

                <InputGroup hideLabel
                            error={this.form.submitted && this.form.fields.email.validationMessages[0]}
                            label={t("account.edit.form.email.label")}>

                  <Input inputSize="lg"
                         type="email"
                         name="email"
                         value={this.form.fields.email.value}
                         onChange={(event) => this.handleEmailChange(event)}
                         placeholder={t("account.edit.form.email.label")}
                         disabled={loading}/>
                </InputGroup>

                <ButtonContainer>
                  <Button size="lg"
                          component="button"
                          type="submit"
                          intent="primary"
                          disabled={this.form.pristine || (this.form.submitted && !this.form.valid)}
                          loading={loading}>
                    {t("account.edit.btn.submit")}
                  </Button>

                  <Button size="lg"
                          variant="ghost"
                          loading={loading}
                          onClick={() => this.handleBackButtonClick()}>
                    {t("account.edit.btn.back")}
                  </Button>
                </ButtonContainer>

                {error}

                {successMessage && (
                  <Alert
                    style={{marginTop: theme.spaces.md}}
                    elevation="xs"
                    intent="success"
                    title={t('account.edit.success')}/>
                )}

              </form>
            </div>
          </div>
        </CenterPageContent>
      </BrandedPageShell>
    );
  }
}

interface EditAccountPageProps extends WithTranslation, RouteComponentProps {

}

interface EditAccountPageState {
  initialLoading: boolean;
  loading: boolean;
  country: string;
  successMessage?: boolean;
  error?: {
    title: string,
    desc: string
  }
}

interface EditAccountPageUrlParams extends CommonUrlParams {
  /**
   * Username for login
   */
  user?: string;
}

export default withTranslation()(withRouter(EditAccountPage));
