import React, { Component } from 'react';
import i18n from '../../i18n';
import QueryService from "../../services/queryService";
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import { Alert, Text } from "sancho";
import { WithTranslation, withTranslation } from "react-i18next";
import CenterPageContent from "../../components/structural/CenterPageContent/CenterPageContent";

class VerifyPage extends Component<VerifyPageProps, VerifyPageState> {
  constructor(props: VerifyPageProps) {
    super(props);
    this.state = {
      resultCode: 0,
      message: i18n.t('verify.results.unsuccess'),
      description: i18n.t('verify.results.unavailable')
    };
  }

  componentDidMount() {
    const {t} = this.props;
    const queryParams = QueryService.getQueryParams();
    const props = {
      ...queryParams,
      resultCode: queryParams?.resultCode !== undefined && parseInt(queryParams.resultCode),
      t: queryParams?.t !== undefined && parseInt(queryParams.t),
    } as VerifyPageUrlParams;

    // Set result messaging
    let pageMsg = this.state.message;
    let pageDesc = this.state.description;
    switch (props.resultCode) {
      case 0:
        pageMsg = t('verify.results.success');
        pageDesc = t('verify.desc');
        if (props.t === 1) {
          pageDesc = t('verify.email.desc');
        } else if (props.t === 2) {
          pageDesc = t('verify.phone.desc');
        }
        break;
      case 1:
        pageDesc = t('verify.results.unavailable');
        break;
      case 2:
        pageDesc = t('verify.results.expired');
        break;
    }

    // Set page title
    let pageTitle = t('verify.title');
    switch (props.t) {
      case 1:
        pageTitle = t('verify.email.title');
        break;
      case 2:
        pageTitle = t('verify.phone.title');
        break;
    }
    document.title = pageTitle;

    this.setState({
      resultCode: props.resultCode || 0,
      message: pageMsg,
      description: pageDesc,
      pageTitle: pageTitle
    });
  }

  render() {
    // const {t} = this.props;

    const {message, description, pageTitle, resultCode} = this.state;
    let intent: AlertIntentions = "success";
    if (resultCode !== 0) {
      intent = "warning";
    }

    return (
      <BrandedPageShell withLogo>
        <CenterPageContent>
          <Text variant="h5">{pageTitle}</Text>
          <Alert intent={intent}
                 title={message}
                 subtitle={description}
                 elevation="xs"/>
        </CenterPageContent>
      </BrandedPageShell>
    );
  }
}

/* Query Properties */
type VerifyPageUrlParams = {
  resultCode: number;
  t: VerifyType;
}

enum VerifyType {
  Default, Email, Phone
}

interface VerifyPageState {
  resultCode: number;
  message: string;
  description: string;
  pageTitle?: string;
}

interface VerifyPageProps extends WithTranslation {

}

export type AlertIntentions =
  | "info"
  | "success"
  | "question"
  | "danger"
  | "warning";

export default withTranslation()(VerifyPage);
