import React, { Component } from "react";
import { IconCalendar, IconHome, Skeleton, Text } from "sancho";

const DEFAULT_ICON = "calendar";

class HeadInfo extends Component<HeadInfoProps> {

  render() {
    const {
      loading = false,
      icon = DEFAULT_ICON,
      subIcon = false,
      header = "",
      subheader = ""
    } = this.props;

    let iconElement;
    if (!loading) {
      if (icon === 'home') {
        iconElement = (
          <IconHome size="xl"/>
        );
      } else if (icon === 'calendar') {
        iconElement = (
          <IconCalendar size="xl"/>
        );
      }
    }

    return (
      <>
        <div className="HeadInfoContainer">
          {loading ? (
            <div className="IconContainer">
              <Skeleton animated css={{
                display: "inline-block",
                borderRadius: "50%"
              }}/>
            </div>
          ) : (
            <div className="IconContainer Loaded">
              {iconElement}
            </div>
          )}

          <div className="TextContainer">
            {loading ? (
              <>
                <div style={{width: "100px", maxWidth: "80%"}}>
                  <Skeleton animated/>
                </div>
                <div style={{width: "150px", maxWidth: "100%"}}>
                  <Skeleton animated/>
                </div>
              </>
            ) : (
              <>
                <Text variant="h3">
                  {header}
                </Text>

                <Text variant="h5" muted={true}>
                  {subIcon && (<IconHome/>)}
                  {subheader}
                </Text>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

interface HeadInfoProps {
  icon?: "home" | "calendar";
  header?: string;
  subheader?: string;
  subIcon?: boolean;
  loading?: boolean;
}

export default HeadInfo;
