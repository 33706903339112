import { defaultTheme } from 'sancho';
import { brandingService } from './services/brandingService';

/**
 * Global application theme
 * NOTE: if you making any changes here - you need to fix the same in src/styles/theme.scss file
 * NOTE: if you making brand-related changes - you need to also change behaviour in src/styles/theming.scss file
 */
const theme = {
  mode: 'light', // light or dark
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    text: {
      ...defaultTheme.colors.text,
      selected: '#787F84'
    },
    intent: {
      ...defaultTheme.colors.intent,
      primary: {
        ...defaultTheme.colors.intent.primary,
        base: brandingService.currentBrand.colors.primary
      },
      success: {
        ...defaultTheme.colors.intent.success,
        base: '#67BD45'
      },
      danger: {
        ...defaultTheme.colors.intent.danger,
        base: '#D0021B'
      },
      warning: {
        ...defaultTheme.colors.intent.warning,
        base: '#F8890C'
      }
    },
    background: {
      ...defaultTheme.colors.background,
      default: brandingService.currentBrand.colors.background
    },
    modes: {
      ...defaultTheme.modes,
      ligth: {
        ...defaultTheme.modes.light,
        text: {
          ...defaultTheme.modes.light.text,
          selected: '#787F84'
        }
      }
    }
  }
};

export default theme;
