import React, { Component } from "react";
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import { WithTranslation, withTranslation } from "react-i18next";
import QueryService from "../../services/queryService";
import { CommonUrlParams } from "../../env/models/CommonUrlParams";
import Story from "../../components/Story/Story";
import CenterPageContent from "../../components/structural/CenterPageContent/CenterPageContent";

class StoryPage extends Component<StoryPageProps, StoryPageState> {
  private readonly urlParams: StoryPageUrlParams;

  constructor(props: StoryPageProps) {
    super(props);
    this.urlParams = QueryService.getQueryParams() as StoryPageUrlParams;
  }

  render() {
    const {i18n} = this.props;
    return (
      <BrandedPageShell>
        <CenterPageContent>
          <Story lang={i18n.language} storyId={this.urlParams.storyId}/>
        </CenterPageContent>
      </BrandedPageShell>
    );
  }
}

interface StoryPageProps extends WithTranslation {

}

interface StoryPageState {

}

interface StoryPageUrlParams extends CommonUrlParams {
  storyId: string;
}

export default withTranslation()(StoryPage);
