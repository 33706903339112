/**
 * Applications that allow access to them
 * @type {OAuthHosts}
 */
export const OAUTH_HOSTS: OAuthHosts = {
  "2": {
    name: "Twitter",
    i18nKey: "twitter",
    logo: "/assets/images/oauth/logo-twitter.svg"
  },
  "6": {
    name: "Netatmo",
    i18nKey: "netatmo",
    logo: "/assets/images/oauth/logo-netatmo.png"
  },
  "7": {
    name: "Sensibo",
    i18nKey: "sensibo",
    logo: "/assets/images/oauth/logo-sensibo.png"
  },
  "8": {
    name: "Honeywell",
    i18nKey: "honeywell",
    logo: "/assets/images/oauth/logo-honeywell.png"
  },
  "9": {
    name: "Logitech",
    i18nKey: "logitech",
    logo: "/assets/images/oauth/logo-logitech.png"
  },
  "10": {
    name: "Ecobee",
    i18nKey: "ecobee",
    logo: "/assets/images/oauth/logo-ecobee.png"
  }
};

export interface OAuthHosts {
  [appId: string]: {
    /**
     * OAuth host name
     */
    name: string;

    /**
     * OAuth host logo URL
     */
    logo: string

    /**
     * Translation key for OAuth host
     */
    i18nKey: string
  }
}
