import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Alert, Button, IconCalendar, Skeleton, IconChevronRight, IconHome, IconDollarSign, Layer, List, ListSection, ListItem, Text, IconCreditCard } from "sancho";
import UserService from "../../../services/userService";
import { UserInformation } from "@ppc/webcore/dist/data/services/userService";
import { UserLocation } from "../../../env/models/UserLocation";
import ChargifyForm from "../ChargifyForm/ChargifyForm";
import utils from "../../../services/utils";
import Utils from "../../../services/utils";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { subscriptionsService } from "../../../services/subscriptionsService";
import { ServicePlans, Subscriptions, UserPaymentProfiles } from "@ppc/webcore/dist/data/services/subscriptionsService";
import SkeletonListItem from "../../SkeletonListItem/SkeletonListItem";
import { Subscription } from "../../../env/models/Subscription";
import { ServicePlan, ServicePlanPrice } from "../../../env/models/ServicePlan";
import { SubscriptionPageUrlParams } from "../../../pages/Subscription/Subscription";
import QueryService from "../../../services/queryService";
import { PaymentProfile } from "../../../env/models/PaymentProfile";
import { PurchaseUrlParams } from "../../../pages/Purchase/Purchase";
import ButtonContainer from "../../structural/ButtonContainer/ButtonContainer";
import UserInfo from "../../UserInfo/UserInfo";
import HeadInfo from "../../HeadInfo/HeadInfo";
import { SubscriptionStatus } from "@ppc/webcore/dist/data/api/app/paidServices/getLocationSubscriptionsApiResponse";

class PurchaseOrUpgradeSubscription extends Component<PurchaseOrUpgradeSubscriptionProps, PurchaseOrUpgradeSubscriptionState> {

  private userInfo?: UserInformation;
  private selectedLocation?: UserLocation;
  private subscriptions?: Subscriptions;
  private servicePlans?: ServicePlans;
  private userPaymentProfiles?: UserPaymentProfiles;

  private servicePlanToBuy?: ServicePlan;
  private servicePlanPriceToBuy?: ServicePlanPrice;

  private backNavigationListenerDestructor?: () => void;

  private uiStateHistory: Array<PurchaseOrUpgradeSubscriptionState["uiState"]> = [];

  constructor(props: PurchaseOrUpgradeSubscriptionProps) {
    super(props);
    this.state = {
      loading: true,
      uiState: "initial"
    };
  }

  componentDidMount() {
    this.backNavigationListenerDestructor = this.props.history.listen((newLocation, action) => {
      if (action === "POP") {
        if (this.uiStateHistory.length <= 1) {
          this.props.history.goBack();
        } else {
          this.uiStateHistory.pop();
          let lastState = this.uiStateHistory[this.uiStateHistory.length - 1];
          this.setState({uiState: lastState!});
        }
      }
    });

    Promise.all([
      this.initUser(),
      this.initUserPaymentProfiles()
    ])
      .then(() => this.processLocations())
      .then(() => {
        this.setState({
          loading: false
        });
      })
      .catch((err) => {
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState({
          uiState: "error",
          loading: false,
          error: {
            title: errTitle,
            desc: errDesc
          }
        });
      });
  }

  componentWillUnmount() {
    this.backNavigationListenerDestructor && this.backNavigationListenerDestructor();
  }

  UNSAFE_componentWillUpdate(nextProps: Readonly<PurchaseOrUpgradeSubscriptionProps>, nextState: Readonly<PurchaseOrUpgradeSubscriptionState>, nextContext: any): void {
    let uiState = nextState.uiState;
    if (uiState !== 'initial' && this.state.uiState !== uiState && (this.uiStateHistory.length === 0 || this.uiStateHistory[this.uiStateHistory.length - 1] !== uiState)) {
      this.uiStateHistory.push(uiState);
      let {history, location} = this.props;
      history.push(`${location.pathname}${QueryService.encodeQueryParams({
        ...QueryService.getQueryParams(),
        locationId: this.selectedLocation?.id,
        plan: this.servicePlanToBuy?.id,
        priceId: this.servicePlanPriceToBuy?.id
      })}`);
    }
  }

  componentDidUpdate(prevProps: Readonly<PurchaseOrUpgradeSubscriptionProps>, prevState: Readonly<PurchaseOrUpgradeSubscriptionState>) {

  }

  private goBack() {
    this.props.history.goBack();
  }

  private initUser(): Promise<any> {
    return UserService.getCurrentUser()
      .then((userInfo: UserInformation) => {
        this.userInfo = userInfo;
      });
  }

  private processLocations(): Promise<any> {
    const {t} = this.props;
    let urlParams: PurchaseUrlParams = QueryService.getQueryParams();

    if (!this.userInfo) {
      return Promise.reject();
    }

    if (!this.userInfo.locations || this.userInfo.locations.length === 0) {
      // no locations at user account
      this.setState({
        uiState: "error",
        error: {
          title: t('purchase.errors.noLocations.header'),
          desc: t('purchase.errors.noLocations.desc')
        }
      });
    } else if (urlParams.locationId) {
      let selectedLocation = this.userInfo.locations.find(l => l.id.toString() === urlParams.locationId!.toString());
      if (selectedLocation) {
        this.selectedLocation = selectedLocation;
        this.chooseLocation(this.selectedLocation);
      } else {
        console.warn(`Current user has no location with id ${urlParams.locationId} described in URL params`);
        this.setState({
          uiState: "error",
          error: {
            title: t('purchase.errors.locationNotFound.header', {locationId: urlParams.locationId}),
            desc: t('purchase.errors.locationNotFound.desc')
          }
        });
      }
    } else if (this.userInfo.locations.length === 1) {
      // select first location if we have only one
      this.selectedLocation = this.userInfo.locations[0];
      this.chooseLocation(this.selectedLocation);
    } else {
      this.setState({
        uiState: "chooseLocation"
      });
    }
    return Promise.resolve();
  }

  private initSubscriptions(): Promise<any> {
    return subscriptionsService.getLocationSubscriptions(this.selectedLocation!.id, 0, true)
      .then((subscriptions) => {
        this.subscriptions = subscriptions;
      });
  }

  private initUserPaymentProfiles() {
    return subscriptionsService.getUserPaymentProfiles()
      .then((profiles) => {
        this.userPaymentProfiles = profiles;
      });
  }

  private initServicePlans() {
    return subscriptionsService.getServicePlans(this.selectedLocation!.id)
      .then((servicePlans) => {
        this.servicePlans = servicePlans;
      });
  }

  private processSubscriptionsAndPlans() {
    const {t} = this.props;
    let urlParams: PurchaseUrlParams = QueryService.getQueryParams();

    if (!this.subscriptions || !this.servicePlans) {
      return;
    }

    // Current active subscriptions
    let subscriptionsToShow = (this.subscriptions.subscriptions || [])
      .filter(s => s.status !== SubscriptionStatus.ExpiredOrCancelled && !s.cancelled && s.endDateMs > new Date().getTime());

    // Active subscriptions that we can buy by Chargify
    let servicePlansToShow = (this.servicePlans.servicePlans || [])
      .filter(plan => plan.prices && plan.prices.some(price => price.paymentType === 4) && plan.available);

    // Check if we have service plan id in URL params
    let preselectedPlanId = parseInt(urlParams.plan || '');

    if (!preselectedPlanId) {
      // if no service plan id in URL params - show usual UI
      this.setState({
        uiState: "chooseSubscriptionOrPlan"
      });
      return;
    }

    // look for the plan with such id
    let preselectedPlan = servicePlansToShow.find(p => p.id === preselectedPlanId);
    if (!preselectedPlan) {
      // if nothing found - show error
      this.setState({
        uiState: "error",
        error: {
          title: t('purchase.errors.planNotFound.header', {servicePlanId: preselectedPlanId}),
          desc: t('purchase.errors.planNotFound.desc')
        }
      });
      return;
    }

    // look for some active subscription that is represent selected plan.
    // Because we do not allow user to buy more than one subscription
    let subscriptionForPlan = subscriptionsToShow
      .find(s => (s.updatePlan && s.updatePlan.id === preselectedPlanId) || (s.plan.id === preselectedPlanId));
    if (subscriptionForPlan) {
      // navigate to subscription page with special message
      let {history} = this.props;
      let params: SubscriptionPageUrlParams = {
        userPlanId: subscriptionForPlan.userPlanId.toString(),
        locationId: subscriptionForPlan.locationId.toString(),
        msg: t('purchase.targetPlanIsAlreadyPurchased')
      };
      history.push(`/subscription${QueryService.encodeQueryParams(params)}`);

      return;
    }

    // Look for subscription that can be upgraded to selected plan
    let subscriptionToUpgradeToPlan = subscriptionsToShow
      .find(s => s.upgradableTo && s.upgradableTo.some(id => id === preselectedPlanId));
    if (subscriptionToUpgradeToPlan) {
      // navigate to subscription page to upgrade to particular plan
      let {history} = this.props;
      let params: SubscriptionPageUrlParams = {
        userPlanId: subscriptionToUpgradeToPlan.userPlanId.toString(),
        locationId: subscriptionToUpgradeToPlan.locationId.toString(),
        upgradeToPlanId: preselectedPlanId.toString()
      };
      history.push(`/subscription${QueryService.encodeQueryParams(params)}`);

      return;
    }

    this.chooseServicePlanToBuy(preselectedPlan);
  }

  private processUserPaymentProfiles() {
    if (this.userPaymentProfiles && this.userPaymentProfiles.paymentProfiles && this.userPaymentProfiles.paymentProfiles.length > 0) {
      this.setState({
        uiState: "choosePaymentMethod"
      });
    } else {
      this.setState({
        uiState: "newPaymentMethod"
      });
    }
  }

  private processPrices() {
    let {t} = this.props;
    let urlParams: PurchaseUrlParams = QueryService.getQueryParams();

    let pricesToShow = this.servicePlanToBuy!.prices
      .filter(p => p.paymentType === 4 && (p.free === false || p.free == null));
    if (urlParams.priceId) {
      let price = pricesToShow.find(p => p.id.toString() === urlParams.priceId);
      if (price) {
        this.choosePriceToBuy(price);
      } else {
        this.setState({
          uiState: "error",
          error: {
            title: t('purchase.errors.priceNotFound.header', {priceId: urlParams.priceId}),
            desc: t('purchase.errors.priceNotFound.desc')
          }
        });
      }
      return;
    } else if (pricesToShow.length === 1) {
      this.choosePriceToBuy(pricesToShow[0]);
      return;
    }
    this.setState({uiState: "choosePrice"});
  }

  private chooseServicePlanToBuy(servicePlan: ServicePlan) {
    this.servicePlanToBuy = servicePlan;
    this.processPrices();
  }

  private choosePriceToBuy(price: ServicePlanPrice) {
    this.servicePlanPriceToBuy = price;
    this.processUserPaymentProfiles();
  }

  private chooseLocation(location: UserLocation) {
    this.selectedLocation = location;
    if (this.selectedLocation?.locationAccess !== 30) {
      this.noLocationAccess();
      return;
    }

    Promise.all([
      this.initServicePlans(),
      this.initSubscriptions()
    ]).then(() => {
      return this.processSubscriptionsAndPlans();
    }, () => this.noLocationAccess());
  }

  private chooseSubscription(subscription: Subscription) {
    if (!subscription) {
      return;
    }
    let {history} = this.props;
    let params: SubscriptionPageUrlParams = {
      userPlanId: subscription.userPlanId.toString(),
      locationId: subscription.locationId.toString(),
      from: "purchase"
    };
    history.push(`/subscription${QueryService.encodeQueryParams(params)}`);
  }

  private onChargifyTokenReady(token: string) {
    this.setState({
      loading: true
    });
    subscriptionsService.provideNewPurchaseInfo(
      {
        paymentToken: token
      },
      this.servicePlanPriceToBuy!.id,
      this.selectedLocation!.id)
      .then(() => {
        this.setState({
          loading: false,
          uiState: "purchaseSuccess"
        });
      }, (err) => {
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState({
          loading: false,
          uiState: "error",
          error: {
            title: errTitle,
            desc: errDesc
          }
        });
      });
  }

  private gotoNewPaymentMethod() {
    this.setState({
      uiState: "newPaymentMethod"
    });
  }

  private noLocationAccess() {
    this.setState({
      uiState: "locationAccessDenied"
    });
  }

  private choosePaymentProfile(paymentProfile: PaymentProfile) {
    this.setState({
      loading: true
    });
    subscriptionsService.provideNewPurchaseInfo(
      {
        paymentProfileId: paymentProfile.id
      },
      this.servicePlanPriceToBuy!.id,
      this.selectedLocation!.id)
      .then(() => {
        this.setState({
          loading: false,
          uiState: "purchaseSuccess"
        });
      }, (err) => {
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState({
          loading: false,
          uiState: 'error',
          error: {
            title: errTitle,
            desc: errDesc
          }
        });
      });
  }

  private reloadPage() {
    window.location.reload();
  }

  private goToAccountPage() {
    let {history} = this.props;
    history.push(`/account`);
  }

  private selectAnotherLocation() {
    this.selectedLocation = undefined;
    this.setState({uiState: "chooseLocation"});
  }

  private selectAnotherPlan() {
    this.servicePlanToBuy = undefined;
    this.setState({uiState: "chooseSubscriptionOrPlan"});
  }

  private selectAnotherPrice() {
    this.servicePlanPriceToBuy = undefined;
    this.setState({uiState: "choosePrice"});
  }

  render() {
    const {t} = this.props;

    let ui = null;

    if (this.state.uiState === "initial" || this.state.loading) {
      ui = (
        <>
          <div className="HeadContainer">
            <UserInfo/>
          </div>
          <div style={{width: "70%"}}>
            <Skeleton animated />
          </div>
          <Layer elevation="xs"
                 className="ListLayer">
            <List>
              <SkeletonListItem animated/>
              <SkeletonListItem animated/>
            </List>
          </Layer>
        </>
      );
    } else if (this.state.uiState === "chooseLocation" && this.userInfo && this.userInfo.locations) {
      let availableLocations: UserLocation[] = [];
      let limitedLocations: UserLocation[] = [];
      this.userInfo.locations.forEach(location => {
        if (location.locationAccess === 30) {
          availableLocations.push(location);
        } else {
          limitedLocations.push(location);
        }
      });

      ui = (
        <>
          <div className="HeadContainer">
            <UserInfo/>
          </div>
          <Text variant="lead">
            {t('purchase.selectLocation')}
          </Text>
          <Layer elevation="xs"
                 className="ListLayer">

            {!!availableLocations.length && (
              <ListSection title={t('purchase.availableLocations')}>
                <List>
                  {
                    availableLocations.map(location => (
                      <ListItem
                        className={"ListItem OverflowHidden " + (!!limitedLocations.length && "DisableRoundCorners")}
                        wrap={true}
                        key={location.id}
                        primary={
                          <Text variant="lead" muted={!location.name}>
                            {location.name || t('purchase.unknownLocation')}
                          </Text>
                        }
                        secondary={
                          <Text muted={true}>
                            {utils.getLocationAddressString(location) || t('purchase.unknownAddress')}
                          </Text>
                        }
                        onPress={() => this.chooseLocation(location)}
                        contentBefore={<IconHome size="xl"/>}
                        contentAfter={<IconChevronRight/>}
                      />
                    ))
                  }
                </List>
              </ListSection>
            )}
            
            {!!limitedLocations.length && (
              <ListSection title={t('purchase.limitedLocations')}>
                <List>
                  {
                    limitedLocations.map(location => (
                      <ListItem
                        className="ListItem OverflowHidden"
                        wrap={true}
                        key={location.id}
                        primary={
                          <Text variant="lead" muted={!location.name}>
                            {location.name || t('purchase.unknownLocation')}
                          </Text>
                        }
                        secondary={
                          <Text muted={true}>
                            {utils.getLocationAddressString(location) || t('purchase.unknownAddress')}
                          </Text>
                        }
                        onPress={() => this.chooseLocation(location)}
                        contentBefore={<IconHome size="xl"/>}
                        contentAfter={<IconChevronRight/>}
                      />
                    ))
                  }
                </List>
              </ListSection>
            )}
          </Layer>
        </>
      );
    } else if (this.state.uiState === "locationAccessDenied") {
      ui = (
        <>
          <div className="HeadContainer">
            <HeadInfo icon="home"
                      header={this.selectedLocation?.name || t('subscription.unknownLocation')}
                      subheader={this.selectedLocation && utils.getLocationAddressString(this.selectedLocation)}
                      loading={false}/>
          </div>

          <Alert intent="warning"
                 title={t('purchase.errors.noLocationAccess.header')}
                 subtitle={t('purchase.errors.noLocationAccess.desc', {locationName: this.selectedLocation?.name || t('subscription.unknownLocation')})}
                 elevation="xs"/>

          {!!this.userInfo?.locations && this.userInfo.locations.length > 1 && (
            <ButtonContainer>
              <Button size="lg"
                      variant="ghost"
                      loading={this.state.loading}
                      onClick={() => this.selectAnotherLocation()}>
                {t('purchase.btn.selectAnotherLocation')}
              </Button>
            </ButtonContainer>
          )}
        </>
      );
    } else if (this.state.uiState === "chooseSubscriptionOrPlan") {
      if (!this.subscriptions || !this.servicePlans) {
        ui = (
          <>
            <div className="HeadContainer">
              <HeadInfo icon="home"
                        header={this.selectedLocation?.name || t('subscription.unknownLocation')}
                        subheader={this.selectedLocation && utils.getLocationAddressString(this.selectedLocation)}
                        loading={false}/>
            </div>
            <div style={{width: "70%"}}>
              <Skeleton animated />
            </div>
            <Layer elevation="xs"
                   className="ListLayer">
              <List>
                <SkeletonListItem animated/>
                <SkeletonListItem animated/>
              </List>
            </Layer>
          </>
        );
      } else {
        let subscriptionsToShow = (this.subscriptions.subscriptions || [])
          .filter(s => s.status !== SubscriptionStatus.ExpiredOrCancelled && !s.cancelled && s.endDateMs > new Date().getTime());

        let servicePlansToShow = (this.servicePlans.servicePlans || [])
          .filter(plan => plan.prices && plan.prices.some(price => price.paymentType === 4)
            && plan.available
            && !subscriptionsToShow.some(s => s.plan.id === plan.id)
            && !subscriptionsToShow.some(s => s.updatePlan && s.updatePlan.id === plan.id)
            && !subscriptionsToShow.some(s => s.upgradableTo && s.upgradableTo.some(toId => toId === plan.id)));

        ui = (
          <>
            <div className="HeadContainer">
              <HeadInfo icon="home"
                        header={this.selectedLocation?.name || t('subscription.unknownLocation')}
                        subheader={this.selectedLocation && utils.getLocationAddressString(this.selectedLocation)}
                        loading={false}/>
            </div>
            <Text variant="lead">
              {t('purchase.selectSubscription')}
            </Text>

            {subscriptionsToShow && subscriptionsToShow.length > 0 && (
              <Layer elevation="xs"
                     className="ListLayer">
                <ListSection title={t('account.subscriptions.activeHeader')}>
                  <List>
                    {subscriptionsToShow.map(subscription => {
                      return (
                        <ListItem 
                          className="ListItem OverflowHidden"
                          wrap={true}
                          key={subscription.userPlanId}
                          contentBefore={
                            <IconCalendar size="xl"/>
                          }
                          primary={
                            <Text variant="lead">
                              {subscription.plan.name}
                            </Text>
                          }
                          secondary={
                            <Text muted={true}>
                              {subscription.plan.desc}
                            </Text>
                          }
                          onClick={() => this.chooseSubscription(subscription)}
                          contentAfter={<IconChevronRight/>}
                        />
                      );
                    })}
                  </List>
                </ListSection>
              </Layer>
            )}

            {servicePlansToShow && servicePlansToShow.length > 0 && (
              <Layer elevation="xs"
                     className="ListLayer">
                <ListSection title={t('purchase.availableSubscriptions')}>
                  <List>
                    {servicePlansToShow.map(plan => {
                      return (
                        <ListItem
                          className="ListItem"
                          wrap={true}
                          key={plan.id}
                          contentBefore={
                            <IconCalendar size="xl"/>
                          }
                          primary={
                            <Text variant="lead">
                              {plan.name}
                            </Text>
                          }
                          secondary={
                            <Text muted={true}>
                              {plan.desc}
                            </Text>
                          }
                          contentAfter={<IconChevronRight/>}
                          onClick={() => this.chooseServicePlanToBuy(plan)}
                        />
                      );
                    })}
                  </List>
                </ListSection>
              </Layer>
            )}

            {!!this.userInfo?.locations && this.userInfo.locations.length > 1 && (
              <ButtonContainer>
                <Button size="lg"
                        variant="ghost"
                        loading={this.state.loading}
                        onClick={() => this.selectAnotherLocation()}>
                  {t('purchase.btn.selectAnotherLocation')}
                </Button>
              </ButtonContainer>
            )}
          </>
        );
      }
    } else if (this.state.uiState === "choosePrice") {
      ui = (
        <>
          <div className="HeadContainer">
            <HeadInfo icon="calendar"
                      header={this.servicePlanToBuy?.name || this.servicePlanToBuy?.desc}
                      subheader={this.selectedLocation?.name || t("subscription.unknownLocation")}
                      subIcon={true}
                      loading={false}/>
          </div>
          <Text variant="lead">
            {t('purchase.selectPrice')}
          </Text>
          <Layer elevation="xs"
                 className="ListLayer">
            <List>
              <ListSection title={t("purchase.subscriptionOptions")}>
                {
                  this.servicePlanToBuy!.prices
                    .filter(p => p.paymentType === 4 && (p.free === false || p.free == null))
                    .map(price => {
                      let priseStr;
                      if (!!price.currencySymbol && !!price.totalAmount) {
                        priseStr = t('purchase.priceFormat', {
                          currencySymbol: price.currencySymbol,
                          amount: price.totalAmount
                        });
                      } else if (price.free) {
                        priseStr = t('purchase.priceFree');
                      }

                      return (
                        <ListItem
                          className="ListItem"
                          key={`${this.servicePlanToBuy!.id}-${price.id}`}
                          primary={
                            <Text variant="lead">
                              {t(`purchase.priceTypes.${price.type}`)}
                            </Text>
                          }
                          secondary={
                            <Text muted={true}>
                              {priseStr}
                            </Text>
                          }
                          onClick={() => this.choosePriceToBuy(price)}
                          contentBefore={
                            <IconDollarSign size="xl"/>
                          }
                          contentAfter={(
                            <>
                              {price.duration && (
                                <Text>
                                  {t('purchase.priceDurationDays', {days: price.duration})}
                                </Text>
                              )}
                              <IconChevronRight/>
                            </>
                          )}
                        />
                      );
                    })
                }
              </ListSection>
            </List>
          </Layer>

          {/* TODO detect previous step and show proper button */}
          <ButtonContainer>
            <Button size="lg"
                    variant="ghost"
                    loading={this.state.loading}
                    onClick={() => this.selectAnotherPlan()}>
              {t('purchase.btn.selectAnotherPlan')}
            </Button>
          </ButtonContainer>
        </>
      );
    } else if (this.state.uiState === "choosePaymentMethod") {
      if (!this.userPaymentProfiles || !this.userPaymentProfiles.paymentProfiles || this.userPaymentProfiles.paymentProfiles.length === 0) {
        ui = (
          <Layer>
            <List>
              <SkeletonListItem animated/>
              <SkeletonListItem animated/>
            </List>
          </Layer>
        );
      } else {
        ui = (
          <>
            <div className="HeadContainer">
              <HeadInfo icon="calendar"
                        header={this.servicePlanToBuy?.name || this.servicePlanToBuy?.desc}
                        subheader={this.selectedLocation?.name || t("subscription.unknownLocation")}
                        subIcon={true}
                        loading={false}/>
            </div>
            <Text variant="lead">
              {t('purchase.selectPaymentMethod')}
            </Text>
            <Layer elevation="xs"
                   className="ListLayer">
              <List>
                <ListSection title={t("purchase.paymentMethods")}>
                  {
                    this.userPaymentProfiles!.paymentProfiles!.map(profile => (
                      <ListItem
                        className="ListItem"
                        key={profile.id}
                        primary={
                          <Text variant="lead">
                            {`${profile.firstName} ${profile.lastName}`}
                          </Text>
                        }
                        secondary={
                          <div className="Column">
                            <Text muted={true}>{profile.maskedCardNumber
                              ?.replace(/[Xx×🗙⨯⨉✖✕☓╳]/g, "*")
                              .replace(/[_\-‐‑‒—―]/g, " ")}</Text>
                            <Text variant="subtitle">{`${profile.expirationMonth}/${profile.expirationYear}`}</Text>
                          </div>
                        }
                        onClick={() => this.choosePaymentProfile(profile)}
                        contentBefore={
                          <IconCreditCard size="xl"/>
                        }
                        contentAfter={
                          <div className="Column RightAlign">
                            <Text variant="subtitle">{profile.billingAddress} {profile.billingAddress2}</Text>
                            <Text variant="subtitle">{profile.billingCity}, {profile.billingState}</Text>
                            <Text variant="subtitle">{profile.billingZip}, {profile.billingCountry}</Text>
                          </div>
                        }
                      />
                    ))
                  }
                </ListSection>
              </List>
            </Layer>

            <ButtonContainer>
              <Button size="lg"
                      component="button"
                      intent="primary"
                      onClick={() => this.gotoNewPaymentMethod()}>
                {t('purchase.btn.newPaymentMethod')}
              </Button>
              <Button size="lg"
                      variant="ghost"
                      loading={this.state.loading}
                      onClick={() => this.selectAnotherPrice()}>
                {t('purchase.btn.selectAnotherPrice')}
              </Button>
            </ButtonContainer>
          </>
        );
      }

    } else if (this.state.uiState === "newPaymentMethod") {
      ui = (
        <ChargifyForm onTokenReceived={(token) => this.onChargifyTokenReady(token)}
                      onBack={() => this.goBack()}
                      chargifySite={this.servicePlanPriceToBuy!.site!}/>
      );
    } else if (this.state.uiState === 'purchaseConfirmation') {
      // TODO
      ui = (
        <>
          {/*<ButtonContainer>*/}
          {/*  <Button size="lg"*/}
          {/*          component="button"*/}
          {/*          intent="primary"*/}
          {/*          onClick={() => this.confirmPurchase()}>*/}
          {/*    {t('purchase.btn.confirmPurchase')}*/}
          {/*  </Button>*/}
          {/*  <Button size="lg"*/}
          {/*          variant="ghost"*/}
          {/*          loading={this.state.loading}*/}
          {/*          onClick={() => this.selectAnotherPrice()}>*/}
          {/*    {t('purchase.btn.selectAnotherPrice')}*/}
          {/*  </Button>*/}
          {/*</ButtonContainer>*/}
        </>
      );
    } else if (this.state.uiState === "purchaseSuccess") {
      ui = (
        <>
          <Alert intent="success"
                 title={t('purchase.success.header')}
                 subtitle={t('purchase.success.desc')}
                 elevation="xs"/>
          <ButtonContainer>
            <Button size="lg"
                    component="button"
                    intent="primary"
                    onClick={() => this.goToAccountPage()}>
              {t('purchase.btn.goToAccount')}
            </Button>
          </ButtonContainer>
        </>
      );
    } else if (this.state.uiState === "error") {
      ui = (
        <>
          <Alert intent="danger"
                 title={this.state.error?.title}
                 subtitle={this.state.error?.desc}
                 elevation="xs"/>
          <ButtonContainer>
            <Button size="lg"
                    component="button"
                    intent="primary"
                    onClick={() => this.goToAccountPage()}>
              {t('purchase.btn.goToAccount')}
            </Button>
            <Button size="lg"
                    variant="ghost"
                    onClick={() => this.reloadPage()}>
              {t('purchase.btn.startOver')}
            </Button>
          </ButtonContainer>
        </>
      );
    }

    return ui;
  }
}

interface PurchaseOrUpgradeSubscriptionProps extends WithTranslation, RouteComponentProps {

}

interface PurchaseOrUpgradeSubscriptionState {
  loading: boolean;

  uiState: 'initial' | 'chooseLocation' | 'chooseSubscriptionOrPlan' | 'choosePrice' | 'choosePaymentMethod' | 'newPaymentMethod' | 'purchaseConfirmation' | 'purchaseSuccess' | 'locationAccessDenied' | 'error';

  error?: {
    title: string,
    desc: string
  }
}

export default withTranslation()(withRouter(PurchaseOrUpgradeSubscription));
