import React, { Component } from "react";
import "./Markdown.scss";
import MarkdownIt from "markdown-it";

class Markdown extends Component<MarkdownProps> {

  private readonly md: MarkdownIt;

  constructor(props: MarkdownProps) {
    super(props);

    this.md = MarkdownIt({
      html: false,        // Enable HTML tags in source
      xhtmlOut: false,        // Use '/' to close single tags (<br />).
                              // This is only for full CommonMark compatibility.
      breaks: true,        // Convert '\n' in paragraphs into <br>
      linkify: false,        // Autoconvert URL-like text to links
    });
  }

  render() {
    if (!this.props.src) {
      return null;
    }
    try {
      let html = this.md.render(this.props.src);
      return (
        <div dangerouslySetInnerHTML={{__html: html}}/>
      );
    } catch (e) {
      console.warn("Unable to render markdown", e);
      return null;
    }
  }
}

interface MarkdownProps {
  src?: string;
}

export default Markdown;
