import UserService from "../../services/userService";
import React, { Component } from "react";
import { UserInformation } from "@ppc/webcore/dist/data/services/userService";
import { Text, Skeleton } from "sancho";
import UserAvatar from "../UserAvatar/UserAvatar";

class UserInfo extends Component<UserInfoProps, UserInfoState> {

  constructor(props: UserInfoProps) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount(): void {
    this.init();
  }

  componentWillUnmount(): void {
  }

  private init() {
    return Promise.all([
      this.initUser(),
      this.initAvatar()
    ])
      .then(() => {
        this.setState({
          loading: false
        });
      });
  }

  private initAvatar(): Promise<any> {
    return UserService.getUserAvatarUrl()
      .then((url) => {
        if (url) {
          this.setState({
            avatarUrl: url
          });
        }
      });
  }

  private initUser(): Promise<any> {
    return UserService.getCurrentUser()
      .then((userInfo: UserInformation) => {
        this.setState({
          userInfo: userInfo
        });
      });
  }

  render() {
    const {userInfo} = this.state;

    return (
      <>
        <div className="UserInfoContainer">
          <UserAvatar className="AvatarContainer" size="lg"/>
          
          <div className="TextContainer">
            {!!this.state.loading ? (
              <>
                <div style={{width: "100px", maxWidth: "80%"}}>
                  <Skeleton animated/>
                </div>
                <div style={{width: "150px", maxWidth: "100%"}}>
                  <Skeleton animated/>
                </div>
              </>
            ) : (
              <>
                <Text variant="h3">
                  {this.props.header || `${userInfo?.user.firstName} ${userInfo?.user.lastName}`}
                </Text>

                <Text variant="h5">
                  {
                    this.props.subheader
                    || userInfo?.user.email?.email
                    || userInfo?.user.userName
                    || userInfo?.user.altUsername
                  }
                </Text>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

interface UserInfoProps {
  header?: string;
  subheader?: string;
}

interface UserInfoState {
  avatarUrl?: string;
  userInfo?: UserInformation;
  loading: boolean;
}

export default UserInfo;
