import Logo from "../../Logo/Logo";
import React, { Component } from "react";
import { Text } from "sancho";

/**
 * Branded page header
 * Note: Sets its height from its content, sets its width as 100% of the container
 */
class PageHeader extends Component<PageHeaderProps> {
  render() {
    let {withLogo, lightLogo, logoLink, title} = this.props;

    if (!withLogo && !title) {
      return null;
    }

    return (
      <div className="PageHeader">

        {!!withLogo && (
          <div className="LogoContainer">
            <Logo lightLogo={lightLogo} linked={!!logoLink} link={logoLink}/>
          </div>
        )}

        {!!title && (
          <div className="HeaderContainer">
            <Text variant="lead">{title}</Text>
          </div>
        )}

      </div>
    );
  }
}

interface PageHeaderProps {
  /**
   * Set true to show the logo
   */
  withLogo?: boolean;

  /**
   * Set true to use light version of the logo
   */
  lightLogo?: boolean;

  /**
   * Set custom href link for the logo. Set to falsy-value to remove href link from the logo
   */
  logoLink?: string;

  /**
   * Header text
   */
  title?: string;
}

export default PageHeader;
