import * as React from "react";
import { ListItem, Skeleton } from "sancho";
import './SkeletonListItem.scss'

class SkeletonListItem extends React.Component<SkeletonListItemProps, SkeletonListItemState> {
  render() {
    return (
      <ListItem interactive={false}
                aria-live="polite"
                aria-busy="true"
                className="SkeletonListItem"
                contentBefore={
                  <div className="Avatar">
                    <Skeleton/>
                  </div>
                }
                primary={
                  <div className="FirstLine">
                    <Skeleton animated={this.props.animated}/>
                  </div>
                }
                secondary={
                  <div className="SecondLine">
                    <Skeleton animated={this.props.animated}/>
                  </div>
                }
      />
    );
  }
}

interface SkeletonListItemProps {
  animated?: boolean;
}

interface SkeletonListItemState {

}

export default SkeletonListItem;
