import React from 'react';
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import { WithTranslation, withTranslation } from "react-i18next";
import AuthService from "../../services/authService";
import UserService from "../../services/userService";
import userService from "../../services/userService";
import { UserInformation } from "@ppc/webcore/dist/data/services/userService";
import { NotificationType } from "@ppc/webcore/dist/data/api/app/userCommunications/getNotificationSubscriptionsApiResponse"
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from "react-router";
import QueryService from "../../services/queryService";
import { LoginUrlParams } from "../Login/LoginPage";
import { Alert, Button, Dialog, IconCheckCircle, IconXCircle, InputGroup, Layer, Link, List, ListItem, Select, Text } from "sancho";
import Utils from "../../services/utils";
import { NotificationSubscription } from "../../env/models/NotificationSubscription";
import { CommonUrlParams } from "../../env/models/CommonUrlParams";
import CenterPageContent from "../../components/structural/CenterPageContent/CenterPageContent";
import ButtonContainer from "../../components/structural/ButtonContainer/ButtonContainer";
import UserInfo from "../../components/UserInfo/UserInfo";
import theme from "../../theme";
import { toastService } from "../../services/toastService";

const UNSUBSCRIBE_TYPE = 0;
let USER_SETTINGS_BACKUP: Array<NotificationConfig> = [];
let LOCATION_SETTINGS_BACKUP: LocationNotificationsConfig = {};

class NotificationSubscriptionsPage extends React.Component<NotificationSubscriptionsPageProps, NotificationSubscriptionsPageState> {

  private userInfo?: UserInformation;
  private unsubscribeCategories: Array<string>;
  private readonly urlParams: NotificationSubscriptionsPageUrlParams;
  private readonly userNotifications: Array<NotificationConfig>;
  private readonly locationNotifications: Array<NotificationConfig>;
  private defaultLocationLoaded = false;

  private notificationSettings: {
    user: Array<NotificationConfig>,
    location?: LocationNotificationsConfig
  };

  private notificationSubscriptions?: NotificationSubscription[];

  constructor(props: NotificationSubscriptionsPageProps) {
    super(props);
    const {t} = this.props;

    // Private notification types
    this.userNotifications = [
      {
        type: 1,
        name: t("notifications.form.marketingEmails"),
        email: true
      },
      {
        type: 21,
        name: t("notifications.form.communityEvents"),
        push: true
      }
    ];

    // Location-based notitifcation types
    this.locationNotifications = [
      {
        type: 4,
        name: t("notifications.form.botAnalytics"),
        email: true,
        push: true,
        sms: true
      },
      {
        type: 7,
        name: t("notifications.form.deviceAlerts"),
        email: true,
        push: true
      },
      {
        type: 8,
        name: t("notifications.form.appsEmails"),
        email: true,
        push: true
      },
      {
        type: 22,
        name: t("notifications.form.botReports"),
        email: true,
        push: true,
        sms: true
      }
    ];

    this.unsubscribeCategories = [];
    this.notificationSettings = {
      user: this.userNotifications.map(el => Object.assign([], el))
    };

    this.urlParams = QueryService.getQueryParams(true) as NotificationSubscriptionsPageUrlParams;

    this.state = {
      initialLoading: true,
      loading: false,
      openDialog: false,
      locationId: this.urlParams?.locationId ? parseInt(this.urlParams.locationId) : null,
      unsubscribed: false
    };
  }

  componentDidMount(): void {
    this.initUser()
      .then(() => this.initNotificationSubscriptions())
      .then(() => {
        this.setState({
          initialLoading: false,
          loading: false
        });
      });
  }

  private initUser(): Promise<any> {
    if (AuthService.isAuthenticated() && !this.urlParams.tempKey) {
      return UserService.getCurrentUser()
        .then((userInfo: UserInformation) => {
          this.userInfo = userInfo;
          this.userInfo.locations = this.userInfo.locations || [];

          if (this.state.locationId == null || !this.userInfo.locations.some(l => l.id === this.state.locationId)) {
            // if location id is not defined in URL params
            // or if location id is defined in URL params but it not present in user's locations list
            this.setState({
              locationId: this.userInfo.locations.length > 0 ? userInfo.locations[0].id : null
            });
          }
        }, () => {
          this.redirectToLoginPage();
          return Promise.reject();
        });
    } else {
      this.redirectToLoginPage();
      return Promise.reject();
    }
  }

  private redirectToLoginPage() {
    let {location, history} = this.props;
    let redirectTo = location.pathname + QueryService.encodeQueryParams({
      ...this.urlParams,
      k: undefined,
      tempKey: undefined
    });
    let params: LoginUrlParams = {
      redirectTo: redirectTo,
      tempKey: this.urlParams.tempKey || undefined,
      user: this.urlParams.user || undefined
    };
    history.push(`/login${QueryService.encodeQueryParams(params)}`);
  }

  private initNotificationSubscriptions(): Promise<any> {
    return userService.getNotificationSubscriptions()
      .then((data) => {
        this.notificationSubscriptions = data?.subscriptions || [];
        this.processNotificationSubscriptions();
      })
      .catch((err) => {
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState({
          error: {
            title: errTitle,
            desc: errDesc
          }
        });
      })
  }

  private processNotificationSubscriptions() {
    let defaultSettings = Object.assign([], this.locationNotifications);

    if (!this.notificationSubscriptions) {
      // Set defaults for all locations
      this.userInfo?.locations.forEach(loc => {
        this.notificationSettings.location ? this.notificationSettings.location[loc.id] = defaultSettings : this.notificationSettings.location = {
          [loc.id]: defaultSettings
        };
      });
      return;
    }

    let setCurrentValue = (el: NotificationConfig, locationId?: number) => {
      let communications = ['email', 'push', 'sms'];
      let type = this.notificationSubscriptions?.find(s => s.type === el.type && (!!locationId ? s.locationId === locationId : true));
      communications.forEach(c => {
        // @ts-ignore
        if (el[c]) {
          // @ts-ignore
          el[c] = type[c];
        }
      });
      return el;
    };

    // Detect unsubscribe from all settings type
    let unsubscribeFromAll = this.notificationSubscriptions.find(el => el.type === UNSUBSCRIBE_TYPE);
    if (unsubscribeFromAll && (!unsubscribeFromAll.email || !unsubscribeFromAll.push || !unsubscribeFromAll.sms)) {
      this.setState({
        unsubscribed: true
      });
      !unsubscribeFromAll.email && this.unsubscribeCategories.push('email');
      !unsubscribeFromAll.push && this.unsubscribeCategories.push('push');
      !unsubscribeFromAll.sms && this.unsubscribeCategories.push('sms');
    }

    this.notificationSettings = {
      user: this.userNotifications.map(el => {
        let value = Object.assign({}, el);
        return setCurrentValue(value);
      })
    };

    // Backup user settings
    USER_SETTINGS_BACKUP = this.notificationSettings.user.map(el => Object.assign({}, el));

    this.userInfo?.locations.forEach(loc => {
      let currentSettings = this.locationNotifications.map(el => {
        let value = Object.assign({}, el);
        return setCurrentValue(value, loc.id);
      });
      this.notificationSettings.location ? this.notificationSettings.location[loc.id] = currentSettings || defaultSettings : this.notificationSettings.location = {
        [loc.id]: currentSettings || defaultSettings
      };

      // Backup location settings
      LOCATION_SETTINGS_BACKUP[loc.id] = Object.assign([], JSON.parse(JSON.stringify(currentSettings || defaultSettings)));
    });

    this.forceUpdate();
  }

  private selectLocation(event: React.ChangeEvent<HTMLSelectElement>) {
    let selectedIndex = event.target.options.selectedIndex;
    let locationId = event.target.options[selectedIndex].getAttribute('data-key') || '';
    this.setState({
      locationId: parseInt(locationId)
    });
  }

  private defaultLocation() {
    if (this.defaultLocationLoaded || this.state.loading || this.state.initialLoading) {
      return;
    }
    let locationParam = parseInt(this.urlParams?.locationId || '');
    if (locationParam && this.userInfo?.locations.length) {
      let result = this.userInfo.locations.find(el => el.id === locationParam);
      if (result) {
        this.defaultLocationLoaded = true;
        return result.name;
      }
    }
  }

  private isLocationNotificaitonEnabled(index: number, protocol: string) {
    let selectedLocation = this.state.locationId;
    if (this.notificationSettings.location && selectedLocation) {
      let selectedSettings = this.notificationSettings.location[selectedLocation];
      // @ts-ignore
      return selectedSettings[index] && selectedSettings[index][protocol];
    }
    return false;
  }

  private updateLocationNotificationStatus(index: number, protocol: string) {
    let selectedLocation = this.state.locationId;
    if (this.notificationSettings.location && selectedLocation && this.notificationSettings.location[selectedLocation]) {
      let selectedSettings = this.notificationSettings.location[selectedLocation];
      // @ts-ignore
      selectedSettings[index][protocol] = !selectedSettings[index][protocol];
      this.forceUpdate();
    }
  }

  private handleCancelButtonClick() {
    this.props.history.goBack();
  }

  private handleUnsubscribeButtonClick() {
    this.setState({
      openDialog: false,
      loading: true,
      error: undefined
    });

    userService.setNotificationSubscriptions(0, {
      email: false,
      push: false,
      sms: false
    })
      .then(() => this.initNotificationSubscriptions())
      .then(() => {
        this.setState({
          loading: false,
          error: undefined
        });
        toastService.show({
          intent: "success",
          title: this.props.t("notifications.success")
        });
      })
      .catch((err) => {
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState({
          loading: false,
          error: {
            title: errTitle,
            desc: errDesc
          }
        });
      });
  }

  private returnChangedSettings() {
    // console.log(this.notificationSettings);
    // console.log(USER_SETTINGS_BACKUP);
    // console.log(LOCATION_SETTINGS_BACKUP);

    let result: Array<NotificationConfig> = [];

    let compareElements = (oldValues: Array<NotificationConfig>, newValues: Array<NotificationConfig>, locationId?: number) => {
      newValues.forEach(el => {
        let backupElement = oldValues.find(val => val.type === el.type);
        if (JSON.stringify(el) !== JSON.stringify(backupElement)) {
          if (locationId) {
            el.locationId = locationId;
          }
          result.push(el);
        }
      });
    };

    // Test user settings
    compareElements(USER_SETTINGS_BACKUP || [], this.notificationSettings.user || []);

    // Test location settings
    this.userInfo?.locations.forEach(loc => {
      let backupElement = LOCATION_SETTINGS_BACKUP[loc.id] || [];
      let el = this.notificationSettings.location ? this.notificationSettings.location[loc.id] : [];
      compareElements(backupElement, el, loc.id);
    });

    return result;
  }

  private handleSubmit() {
    if (this.state.loading || this.state.initialLoading) {
      return;
    }

    this.setState({
      loading: true,
      error: undefined
    });
    let promises: Promise<any>[] = [];

    // Reset unsubscribe from all
    promises.push(userService.setNotificationSubscriptions(UNSUBSCRIBE_TYPE, {
      email: true,
      push: true,
      sms: true
    }));

    // Get only exact changes
    let changes = this.returnChangedSettings();
    changes.forEach(el => promises.push(userService.setNotificationSubscriptions(el.type, {
      locationId: el.locationId || void 0,
      email: el.email || false,
      push: el.push || false,
      sms: el.sms || false
    })));

    Promise.all(promises)
      .then(() => this.initNotificationSubscriptions())
      .then(() => {
        this.setState({
          unsubscribed: false,
          loading: false,
          error: undefined
        });
        toastService.show({
          intent: "success",
          title: this.props.t("notifications.success")
        });
      })
      .catch((err) => {
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState({
          loading: false,
          error: {
            title: errTitle,
            desc: errDesc
          }
        });
      });
  }

  render() {
    const {loading, initialLoading, openDialog, unsubscribed} = this.state;
    const {t} = this.props;

    if (initialLoading || !this.userInfo) {
      // todo skeleton
      return null;
    }

    let error;
    if (this.state.error) {
      error = (
        <Alert
          style={{marginTop: theme.spaces.md}}
          elevation="xs"
          intent="danger"
          title={t(this.state.error.title)}
          subtitle={t(this.state.error.desc)}/>
      );
    }

    let categories = "";
    if (unsubscribed) {
      if (this.unsubscribeCategories && this.unsubscribeCategories.length) {
        this.unsubscribeCategories.forEach((el, i) => {
          categories += t("notifications.transport." + el);
          categories += this.unsubscribeCategories.length > i + 1 ? ", " : "";
        });
      }
    }

    return (
      <BrandedPageShell>
        <CenterPageContent>
          <div className="NotificationSubscriptionsPage">
            <UserInfo/>
            <Text variant="uppercase"
                  style={{marginTop: theme.spaces.lg}}>
              {t("notifications.personalSettings")}
            </Text>

            <Layer className="NotificationsList"
                   elevation="xs">
              <List>
                {this.userNotifications.map((notification, index) => (
                  <ListItem
                    key={notification.type}
                    interactive={false}
                    className="ListItem"
                    primary={
                      <Text variant="lead">
                        {notification.name}
                      </Text>
                    }
                    contentAfter={(
                      <>
                        {!!notification.email && (
                          <Button className="IconOnly"
                                  variant="outline"
                                  disabled={loading || initialLoading}
                                  intent={this.notificationSettings.user[index].email ? "none" : "danger"}
                                  onClick={() => {
                                    this.notificationSettings.user[index].email = !this.notificationSettings.user[index].email;
                                    this.forceUpdate();
                                  }}>
                            {t("notifications.transport.email")}
                            {this.notificationSettings.user[index].email ? (
                              <IconCheckCircle size="sm" className="CheckIcon"/>
                            ) : (
                              <IconXCircle size="sm" className="CheckIcon Disabled"/>
                            )}
                          </Button>
                        )}
                        {!!notification.push && (
                          <Button className="IconOnly"
                                  variant="outline"
                                  disabled={loading || initialLoading}
                                  intent={this.notificationSettings.user[index].push ? "none" : "danger"}
                                  onClick={() => {
                                    this.notificationSettings.user[index].push = !this.notificationSettings.user[index].push;
                                    this.forceUpdate();
                                  }}>
                            {t("notifications.transport.push")}
                            {this.notificationSettings.user[index].push ? (
                              <IconCheckCircle size="sm" className="CheckIcon"/>
                            ) : (
                              <IconXCircle size="sm" className="CheckIcon Disabled"/>
                            )}
                          </Button>
                        )}
                        {!!notification.sms && (
                          <Button className="IconOnly"
                                  variant="outline"
                                  disabled={loading || initialLoading}>
                            {t("notifications.transport.sms")}
                          </Button>
                        )}
                      </>
                    )}/>
                ))}
              </List>
            </Layer>

            {unsubscribed ? (
              <Alert title={t("notifications.unsubscribe.unsubscribedTitle", {categories: categories})}
                     subtitle={t("notifications.unsubscribe.unsubscribedDesc")}
                     intent="warning"
                     style={{marginTop: theme.spaces.lg}}/>
            ) : (
              <div className="UnsubscribeLink">
                <Text>
                  <Link onClick={() => this.setState({openDialog: true})}>
                    {t("notifications.btn.unsubscribeFromAll")}
                  </Link>
                </Text>
              </div>
            )}

            <Text variant="uppercase"
                  style={{marginTop: theme.spaces.lg}}>
              {t("notifications.locationSettings")}
            </Text>

            {/* Show only if more than 1 locaiton */}
            {!!this.userInfo.locations && this.userInfo.locations.length > 1 && (
              <InputGroup label=""
                          hideLabel={true}
                          helpText={t("notifications.locationSettingsDesc")}>
                <Select inputSize="lg"
                        onChange={(event) => this.selectLocation(event)}
                        defaultValue={this.defaultLocation()}>
                  {this.userInfo.locations.map(location => {
                    return (
                      <option key={location.id}
                              data-key={location.id}>
                        {location.name || t("subscription.unknownLocation")}
                      </option>
                    );
                  })}
                </Select>
              </InputGroup>
            )}

            <Layer className="NotificationsList LocationNotifications"
                   elevation="xs">
              <List>
                {this.locationNotifications.map((notification, index) => (
                  <ListItem
                    key={notification.type}
                    interactive={false}
                    className="ListItem"
                    primary={
                      <Text variant="lead">
                        {notification.name}
                      </Text>
                    }
                    contentAfter={(
                      <div className="ButtonGroup">
                        {!!notification.email && (
                          <Button className="IconOnly"
                                  variant="outline"
                                  disabled={loading || initialLoading}
                                  intent={this.isLocationNotificaitonEnabled(index, 'email') ? "none" : "danger"}
                                  onClick={() => this.updateLocationNotificationStatus(index, 'email')}>
                            {t("notifications.transport.email")}
                            {this.isLocationNotificaitonEnabled(index, 'email') ? (
                              <IconCheckCircle size="sm" className="CheckIcon"/>
                            ) : (
                              <IconXCircle size="sm" className="CheckIcon Disabled"/>
                            )}
                          </Button>
                        )}
                        {!!notification.push && (
                          <Button className="IconOnly"
                                  variant="outline"
                                  disabled={loading || initialLoading}
                                  intent={this.isLocationNotificaitonEnabled(index, 'push') ? "none" : "danger"}
                                  onClick={() => this.updateLocationNotificationStatus(index, 'push')}>
                            {t("notifications.transport.push")}
                            {this.isLocationNotificaitonEnabled(index, 'push') ? (
                              <IconCheckCircle size="sm" className="CheckIcon"/>
                            ) : (
                              <IconXCircle size="sm" className="CheckIcon Disabled"/>
                            )}
                          </Button>
                        )}
                        {!!notification.sms && (
                          <Button className="IconOnly"
                                  variant="outline"
                                  disabled={loading || initialLoading}
                                  intent={this.isLocationNotificaitonEnabled(index, 'sms') ? "none" : "danger"}
                                  onClick={() => this.updateLocationNotificationStatus(index, 'sms')}>
                            {t("notifications.transport.sms")}
                            {this.isLocationNotificaitonEnabled(index, 'sms') ? (
                              <IconCheckCircle size="sm" className="CheckIcon"/>
                            ) : (
                              <IconXCircle size="sm" className="CheckIcon Disabled"/>
                            )}
                          </Button>
                        )}
                      </div>
                    )}/>
                ))}
              </List>
            </Layer>

            <ButtonContainer>
              <Button size="lg"
                      component="button"
                      onClick={() => this.handleSubmit()}
                      intent="primary"
                      loading={loading || initialLoading}>
                {unsubscribed ?
                  t("notifications.btn.subscribeConfirm") :
                  t("notifications.btn.submit")
                }
              </Button>
              <Button size="lg"
                      variant="ghost"
                      disabled={loading}
                      onClick={() => this.handleCancelButtonClick()}>
                {t("notifications.btn.cancel")}
              </Button>
            </ButtonContainer>

            {error}

          </div>

          <Dialog isOpen={openDialog}
                  onRequestClose={() => this.setState({openDialog: false})}
                  title={t("notifications.unsubscribe.title")}>
            <div className="DialogContent">
              <Text variant="paragraph">
                {t("notifications.unsubscribe.desc")}
              </Text>
              <ButtonContainer>
                <Button size="md"
                        component="button"
                        intent="danger"
                        disabled={loading || initialLoading}
                        onClick={() => this.handleUnsubscribeButtonClick()}>
                  {t("notifications.btn.unsubscribeConfirm")}
                </Button>
                <Button size="md"
                        className="IconOnly"
                        variant="ghost"
                        onClick={() => this.setState({openDialog: false})}>
                  {t("notifications.btn.cancel")}
                </Button>
              </ButtonContainer>
            </div>
          </Dialog>

        </CenterPageContent>
      </BrandedPageShell>
    );
  }
}

type LocationNotificationsConfig = {
  [key: number]: NotificationConfig[];
}

type NotificationConfig = {
  type: NotificationType;
  name: string;
  email?: boolean;
  push?: boolean;
  sms?: boolean;
  locationId?: number;
}

interface NotificationSubscriptionsPageProps extends WithTranslation, RouteComponentProps {

}

interface NotificationSubscriptionsPageState {
  initialLoading: boolean;
  loading: boolean;
  error?: {
    title: string,
    desc: string
  };
  openDialog: boolean;
  locationId: number | null;
  unsubscribed: boolean;
}

interface NotificationSubscriptionsPageUrlParams extends CommonUrlParams {
  /**
   * Username for login
   */
  user?: string;

  /**
   * Location ID
   */
  locationId?: string;
}

export default withTranslation()(withRouter(NotificationSubscriptionsPage));
