import React from 'react';
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import { WithTranslation, withTranslation } from "react-i18next";
import AuthService from "../../services/authService";
import UserService from "../../services/userService";
import { UserInformation } from "@ppc/webcore/dist/data/services/userService";
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from "react-router";
import QueryService from "../../services/queryService";
import { LoginUrlParams } from "../Login/LoginPage";
import { Button, IconButton, IconLogOut } from "sancho";
import SubscriptionList from "../../components/SubscriptionList/SubscriptionList";
import CenterPageContent from "../../components/structural/CenterPageContent/CenterPageContent";
import { CommonUrlParams } from "../../env/models/CommonUrlParams";
import ButtonContainer from "../../components/structural/ButtonContainer/ButtonContainer";
import UserInfo from "../../components/UserInfo/UserInfo";

class AccountPage extends React.Component<AccountPageProps, AccountPageState> {
  private readonly urlParams: AccountPageUrlParams;

  constructor(props: AccountPageProps) {
    super(props);

    this.urlParams = QueryService.getQueryParams(true) as AccountPageUrlParams;

    this.state = {
      loading: true
    };
  }

  componentDidMount(): void {
    this.initUser()
      .then(() => {
        this.setState({
          loading: false
        });
      });
  }

  private initUser(): Promise<any> {
    if (AuthService.isAuthenticated() && !this.urlParams.tempKey) {
      return UserService.getCurrentUser()
        .then((userInfo: UserInformation) => {
          this.setState({
            userInfo: userInfo
          });
        }, (error) => {
          this.redirectToLoginPage();
          return Promise.reject();
        });
    } else {
      this.redirectToLoginPage();
      return Promise.reject();
    }
  }

  private redirectToLoginPage() {
    let {location, history} = this.props;
    let redirectTo = location.pathname + QueryService.encodeQueryParams({
      ...this.urlParams,
      k: undefined,
      tempKey: undefined
    });
    let params: LoginUrlParams = {
      redirectTo: redirectTo,
      tempKey: this.urlParams.tempKey || undefined,
      user: this.urlParams.user || undefined
    };
    history.push(`/login${QueryService.encodeQueryParams(params)}`);
  }

  private logoutAndGotoLogin() {
    AuthService.logout()
      .then(() => {
        this.props.history.push(`/login`);
      });
  }

  private gotoNotificationSubscriptions() {
    let {history} = this.props;
    history.push(`/notifications`);
  }

  private gotoEditUser() {
    let {history} = this.props;
    history.push(`/editaccount`);
  }

  render() {
    const {userInfo} = this.state;
    const {t} = this.props;

    if (this.state.loading || !userInfo) {
      return null;
    }

    return (
      <BrandedPageShell>
        <CenterPageContent>
          <div className="AccountPage">

            <UserInfo/>

            <ButtonContainer>
              <IconButton size="lg"
                          className="DisplayOnly IconOnly"
                          variant="ghost"
                          label={t('account.btn.signOut')}
                          icon={<IconLogOut/>}
                          onClick={() => this.logoutAndGotoLogin()}>
              </IconButton>
              <Button size="lg"
                      component="button"
                      intent="primary"
                      onClick={() => this.gotoEditUser()}>
                {t('account.btn.editUser')}
              </Button>
              <Button size="lg"
                      component="button"
                      intent="primary"
                      onClick={() => this.gotoNotificationSubscriptions()}>
                {t('account.btn.notifications')}
              </Button>
              <Button size="lg"
                      className="MobileOnly"
                      component="button"
                      variant="ghost"
                      onClick={() => this.logoutAndGotoLogin()}>
                {t('account.btn.signOut')}
              </Button>
            </ButtonContainer>

            <SubscriptionList/>

          </div>
        </CenterPageContent>
      </BrandedPageShell>
    );
  }
}

interface AccountPageProps extends WithTranslation, RouteComponentProps {

}

interface AccountPageState {
  loading: boolean;
  userInfo?: UserInformation;
}

interface AccountPageUrlParams extends CommonUrlParams {
  /**
   * Username for login
   */
  user?: string;
}


export default withTranslation()(withRouter(AccountPage));
