import React from "react";
import BrandedPageShell from "../structural/BrandedPageShell/BrandedPageShell";
import "./FullScreenLoader.scss";
import { Spinner } from "sancho";

/**
 * Loading page with branded logo
 */
function FullScreenLoader() {
  return (
    <BrandedPageShell withLogo logoLink="" title="Loading...">
      <Spinner center delay={100} size="xl"/>
    </BrandedPageShell>
  );
}

export default FullScreenLoader;
