import WebCore from './webcoreService';
import { CommonUrlParams } from '../env/models/CommonUrlParams';

class QueryService {

  /**
   * Parse current URL query string and convert into hash object
   * @param {boolean} [parseTempKey] Optionally rewrite API key parameter
   * @return {ParsedQueryString}
   */
  getQueryParams(parseTempKey?: boolean): CommonUrlParams {
    let result = WebCore.services.query.getQueryParams() as CommonUrlParams;
    if (parseTempKey && result && result.k) {
      result.tempKey = result.k;
    }
    return result;
  }

  /**
   * Encode object into query params string. With ? sign
   * @param params
   * @return {string}
   */
  encodeQueryParams(params: { [paramName: string]: string | number | boolean | Array<string | number | boolean> | undefined }): string {
    return WebCore.services.query.encodeQueryParams(params, {
      addQueryPrefix: true,
      arrayFormat: 'repeat'
    }) as string;
  }

}

export default new QueryService();
