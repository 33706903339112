import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.scss';
// Pages
import HomePage from '../pages/Home/HomePage';
import LoginPage from '../pages/Login/LoginPage';
import VerifyPage from '../pages/Verify/VerifyPage';
// UI Components
import { ThemeProvider } from 'sancho';
import theme from '../theme';
import FullScreenLoader from "./FullScreenLoader/FullScreenLoader";
import PasswordRenewRequestPage from "../pages/PasswordRenewRequest/PasswordRenewRequest";
import PasswordRenewResultPage from "../pages/PasswordRenewResult/PasswordRenewResult";
import OAuthResultPage from "../pages/OAuthResult/OAuthResult";
import OAuthHostPage from "../pages/OAuthHost/OAuthHost";
import CookiesPolicyPage from "../pages/CookiesPolicy/CookiesPolicy";
import TermsOfServicePage from "../pages/TermsOfService/TermsOfService";
import PrivacyPolicyPage from "../pages/PrivacyPolicy/PrivacyPolicy";
import MonitoringServicesPage from "../pages/MonitoringServices/MonitoringServices";
import PurchasePage from "../pages/Purchase/Purchase";
import AccountPage from "../pages/Account/Account";
import EditAccountPage from "../pages/EditAccount/EditAccount";
import EditPasswordPage from "../pages/EditPassword/EditPassword";
import SubscriptionPage from "../pages/Subscription/Subscription";
import NotificationSubscriptionsPage from "../pages/NotificationSubscriptions/NotificationSubscriptions";
import StoryPage from "../pages/StoryPage/StoryPage";
import { ToastProvider } from "./ToastProvider/ToastProvider";

const App = () => {
  // NOTE: Suspense is barely get to release state, so it is not supported yet by anything
  //   so don't be surprised if you will not see FullScreenLoader fallback
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<FullScreenLoader/>}>
        <Switch>
          <Route exact path="/" component={HomePage}/>
          <Route path="/login" component={LoginPage}/>
          <Route path="/password" component={PasswordRenewRequestPage}/>
          <Route path="/results/password" component={PasswordRenewResultPage}/>
          <Route path="/results/oauth" component={OAuthResultPage}/>
          <Route path="/oauth" component={OAuthHostPage}/>
          <Route path="/results/verify" component={VerifyPage}/>
          <Route path="/terms" component={TermsOfServicePage}/>
          <Route path="/privacy" component={PrivacyPolicyPage}/>
          <Route path="/monitoring" component={MonitoringServicesPage}/>
          <Route path="/cookies" component={CookiesPolicyPage}/>
          <Route path="/purchase" component={PurchasePage}/>
          <Route path="/account" component={AccountPage}/>
          <Route path="/editaccount" component={EditAccountPage}/>
          <Route path="/editpwd" component={EditPasswordPage}/>
          <Route path="/subscription" component={SubscriptionPage}/>
          <Route path="/notifications" component={NotificationSubscriptionsPage}/>
          <Route path="/story" component={StoryPage}/>
          <Route exact path="*" component={HomePage}/>
        </Switch>
      </Suspense>
      <ToastProvider/>
    </ThemeProvider>
  );
};

export default App;
