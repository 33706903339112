import React, { Component } from "react";
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import { WithTranslation, withTranslation } from "react-i18next";
import QueryService from "../../services/queryService";
import { CommonUrlParams } from "../../env/models/CommonUrlParams";
import PurchaseOrUpgradeSubscription
  from "../../components/purchase/PurchaseOrUpgradeSubscription/PurchaseOrUpgradeSubscription";
import AuthService from "../../services/authService";
import UserService from "../../services/userService";
import { LoginUrlParams } from "../Login/LoginPage";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import CenterPageContent from "../../components/structural/CenterPageContent/CenterPageContent";

class PurchasePage extends Component<PurchaseProps, PurchaseState> {

  private readonly urlParams: PurchaseUrlParams;

  constructor(props: PurchaseProps) {
    super(props);
    this.state = {
      loading: true
    };

    this.urlParams = QueryService.getQueryParams(true) as PurchaseUrlParams;
  }

  componentDidMount() {
    this.initUser();
  }

  private initUser(): Promise<any> {
    if (AuthService.isAuthenticated() && !this.urlParams.tempKey) {
      return UserService.getCurrentUser()
        .then(() => {
          this.setState({
            loading: false
          });
        })
        .catch((error) => {
          this.redirectToLoginPage();
          return Promise.reject();
        });
    } else {
      this.redirectToLoginPage();
      return Promise.reject();
    }
  }

  private redirectToLoginPage() {
    let {location, history} = this.props;
    let redirectTo = location.pathname + QueryService.encodeQueryParams({
      ...this.urlParams,
      k: undefined,
      tempKey: undefined
    });
    let params: LoginUrlParams = {
      redirectTo: redirectTo,
      tempKey: this.urlParams.tempKey || undefined,
      user: this.urlParams.user || undefined
    };
    history.push(`/login${QueryService.encodeQueryParams(params)}`);
  }

  render() {
    return (
      <BrandedPageShell>
        <CenterPageContent>
          { !this.state.loading && <PurchaseOrUpgradeSubscription/> }
        </CenterPageContent>
      </BrandedPageShell>
    );
  }
}

interface PurchaseProps extends WithTranslation, RouteComponentProps {

}

interface PurchaseState {
  loading: boolean;
}

export interface PurchaseUrlParams extends CommonUrlParams {
  /**
   * Username for login
   */
  user?: string;

  /**
   * Location ID to purchase plan for
   */
  locationId?: string;

  /**
   * Plan id to purchase
   */
  plan?: string;

  /**
   * Plan price id to purchase
   */
  priceId?: string;
}

export default withTranslation()(withRouter(PurchasePage));
