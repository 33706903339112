import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { brandingService } from "../../services/brandingService";
import { mediaQueryService, ScreenSize } from "../../services/mediaQueryService";

class Logo extends Component<LogoProps, LogoState> {
  private mediaQueryServiceUnsubscribe: undefined | (() => void);

  constructor(props: LogoProps) {
    super(props);
    this.handleImgError = this.handleImgError.bind(this);
    this.state = {
      screenSize: mediaQueryService.screenSize
    };
  }

  componentDidMount(): void {
    this.mediaQueryServiceUnsubscribe = mediaQueryService.onChange(screenSize => {
      if (this.state.screenSize !== screenSize) {
        this.setState({screenSize: screenSize});
      }
    });
  }

  componentWillUnmount(): void {
    if (this.mediaQueryServiceUnsubscribe) {
      this.mediaQueryServiceUnsubscribe();
    }
  }

  handleImgError() {
    this.setState(prevState => ({
      ...prevState,
      error: true
    }));
  }

  render() {
    if (this.state.error === true) {
      // NOTE if rendering nothing will case some issues - we always can switch to base64 transparent pixel src
      // src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg=="
      return null;
    }

    let {lightLogo, linked, link} = this.props;
    let logoUrl = lightLogo ? brandingService.brandLogoLightUrl : brandingService.brandLogoUrl;
    let logoSmUrl = lightLogo ? brandingService.brandLogoLightSmUrl : brandingService.brandLogoSmUrl;
    link = link || '/';

    const logoImage = (
      <img className="Image"
           src={this.state.screenSize === "xs" ? logoSmUrl : logoUrl}
           alt={"logo"}
           onError={this.handleImgError}/>
    );

    return (!!linked && !!link) ? (
      <Link className="LogoLink" to={link}>
        {logoImage}
      </Link>
    ) : logoImage;
  }
}

interface LogoProps {
  /**
   * Set true to use light version of the logo
   */
  lightLogo?: boolean;

  /**
   * Set true to make logotype liked
   */
  linked?: boolean

  /**
   * Custom href link of the logo
   */
  link?: string
}

interface LogoState {
  error?: boolean;
  screenSize: ScreenSize;
}

export default Logo;
