import UserService from "../../services/userService";
import React, { Component } from "react";
import { UserInformation } from "@ppc/webcore/dist/data/services/userService";
import { Avatar, AvatarSizes, Skeleton } from "sancho";
import webCoreInstance from "../../services/webcoreService";

const DEFAULT_SIZE: AvatarSizes = "md";

const SIZES = {
  xs: {width: "1.25rem", height: "1.25rem"},
  sm: {width: "2.02rem", height: "2.02rem"},
  md: {width: "3.27rem", height: "3.27rem"},
  lg: {width: "5.29rem", height: "5.29rem"},
  xl: {width: "8.57rem", height: "8.57rem"}
};

class UserAvatar extends Component<UserAvatarProps, UserAvatarState> {
  private cancelSubscription?: () => void;

  constructor(props: UserAvatarProps) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount(): void {
    this.init();

    this.cancelSubscription = webCoreInstance.services.auth.onLogin.on(() => {
      this.init();
    });
  }

  componentWillUnmount(): void {
    this.cancelSubscription && this.cancelSubscription();
  }

  private init() {
    this.setState({
      loading: true
    });
    return Promise.all([
      this.initUser(),
      this.initUserAvatar()
    ])
      .then(() => {
        this.setState({
          loading: false
        });
      });
  }

  private initUserAvatar(): Promise<any> {
    return UserService.getUserAvatarUrl()
      .then((url) => {
        if (url) {
          this.setState({
            avatarUrl: url
          });
        }
      });
  }

  private initUser(): Promise<any> {
    return UserService.getCurrentUser()
      .then((userInfo: UserInformation) => {
        this.setState({
          userName: `${userInfo?.user?.firstName} ${userInfo?.user?.lastName}`
        });
      });
  }

  render() {
    return (
      <>
        {!!this.state.loading ? (
          <>
            <div className={this.props.className}>
              <Skeleton animated css={{
                display: "inline-block",
                width: SIZES[this.props.size || DEFAULT_SIZE].width,
                height: SIZES[this.props.size || DEFAULT_SIZE].height,
                borderRadius: "50%"
              }}/>
            </div>
          </>
        ) : (
          <>
            <div className={this.props.className}>
              <Avatar src={this.state.avatarUrl}
                      name={this.state.userName}
                      size={this.props.size || DEFAULT_SIZE}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

interface UserAvatarProps {
  size?: AvatarSizes;
  className?: string;
}

interface UserAvatarState {
  avatarUrl?: string;
  userName?: string;
  loading: boolean;
}

export default UserAvatar;
