import React, { Component } from 'react';
import { IconDollarSign, Alert, Layer, List, ListSection, ListItem, Text } from "sancho";
import { WithTranslation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import SkeletonListItem from "../SkeletonListItem/SkeletonListItem";
import { subscriptionsService } from "../../services/subscriptionsService";
import { UserSubscriptionTransactions } from "@ppc/webcore/dist/data/services/subscriptionsService";
import { GetSubscriptionTransactionsApiResponse } from "@ppc/webcore/dist/data/api/app/paidServices/getSubscriptionTransactionsApiResponse";
import theme from "../../theme";

class TransactionList extends Component<TransactionListProps, TransactionListState> {

  constructor(props: TransactionListProps) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount(): void {
    this.initTransactions()
      .then(() => {
        this.setState({
          loading: false
        });
      });
  }

  private initTransactions(): Promise<any> {
    return subscriptionsService.getSubscriptionTransactions(this.props.userServicePlanId, this.props.locationId)
      .then((transactions: UserSubscriptionTransactions) => {
        this.setState({
          transactions: transactions
        });
      });
  }

  render() {
    const {t} = this.props;
    const {loading, transactions} = this.state;

    let transactionsToShow: GetSubscriptionTransactionsApiResponse["transactions"] | undefined;
    if (!!transactions && !!transactions.transactions && transactions.transactions.length > 0) {
      transactionsToShow = transactions.transactions
        // .filter((transaction, index, arr) => arr.findIndex(t => (t.transactionId === transaction.transactionId)) === index) // deduplicate. This is an Server bug
        .sort((a, b) => {
          return b.issueDateMs - a.issueDateMs;
        })
    }

    return (
      <>
        {
          !loading && (!transactions || !transactions.transactions || transactions.transactions.length === 0) && (
            <Alert style={{marginTop: theme.spaces.md, marginBottom: theme.spaces.md}}
                   intent="warning" 
                   title={t('subscription.transactions.noTransactions')}
                   subtitle={t('subscription.transactions.noTransDesc')}
                   elevation="xs"/>
          )
        }

        <Layer className="TransactionList"
               elevation="xs"
               style={{
                 display: !loading && !transactionsToShow ? 'none' : 'block'
               }}>
          <List>
            {
              loading && (
                <>
                  <SkeletonListItem animated/>
                  <SkeletonListItem animated/>
                </>
              )
            }

            { !loading && !!transactionsToShow && transactionsToShow.length > 0 && (
                <ListSection title={t('subscription.transactions.header')}>
                  { transactionsToShow.map(transaction => {
                      return (
                        <ListItem 
                          key={transaction.transactionId}
                          interactive={false}
                          className="ListItem"
                          contentBefore={
                            <IconDollarSign size="xl"/>
                          }
                          primary={
                            <Text variant="lead">
                              {t('subscription.transactions.transactionId', {id: transaction.transactionId})}
                            </Text>
                          }
                          secondary={
                            <>
                              <Text className="DisplayOnly" muted={true}>
                                {t('subscription.transactions.charged', {date: new Date(transaction.issueDateMs).toLocaleDateString()})}
                              </Text>
                              <Text className="MobileOnly" muted={true}>
                                {t('subscription.transactions.chargedAmount', {amount: transaction.amount, date: new Date(transaction.issueDateMs).toLocaleDateString()})}
                              </Text>
                            </>
                          }
                          contentAfter={
                            <div className="TransactionAmountBadge">
                              <Text>${transaction.amount}</Text>
                            </div>
                          }
                        />
                      );
                  })}
                </ListSection>
            )}
          </List>
        </Layer>
      </>
    );
  }
}

interface TransactionListProps extends WithTranslation, RouteComponentProps {
  locationId: number;
  userServicePlanId: number;
}

interface TransactionListState {
  error?: string;
  loading: boolean;
  transactions?: UserSubscriptionTransactions;
}

export default withTranslation()(withRouter(TransactionList));
