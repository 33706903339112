import WebCore from './webcoreService';
import { LoginInfo } from "@ppc/webcore/dist/data/services/authService";
import { SendPasscodeApiResponse } from "@ppc/webcore/dist/data/api/app/auth/sendPasscodeApiResponse";
import { ApiResponseBase } from "@ppc/webcore/dist/data/models/apiResponseBase";

const PWD_REGEX_KEY = 'pwdRegExp';

class AuthService {

  getPasswordRegex(): Promise<string> {
    let cache = sessionStorage.getItem(PWD_REGEX_KEY);
    if (cache) {
      return Promise.resolve(cache);
    }
    return WebCore.services.systemProperties.getPasswordValidationRegex()
      .then((response: string) => {
        sessionStorage.setItem(PWD_REGEX_KEY, response);
        return response;
      });
  }

  login(username: string, pwd: string): Promise<any> {
    return WebCore.services.auth.login(username, pwd, false);
  }

  loginByKey(apiKey: string): Promise<any> {
    return WebCore.services.auth.loginByKey(apiKey);
  }

  logout(): Promise<any> {
    return WebCore.services.auth.logoutFromThisBrowser();
  }

  isAuthenticated(): boolean {
    return WebCore.services.auth.isAuthenticated();
  }

  /**
   * Set new password.
   * If 2-factor auth is used - this API will send passcode, so you'll need to call this method again
   * @param [params] Request parameters
   * @param {string} params.newPassword New password
   * @param {string} params.oldPassword Old password
   * @param {string} [params.passcode] 2-factor auth passcode
   * @param {string} [params.brand] A parameter identifying specific email template, among other customization settings
   * @param {string} [params.appName] App name to identify the brand
   * @returns {Promise<ApiResponseBase>}
   */
  public setNewPassword(params: {
    newPassword: string,
    oldPassword: string,
    passcode?: string,
    brand?: string,
    appName?: string
  }): Promise<ApiResponseBase> {
    return WebCore.services.auth.setNewPassword(params.newPassword, params.oldPassword, params.passcode, {
      brand: params.brand || undefined,
      appName: params.appName || undefined
    });
  }

  setNewPasswordByTempKey(newPwd: string, tempKey: string, brand?: string, appName?: string): Promise<any> {
    return WebCore.services.auth.setNewPasswordByTempKey(newPwd, tempKey, {
      brand: brand || undefined,
      appName: appName || undefined
    });
  }

  setNewPasswordByPasscode(newPwd: string, passcode: string, tempKey: string, brand?: string, appName?: string): Promise<any> {
    return WebCore.services.auth.setNewPasswordByTempKey(newPwd, tempKey, {
      brand: brand || undefined,
      appName: appName || undefined,
      passcode: passcode
    });
  }

  recoverPassword(userName: string, brand?: string, appName?: string): Promise<any> {
    return WebCore.services.user.recoverPassword(userName, brand, appName);
  }

  /**
   * Gets temporary API key that is valid for only a brief amount of time.
   * @returns {Promise<LoginInfo>}
   */
  getTempToken(): Promise<LoginInfo> {
    return WebCore.services.auth.getTempToken();
  }

  /**
   * Gets URL which allows a user to approve or deny an authorization request from the third-party application.
   * The user will be redirected to the external application web page.
   * @param {string} approved If the url to approve or deny authorization (true or false).
   * @param params Request parameters.
   * @param {string} params.clientId OAuth Client ID.
   * @param {string} params.responseType OAuth 2 response type. The "code" response type is the only one currently supported.
   * @param {string} params.apiKey Temporary API_KEY.
   * @param {string} [params.brand] Brand name.
   * @param {string} [params.state] The client's state which will be returned in the callback URL.
   * @returns {Promise<string>}
   */
  getUrlToApproveOrDenyAuthorization(approved: boolean, params: {
    clientId: string,
    responseType: string,
    apiKey: string
    state?: string,
    locationId?: number,
    brand?: string,
  }): Promise<string> {
    return WebCore.services.auth.getUrlToApproveOrDenyAuthorization(approved, params);
  }

  /**
   * Send an SMS verification code to specific user.
   * @param {string} username The username.
   * @param {string} [brand] Optional brand name.
   * @returns {Promise<SendPasscodeApiResponse>}
   */
  sendPasscode(username: string, brand?: string): Promise<SendPasscodeApiResponse> {
    let str = username.replace(/\+/g, '');
    return WebCore.services.auth.sendPasscode(str, false, brand);
  }

  /**
   * Log in into the system by username and passcode
   * @param {string} username The username.
   * @param {string} passcode Passcode.
   * @returns {Promise<LoginInfo>}
   */
  loginByPasscode(username: string, passcode: string): Promise<LoginInfo> {
    let str = username.replace(/\+/g, '');
    return WebCore.services.auth.loginByPasscode(str, passcode, false);
  }

}

export default new AuthService();
