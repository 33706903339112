import { Brand, brands, favicons } from "../brands";

class BrandingService {

  private _currentBrand: Brand;

  constructor() {
    this._currentBrand = this.getCurrentBrand();
    this.applyBrand();
  }

  get currentBrand(): Brand {
    return this._currentBrand;
  }

  get brandLogoUrl(): string {
    return this._currentBrand.logoUrl || `/assets/brands/${this._currentBrand.id}/logo.svg`;
  }

  get brandLogoSmUrl(): string {
    return this._currentBrand.logoSmUrl || this.brandLogoUrl;
  }

  get brandLogoLightUrl(): string {
    return this._currentBrand.logoLightUrl || this.brandLogoUrl;
  }

  get brandLogoLightSmUrl(): string {
    return this._currentBrand.logoLightSmUrl || this.brandLogoLightUrl || this.brandLogoSmUrl;
  }

  get brandName(): string {
    return this._currentBrand.name;
  }

  public reInitBrand() {
    let newBrand = this.getCurrentBrand();
    if (this._currentBrand !== newBrand) {
      this._currentBrand = newBrand;
      this.applyBrand();
    }
  }

  public setBrand(brandId: Brand["id"]) {
    let newBrand = brands.brands.find(b => b.id === brandId);
    if (!newBrand) {
      console.warn(`Unable to find brand with id: ${brandId}`);
      this._currentBrand = this.getCurrentBrand();
      this.applyBrand();
      return;
    }
    this._currentBrand = newBrand;
    this.applyBrand();
  }

  private applyBrand() {
    this.cleanPageHead();
    let publicUrl = document.querySelector('head > meta[name="public-url"]')?.getAttribute('content') || '';
    let brandId = this._currentBrand.id || brands.defaultBrand;
    let themeColor = this._currentBrand.colors.theme || this._currentBrand.colors.primary;
    let description = this._currentBrand.description || this._currentBrand.name;

    // Add favicon links to head
    if (this._currentBrand.favicons) {
      favicons.forEach(item => {
        let link = `<link rel="${item.rel}" href="${publicUrl}/assets/brands/${brandId}/favicon/${item.filename}" ${item.type && 'type="' + item.type + '"'} ${item.sizes && 'sizes="' + item.sizes + '"'} ${item.rel === 'mask-icon' && 'color="' + this._currentBrand.colors.primary + '"'}>`;
        document.querySelector('head')!.innerHTML += link;
      });
      document.querySelector('head')!.innerHTML += `<meta name="msapplication-config" content="${publicUrl}/assets/brands/${brandId}/favicon/browserconfig.xml"/>`;
    }

    // Add meta tags and title
    document.querySelector('head')!.innerHTML += `<meta name="msapplication-TileColor" content="${this._currentBrand.colors.background}"/>`;
    document.querySelector('head')!.innerHTML += `<meta name="theme-color" content="${themeColor}"/>`;
    document.querySelector('head')!.innerHTML += `<meta name="description" content="${description}"/>`;
    document.querySelector('head')!.innerHTML += `<title>${this._currentBrand.name}</title>`;

    document.body.classList
      .remove(...brands.brands.filter(b => b !== this._currentBrand).map(b => b.className));
    document.body.classList
      .add(this._currentBrand.className);
  }

  private cleanPageHead() {
    let querySelectors = [
      'meta[name="msapplication-TileColor"]',
      'meta[name="msapplication-config"]',
      'meta[name="theme-color"]',
      'meta[name="description"]',
      'title'
    ];

    // Cleanup favicon links
    favicons.forEach(item => {
      querySelectors.push('link[rel="' + item.rel + '"]');
    });

    querySelectors.forEach(selector => {
      document.querySelectorAll('head > ' + selector)
        .forEach(e => e.parentNode && e.parentNode.removeChild(e));
    });
  }

  private getCurrentBrand(): Brand {
    let url = new URL(window.location.href);
    let brand = url.searchParams.get("brand");
    if (brand) {
      let currentBrandObj = brands.brands.find(b => b.urlParameter.toLowerCase() === brand!.toLowerCase());
      if (currentBrandObj) {
        return currentBrandObj;
      }
    }

    let currentBrandObj = brands.brands.find(b => b.hostname.toLowerCase() === url.hostname.toLowerCase());
    if (currentBrandObj) {
      return currentBrandObj;
    }

    if (brands.defaultBrand) {
      currentBrandObj = brands.brands.find(b => b.urlParameter.toLowerCase() === brands.defaultBrand);
    } else {
      currentBrandObj = brands.brands[0];
    }
    if (brand) {
      currentBrandObj = JSON.parse(JSON.stringify(currentBrandObj));
      currentBrandObj!.id = brand;
    }
    return currentBrandObj!;
  }
}

export const brandingService = new BrandingService();
