import React, { Component } from "react";
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import { WithTranslation, withTranslation } from "react-i18next";
import QueryService from "../../services/queryService";
import { CommonUrlParams } from "../../env/models/CommonUrlParams";
import Story from "../../components/Story/Story";
import CenterPageContent from "../../components/structural/CenterPageContent/CenterPageContent";

class PrivacyPolicyPage extends Component<PrivacyPolicyPageProps, PrivacyPolicyPageState> {

  private readonly urlParams: PrivacyPolicyPageUrlParams;

  constructor(props: PrivacyPolicyPageProps) {
    super(props);
    this.urlParams = QueryService.getQueryParams() as PrivacyPolicyPageUrlParams;
  }

  componentDidMount() {

  }

  render() {
    const {i18n} = this.props;
    return (
      <BrandedPageShell>
        <CenterPageContent>
          <Story lang={i18n.language} storyType={41}/>
        </CenterPageContent>
      </BrandedPageShell>
    );
  }
}

interface PrivacyPolicyPageProps extends WithTranslation {

}

interface PrivacyPolicyPageState {

}

interface PrivacyPolicyPageUrlParams extends CommonUrlParams {

}

export default withTranslation()(PrivacyPolicyPage);
