import React, { Component } from "react";
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import { WithTranslation, withTranslation } from "react-i18next";
import QueryService from "../../services/queryService";
import { CommonUrlParams } from "../../env/models/CommonUrlParams";
import Story from "../../components/Story/Story";
import CenterPageContent from "../../components/structural/CenterPageContent/CenterPageContent";

class MonitoringServicesPage extends Component<MonitoringServicesPageProps, MonitoringServicesPageState> {

  private readonly urlParams: MonitoringServicesPageUrlParams;

  constructor(props: MonitoringServicesPageProps) {
    super(props);
    this.urlParams = QueryService.getQueryParams() as MonitoringServicesPageUrlParams;
  }

  componentDidMount() {

  }

  render() {
    const {i18n} = this.props;
    return (
      <BrandedPageShell>
        <CenterPageContent>
          <Story lang={i18n.language} storyType={42}/>
        </CenterPageContent>
      </BrandedPageShell>
    );
  }
}

interface MonitoringServicesPageProps extends WithTranslation {

}

interface MonitoringServicesPageState {

}

interface MonitoringServicesPageUrlParams extends CommonUrlParams {

}

export default withTranslation()(MonitoringServicesPage);
