import React, { Component } from 'react';
import { UserInformation } from "@ppc/webcore/dist/data/services/userService";
import { Alert, IconCalendar, IconHome, IconSlash, Layer, List, ListItem, ListSection, Text, IconChevronRight } from "sancho";
import { WithTranslation, withTranslation } from "react-i18next";
import UserService from "../../services/userService";
import { Subscription } from "../../env/models/Subscription";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import QueryService from "../../services/queryService";
import { SubscriptionPageUrlParams } from "../../pages/Subscription/Subscription";
import SkeletonListItem from "../SkeletonListItem/SkeletonListItem";
import { subscriptionsService } from "../../services/subscriptionsService";
import { SubscriptionStatus } from "@ppc/webcore/dist/data/api/app/paidServices/getLocationSubscriptionsApiResponse";
import Utils from "../../services/utils";
import theme from "../../theme";

class SubscriptionList extends Component<SubscriptionListProps, SubscriptionListState> {

  private userInfo?: UserInformation;
  private subscriptions?: Subscription[];

  private activeSubscriptions?: Subscription[];
  private expiredSubscriptions?: Subscription[];

  constructor(props: SubscriptionListProps) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount(): void {
    this.initUser()
      .then(() => this.initSubscriptions())
      .then(() => {
        this.setState({
          loading: false
        });
      });
  }

  private initUser(): Promise<any> {
    return UserService.getCurrentUser()
      .then((userInfo: UserInformation) => {
        this.userInfo = userInfo;
      });
  }

  private initSubscriptions(): Promise<any> {
    if (!this.userInfo?.locations || this.userInfo?.locations.length === 0) {
      return Promise.resolve();
    }
    return Promise.all(
      this.userInfo?.locations
        .filter(location => location.locationAccess && location.locationAccess === 30)
        .map((location) => subscriptionsService.getLocationSubscriptions(location.id))
    )
      .then((subscriptionsArr) => {
        this.subscriptions = subscriptionsArr
          .reduce((acc, subscriptionResult) =>
            acc.concat(subscriptionResult.subscriptions || []), [] as Subscription[]
          )
          // deduplicate
          .filter((subscription, index, arr) =>
            arr.findIndex(s => (s.userPlanId === subscription!.userPlanId)) === index
          )
          .sort((a, b) => b.issueDateMs - a.issueDateMs);

        this.activeSubscriptions = this.subscriptions
          .filter(s => (s.status !== SubscriptionStatus.ExpiredOrCancelled && s.cancelled !== true));

        this.expiredSubscriptions = this.subscriptions
          .filter(s => s.status === SubscriptionStatus.ExpiredOrCancelled || s.cancelled === true);
      })
      .catch(err => {
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState({
          error: {
            title: errTitle,
            desc: errDesc
          }
        });
      });
  }

  private openSubscription(subscription: Subscription) {
    if (!subscription) {
      return;
    }
    let {history} = this.props;
    let params: SubscriptionPageUrlParams = {
      userPlanId: subscription.userPlanId.toString(),
      locationId: subscription.locationId.toString()
    };
    history.push(`/subscription${QueryService.encodeQueryParams(params)}`);
  }

  render() {
    const {t} = this.props;
    const {loading} = this.state;

    return (
      <>
        {
          !loading && this.activeSubscriptions?.length === 0 && (
            <Text variant="lead">
              {t('account.subscriptions.noSubscriptions')}
            </Text>
          )
        }

        {
          loading && (
            <>
              <Layer className="SubscriptionList"
                     elevation="xs">
                <List>
                  <SkeletonListItem animated/>
                  <SkeletonListItem animated/>
                </List>
              </Layer>
            </>
          )
        }

        {!loading && this.activeSubscriptions && this.activeSubscriptions.length > 0 && (
          <Layer className="SubscriptionList"
                 elevation="xs">
            <List>
              <ListSection title={t('account.subscriptions.activeHeader')}>
              {
                this.activeSubscriptions!.map(subscription => {
                  let location = this.userInfo?.locations?.find(l => l.id === subscription.locationId);

                  return (
                    <ListItem
                      onClick={() => this.openSubscription(subscription)}
                      key={subscription.userPlanId}
                      className="ListItem"
                      contentBefore={
                        <IconCalendar size="xl"/>
                      }
                      primary={
                        <Text variant="lead">
                          {subscription.plan.name || subscription.plan.desc}
                        </Text>
                      }
                      secondary={
                        <Text muted={true}>
                          {t('account.subscriptions.startedAt', {date: new Date(subscription.startDateMs).toLocaleDateString()})}
                          <IconHome/>
                          <Text style={{color: theme.colors.text.default}}>
                            {location ? location!.name : t('account.subscriptions.unknownLocation')}
                          </Text>
                        </Text>
                      }
                      contentAfter={<IconChevronRight />}
                    />
                  );
                })
              }
              </ListSection>
            </List>
          </Layer>
        )}

        {!loading && this.expiredSubscriptions && this.expiredSubscriptions.length > 0 && (
          <>
            <Layer className="SubscriptionList"
                   elevation="xs">
              <List>
                <ListSection title={t('account.subscriptions.expiredHeader')}>
                {
                  this.expiredSubscriptions!.map((subscription, i) => {
                    if (i > 10) {
                      return null;
                    }

                    let location = this.userInfo?.locations?.find(l => l.id === subscription.locationId);

                    return (
                      <ListItem
                        interactive={false}
                        // onClick={() => this.openSubscription(subscription)}
                        key={subscription.userPlanId}
                        className="ListItem"
                        contentBefore={
                          <IconSlash size="xl"/>
                        }
                        primary={
                          <Text variant="lead">
                            {subscription.plan.name || subscription.plan.desc}
                          </Text>
                        }
                        secondary={
                          <Text muted={true}>
                            {
                              subscription.cancelDateMs
                                ? t('account.subscriptions.canceledAt', {date: new Date(subscription.cancelDateMs).toLocaleDateString()})
                                : t('account.subscriptions.expiredAt', {date: new Date(subscription.endDateMs).toLocaleDateString()})
                            }
                            <IconHome/>
                            <Text style={{color: theme.colors.text.default}}>
                              {location ? location!.name : t('account.subscriptions.unknownLocation')}
                            </Text>
                          </Text>
                        }
                        contentAfter={(
                          <>
                            {!!subscription.updatePlan && (
                              <Text variant="subtitle">
                                {t('account.subscriptions.updatingTo', {target: subscription.updatePlan?.name})}
                              </Text>
                            )}
                            {subscription.status === SubscriptionStatus.Initial && (
                              <Text variant="subtitle">
                                {t('account.subscriptions.processing')}
                              </Text>
                            )}
                          </>
                        )}
                      />
                    );
                  })
                }
                </ListSection>
              </List>
            </Layer>
          </>
        )}

        {this.state.error && (
          <Alert
            style={{marginTop: theme.spaces.md}}
            elevation="xs"
            intent="danger"
            title={t(this.state.error.title)}
            subtitle={t(this.state.error.desc)}/>
        )}
      </>
    );
  }
}

interface SubscriptionListProps extends WithTranslation, RouteComponentProps {
}

interface SubscriptionListState {
  error?: {
    title: string,
    desc: string
  }
  loading: boolean;
}

export default withTranslation()(withRouter(SubscriptionList));
