import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: (process.env.NODE_ENV === 'development'),

    // XHR Backend options
    backend: {
      loadPath: '/locales/{{lng}}.json',
      crossDomain: true
    },

    // Only available languages
    //lng: "en",

    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true,
      useSuspense: true
    },

    // Browser's language detection
    detection: {
      // order and from where user language should be detected
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'path'],

      // keys or params to lookup language from
      lookupQuerystring: 'setLng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupFromPathIndex: 0,

      // cache user language on
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'] // languages to not persist (cookie, localStorage)
    }
  });

export default i18n;
