import amexIcon from "../assets/images/cardIcons/amex.svg";
import defaultIcon from "../assets/images/cardIcons/default.svg";
import dinersIcon from "../assets/images/cardIcons/diners.svg";
import discoverIcon from "../assets/images/cardIcons/discover.svg";
import jcbIcon from "../assets/images/cardIcons/jcb.svg";
import maestroIcon from "../assets/images/cardIcons/maestro.svg";
import mastercardIcon from "../assets/images/cardIcons/mastercard.svg";
import unionpayIcon from "../assets/images/cardIcons/unionpay.svg";
import visaIcon from "../assets/images/cardIcons/visa.svg";

class CreditCardService {
  public getCardIconUrlByMask(maskedCard: string): string {
    if (!maskedCard) {
      return defaultIcon;
    }
    return cardIssuers.find(i => i.shortDetectionRegexp.test(maskedCard))?.icon || defaultIcon;
  }
}

export const cardIssuers: Array<CardIssuer> = [
  {
    name: "Visa Electron",
    shortDetectionRegexp: new RegExp("^(4026|4175|4508|4405|4844|491[37])"),
    icon: visaIcon
  },
  {
    name: "Maestro",
    shortDetectionRegexp: new RegExp("^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)"),
    icon: maestroIcon
  },
  {
    name: "Visa",
    shortDetectionRegexp: new RegExp("^4"),
    icon: visaIcon
  },
  {
    name: "AMEX",
    shortDetectionRegexp: new RegExp("^3[47]"),
    icon: amexIcon
  },
  {
    name: "Discover",
    shortDetectionRegexp: new RegExp("^(6011|622[1-9]|65)"),
    icon: discoverIcon
  },
  {
    name: "Diners",
    shortDetectionRegexp: new RegExp("^36"),
    icon: dinersIcon
  },
  {
    name: "Diners - Carte Blanche",
    shortDetectionRegexp: new RegExp("^30[0-5]"),
    icon: dinersIcon
  },
  {
    name: "JCB",
    shortDetectionRegexp: new RegExp("^(35(2[89]|[3-8][0-9])|2131|1800)"),
    icon: jcbIcon
  },
  {
    name: "Dankort",
    shortDetectionRegexp: new RegExp("^(5019)"),
    icon: defaultIcon
  },
  {
    name: "Interpayment",
    shortDetectionRegexp: new RegExp("^(636)"),
    icon: defaultIcon
  },
  {
    name: "Unionpay",
    shortDetectionRegexp: new RegExp("^(62|88)"),
    icon: unionpayIcon
  },
  {
    name: "Mastercard",
    shortDetectionRegexp: new RegExp("^(5[1-5][0-9]{2}|2(22[1-9][0-9]|2[3-9][0-9]{2}|[3-6][0-9]{3}|7[0-1][0-9]{2}|720[0-9]))"),
    icon: mastercardIcon
  }
];

export interface CardIssuer {
  name: string;
  shortDetectionRegexp: RegExp;
  icon?: string;
}

export const creditCardService = new CreditCardService();
