import React, { Component } from 'react';
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import { WithTranslation, withTranslation } from "react-i18next";
import QueryService from "../../services/queryService";
import { CommonUrlParams } from "../../env/models/CommonUrlParams";
import { Alert, Button, Spinner } from "sancho";
import LoginForm from "../../components/login/LoginForm/LoginForm";
import AuthService from "../../services/authService";
import UserService from "../../services/userService";
import { UserInformation } from "@ppc/webcore/dist/data/services/userService";
import "./LoginPage.scss";
import ChooseUser from "../../components/login/ChooseUser/ChooseUser";
import Utils from "../../services/utils";
import theme from "../../theme";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import CenterPageContent from '../../components/structural/CenterPageContent/CenterPageContent';

class LoginPage extends Component<LoginPageProps, LoginPageState> {

  private readonly urlParams: LoginUrlParams;

  constructor(props: LoginPageProps) {
    super(props);

    this.state = {
      uiState: "initial"
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.handleContinueUserButtonClick = this.handleContinueUserButtonClick.bind(this);
    this.handleChooseAnotherUserButtonClick = this.handleChooseAnotherUserButtonClick.bind(this);
    this.handleBackToLoginButtonClick = this.handleBackToLoginButtonClick.bind(this);

    this.urlParams = QueryService.getQueryParams(true) as LoginUrlParams;
  }

  componentDidMount() {
    this.processUrlParams();
  }

  processUrlParams() {
    this.setState(prevState => ({
      ...prevState,
      user: this.urlParams.user
    }));

    if (this.urlParams.tempKey) {
      AuthService.loginByKey(this.urlParams.tempKey)
        .then(() => UserService.getCurrentUser())
        .then((currentUser) => {
          this.setState(prevState => ({
            ...prevState,
            uiState: "loginResult",
            currentUser: currentUser,
            error: undefined
          }));
          this.handleRedirect();
        })
        .catch((err) => {
          console.warn("Temp key in URL params expired or invalid");
          let [errTitle, errDesc] = Utils.parseApiError(err);
          this.setState(prevState => ({
            ...prevState,
            uiState: "error",
            currentUser: undefined,
            error: {
              header: errTitle,
              desc: errDesc
            }
          }));
        });
    } else if (AuthService.isAuthenticated()) {
      UserService.getCurrentUser()
        .then((currentUser) => {
          if (this.urlParams.user && currentUser.user.userName !== this.urlParams.user && currentUser.user.altUsername !== this.urlParams.user) {
            console.warn("We already logged in, but URL 'user' query param is not equal to current user userName");
            AuthService.logout();
            this.setState(prevState => ({
              ...prevState,
              uiState: "login",
              error: undefined
            }));
            return;
          }

          this.setState(prevState => ({
            ...prevState,
            uiState: "chooseUser",
            currentUser: currentUser,
            error: undefined
          }));
        })
        .catch((err) => {
          AuthService.logout();
          if (err && err.resultCode === 2) { // seems current key is expired
            this.setState(prevState => ({
              ...prevState,
              uiState: "login",
              error: undefined
            }));
          } else {
            console.warn("Unexpected error");
            let [errTitle, errDesc] = Utils.parseApiError(err);
            this.setState(prevState => ({
              ...prevState,
              uiState: "error",
              currentUser: undefined,
              error: {
                header: errTitle,
                desc: errDesc
              }
            }));
          }
        });
    } else {
      AuthService.logout();
      this.setState(prevState => ({
        ...prevState,
        uiState: "login",
        error: undefined,
        currentUser: undefined
      }));
    }
  }

  handleRedirect() {
    let {history} = this.props;
    if (this.urlParams.redirectTo) {
      history.push(this.urlParams.redirectTo);
    } else {
      history.push('/account');
    }
  }

  handleLogin() {
    UserService.getCurrentUser()
      .then((currentUser) => {
        this.setState(prevState => ({
          ...prevState,
          uiState: "loginResult",
          currentUser: currentUser
        }));
        this.handleRedirect();
      }, (err) => {
        console.warn("Unable to init user after log in");
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState(prevState => ({
          ...prevState,
          uiState: "error",
          currentUser: undefined,
          error: {
            header: errTitle,
            desc: errDesc
          }
        }));
      });
  }

  handleContinueUserButtonClick() {
    this.setState(prevState => ({
      ...prevState,
      uiState: "loginResult"
    }));
    this.handleRedirect();
  }

  handleChooseAnotherUserButtonClick() {
    AuthService.logout();
    this.setState(prevState => ({
      ...prevState,
      uiState: "login"
    }));
  }

  handleBackToLoginButtonClick() {
    AuthService.logout();
    this.setState(prevState => ({
      ...prevState,
      uiState: "login",
      error: undefined,
      currentUser: undefined
    }));
  }

  render() {
    const {t} = this.props;
    let ui;

    if (this.state.uiState === "initial") {
      ui = (
        <Spinner center delay={100} size="xl"/>
      );
    } else if (this.state.uiState === "loginResult") {
      ui = (
        <Alert
          intent="success"
          title={t(`login.messages.success.header`)}
          subtitle={t(`login.messages.success.desc`, {username: this.state.currentUser!.user.userName})}/>
      );
    } else if (this.state.uiState === "login") {
      ui = (
        <>
          {/*<Text style={{margin: theme.spaces.md}} variant="h1">{t("login.form.header")}</Text>*/}
          <LoginForm onLogin={this.handleLogin} user={this.state.user}/>
        </>
      );
    } else if (this.state.uiState === "chooseUser" && this.state.currentUser) {
      ui = (
        <ChooseUser user={this.state.currentUser}
                    onContinue={this.handleContinueUserButtonClick}
                    onChooseAnother={this.handleChooseAnotherUserButtonClick}/>
      );
    } else { // sure it's error
      ui = (
        <div style={{padding: theme.spaces.md}}>
          <Alert intent="danger"
                 title={this.state.error ? this.state.error.header : t(`login.messages.unexpectedError.header`)}
                 subtitle={this.state.error ? this.state.error.desc : t(`login.messages.unexpectedError.desc`)}
                 style={{marginBottom: theme.spaces.md}}
          />
          <Button size="lg"
                  style={{marginTop: theme.spaces.md}}
                  component="button"
                  block
                  intent="primary"
                  onClick={this.handleBackToLoginButtonClick}>
            {t('login.tryAgainBtn')}
          </Button>
        </div>
      );
    }

    return (
      <BrandedPageShell withLogo>
        <CenterPageContent>
          {ui}
        </CenterPageContent>
      </BrandedPageShell>
    );
  }
}

interface LoginPageProps extends WithTranslation, RouteComponentProps {

}

interface LoginPageState {
  uiState: UiState;
  user?: string;

  currentUser?: UserInformation,

  error?: {
    header: string,
    desc: string
  }
}

type UiState = 'initial' | 'chooseUser' | 'login' | 'loginResult' | 'error';

export interface LoginUrlParams extends CommonUrlParams {
  /**
   * Account username (if provided we'll fill the Username field)
   */
  user?: string;

  /**
   * Redirect to after login
   */
  redirectTo?: string;
}

export default withTranslation()(withRouter(LoginPage));

