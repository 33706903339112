import React from "react";
import { UserInformation } from "@ppc/webcore/dist/data/services/userService";
import { Button, Text } from "sancho";
import { useTranslation } from "react-i18next";
import ButtonContainer from "../../structural/ButtonContainer/ButtonContainer";
import theme from "../../../theme";

function ChooseUser(props: ChooseUserProps) {
  let {t} = useTranslation();
  const userInfo = props.user;
  let userName = (userInfo.user.firstName || userInfo.user.lastName) ?
    `${userInfo.user.firstName} ${userInfo.user.lastName}` :
    `${userInfo.user.email?.email || userInfo.user.userName || userInfo.user.altUsername}`;

  return (
    <div className="ChooseUser"
         style={{marginTop: theme.spaces.md}}>
      <Text variant="lead"
            style={{marginBottom: theme.spaces.lg}}>
        {t('login.choose.header')} <strong>{userName}</strong>.
      </Text>

      <ButtonContainer>
        <Button size="lg"
                component="button"
                intent="primary"
                onClick={props.onContinue}>
          {t('login.choose.chooseUserBtn')}
        </Button>
        <Button size="lg"
                variant="ghost"
                onClick={props.onChooseAnother}>
          {t('login.choose.chooseAnotherBtn')}
        </Button>
      </ButtonContainer>
    </div>
  );
}

interface ChooseUserProps {
  user: UserInformation,
  onContinue: () => void,
  onChooseAnother: () => void
}

export default ChooseUser;
