import WebCore from "./webcoreService";
import { UpdateUserResponse, UserInformation } from "@ppc/webcore/dist/data/services/userService";
import { ApiResponseBase } from "@ppc/webcore/dist/data/models/apiResponseBase";
import { UserModel } from "@ppc/webcore/dist/data/api/app/userAccounts/updateUserApiResponse";
import { NotificationSubscriptions } from "../env/models/NotificationSubscription";

class UserService {

  /**
   * Get current user. Note that promise will not be resolved until you log in.
   * @param {boolean} [force] set it true to get information from server (ignore cached value)
   * @return {Promise<UserInformation>}
   */
  getCurrentUser(force?: boolean): Promise<UserInformation> {
    return WebCore.services.user.getCurrentUserInfo(force);
  }

  /**
   * Updates user information with the data from supplied model
   * @param {UserModel} user Model with data to update with
   * @returns {Promise<UpdateUserResponse>}
   */
  updateCurrentUser(user: UserModel): Promise<UpdateUserResponse> {
    return WebCore.services.user.updateUserInfo(user)
  }

  /**
   * Set preferred location to link OAuth host.
   * See https://presence.atlassian.net/wiki/spaces/APPS/pages/739705065/Supporting+a+specific+location+for+Alexa+Google
   * @param {string} clientId OAuth client id
   * @param {string|number} locationId location id
   */
  setOAuthPreferredLocation(clientId: string, locationId: string | number): Promise<ApiResponseBase> {
    return WebCore.services.user.updateUserProperties([{
      name: `${clientId}-locationId`,
      content: locationId.toString()
    }]);
  }

  /**
   * Gets value of the specified user or system property.
   * @param {string} propertyName
   * @returns {Promise<string>}
   */
  public getUserOrSystemPropertyValue(propertyName: string): Promise<string> {
    return WebCore.services.user.getUserOrSystemPropertyValue(propertyName);
  }

  /**
   * Gets notification subscriptions for specific user.
   * @returns {Promise<NotificationSubscriptions>}
   */
  public getNotificationSubscriptions(): Promise<NotificationSubscriptions> {
    return WebCore.services.messaging.getNotificationSubscriptions();
  }

  /**
   * Updates notification subscription for specific user.
   * @param {number} type Type of notification.
   * @param params Requested parameters.
   * @param {number} [params.userId] User ID for bots and administrators.
   * @param {number} [params.locationId] Location ID.
   * @param {boolean} [params.email] Disable/enable Email notifications.
   * @param {boolean} [params.push] Disable/enable Push notifications.
   * @param {boolean} [params.sms] Disable/enable SMS notifications.
   * @param {number} [params.emailPeriod] Minimum number of seconds between email notifications.
   * @param {number} [params.pushPeriod] Minimum number of seconds between push notifications.
   * @param {number} [params.smsPeriod] Minimum number of seconds between sms notifications.
   * @returns {Promise<ApiResponseBase>}
   */
  public setNotificationSubscriptions(type: number, params?: {
    locationId?: number,
    email?: boolean,
    push?: boolean,
    sms?: boolean,
    emailPeriod?: number,
    pushPeriod?: number,
    smsPeriod?: number
  }): Promise<ApiResponseBase> {
    return WebCore.services.messaging.setNotificationSubscriptions(type, params);
  }

  /**
   * Get promise of current user avatar
   */
  public getUserAvatarUrl(): Promise<string | undefined> {
    return WebCore.services.user.getCurrentUserInfo()
      .then((user) => {
        if (user?.user?.avatarFileId) {
          return WebCore.services.files.getApplicationFileDownloadUrl(user.user.avatarFileId);
        }
      });
  }

}

export default new UserService();
