import React from "react";
import PageHeader from "../PageHeader/PageHeader";

class BrandedPageShell extends React.Component<BrandedPageShellProps> {
  render() {
    let {withLogo, lightLogo, logoLink, title, transparent = false} = this.props;
    return (
      <div className={`BrandedPageShell ${transparent ? '' : 'BackgroundColor'}`}>
        {(!!withLogo || !!title) && (
          <header className="ShellHeader">
            <PageHeader withLogo={withLogo} lightLogo={lightLogo} logoLink={logoLink} title={title}/>
          </header>
        )}
        <main className="ShellContent">
          {this.props.children}
        </main>
      </div>
    );
  }
}

interface BrandedPageShellProps {
  /**
   * Set true to show the logo
   */
  withLogo?: boolean;

  /**
   * Set true to use light version of the logo
   */
  lightLogo?: boolean;

  /**
   * Set custom href link for the logo. Set to falsy-value to remove href link from the logo
   */
  logoLink?: string;

  /**
   * Header text
   */
  title?: string;

  /**
   * Remove background
   */
  transparent?: boolean;
}

export default BrandedPageShell
