import React from 'react';
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import { WithTranslation, withTranslation } from "react-i18next";
import AuthService from "../../services/authService";
import UserService from "../../services/userService";
import { UserInformation } from "@ppc/webcore/dist/data/services/userService";
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from "react-router";
import QueryService from "../../services/queryService";
import { LoginUrlParams } from "../Login/LoginPage";
import { Alert, Button, IconCalendar, IconHome, IconRefreshCcw, IconSlash, Layer, List, ListItem, ListSection, Skeleton, Text } from "sancho";
import CenterPageContent from "../../components/structural/CenterPageContent/CenterPageContent";
import { CommonUrlParams } from "../../env/models/CommonUrlParams";
import { Subscription } from "../../env/models/Subscription";
import { ServicePlans, Subscriptions } from "@ppc/webcore/dist/data/services/subscriptionsService";
import { UserLocation } from "../../env/models/UserLocation";
import { creditCardService } from "../../services/creditCardService";
import { subscriptionsService } from "../../services/subscriptionsService";
import { ServicePlan, ServicePlanPrice } from "../../env/models/ServicePlan";
import TransactionList from "../../components/TransactionList/TransactionList";
import Utils from "../../services/utils";
import ChargifyForm from "../../components/purchase/ChargifyForm/ChargifyForm";
import { SubscriptionStatus } from "@ppc/webcore/dist/data/api/app/paidServices/getLocationSubscriptionsApiResponse";
import ButtonContainer from "../../components/structural/ButtonContainer/ButtonContainer";
import theme from '../../theme';
import HeadInfo from '../../components/HeadInfo/HeadInfo';

class SubscriptionPage extends React.Component<SubscriptionPageProps, SubscriptionPageState> {
  private readonly userPlanId: string;
  private readonly locationId: string;
  private readonly message: string | undefined;
  private readonly fromPage: string | undefined;
  private servicePlans: ServicePlan[] | undefined;
  private servicePlan: ServicePlan | undefined;
  private price: ServicePlanPrice | undefined;
  private upgradeToPlan: ServicePlan | undefined;
  private upgradeToPlanId: string | undefined;
  private upgradeTotalAmount?: string | undefined;
  private subscription: Subscription | undefined;
  private subscriptionLocked: boolean = false;
  private subscriptionUpgradable: boolean = false;
  private subscriptionUpgradePlans: ServicePlan[] | undefined = [];
  private userInfo: UserInformation | undefined;
  private location: UserLocation | undefined;

  private backNavigationListenerDestructor?: () => void;
  private uiStateHistory: Array<SubscriptionPageState["uiState"]> = [];

  private readonly urlParams: SubscriptionPageUrlParams;

  constructor(props: SubscriptionPageProps) {
    super(props);

    this.state = {
      loading: true,
      uiState: "main"
    };
    this.uiStateHistory.push('main');

    this.urlParams = QueryService.getQueryParams(true) as SubscriptionPageUrlParams;
    this.userPlanId = this.urlParams.userPlanId;
    this.locationId = this.urlParams.locationId;
    this.upgradeToPlanId = this.urlParams.upgradeToPlanId;
    this.message = this.urlParams.msg;
    this.fromPage = this.urlParams.from || undefined;
  }

  componentDidMount(): void {
    this.backNavigationListenerDestructor = this.props.history.listen((newLocation, action) => {
      if (action === "POP") {
        if (this.uiStateHistory.length <= 1) {
          this.props.history.goBack();
        } else {
          this.uiStateHistory.pop();
          let lastState = this.uiStateHistory[this.uiStateHistory.length - 1];
          this.setState({uiState: lastState!});
        }
      }
    });

    Promise.all([
      this.initUser(),
      this.initSubscription(),
      this.initPlans()
    ])
      .then(() => {
        if (this.subscription && this.servicePlans) {
          this.servicePlan = this.servicePlans?.find(plan => plan.id === this.subscription!.plan.id);

          this.price = this.servicePlan?.prices.find(p => p.id === this.subscription!.priceId);

          if (this.subscription.upgradableTo && this.subscription.upgradableTo.length > 0) {
            this.subscriptionUpgradePlans = this.servicePlans!
              .filter(plan => this.subscription!.upgradableTo!.some(upgrateToId => upgrateToId === plan.id));
            this.subscriptionUpgradable = this.subscriptionUpgradePlans.length > 0;
          }
        }

        if (this.servicePlans && this.upgradeToPlanId) {
          let upgradeToPlan = this.servicePlans!.find(p => p.id.toString() === this.upgradeToPlanId!.toString());
          if (upgradeToPlan) {
            this.choosePlanForUpgrade(upgradeToPlan);
          }
        }

        if (this.userInfo && this.userInfo.locations && this.subscription && this.subscription.locationId) {
          this.location = this.userInfo.locations.find((l: UserLocation) => l.id === this.subscription!.locationId)
        }
      })
      .then(() => {
        this.setState({
          loading: false,
          error: undefined
        });
      })
  }

  componentWillUnmount() {
    this.backNavigationListenerDestructor && this.backNavigationListenerDestructor();
  }

  UNSAFE_componentWillUpdate(nextProps: Readonly<SubscriptionPageProps>, nextState: Readonly<SubscriptionPageState>, nextContext: any): void {
    let uiState = nextState.uiState;
    if (this.state.uiState !== uiState && (this.uiStateHistory.length === 0 || this.uiStateHistory[this.uiStateHistory.length - 1] !== uiState)) {
      this.uiStateHistory.push(uiState);
      let {history, location} = this.props;
      history.push(`${location.pathname}${QueryService.encodeQueryParams({
        ...QueryService.getQueryParams()
      })}`);
    }
  }

  private initUser(): Promise<any> {
    if (AuthService.isAuthenticated() && !this.urlParams.tempKey) {
      return UserService.getCurrentUser()
        .then((userInfo: UserInformation) => {
          this.userInfo = userInfo;
        }, () => {
          this.redirectToLoginPage();
          return Promise.reject();
        });
    } else {
      this.redirectToLoginPage();
      return Promise.reject();
    }
  }

  private initSubscription(): Promise<any> {
    return subscriptionsService.getSubscription(parseInt(this.userPlanId), parseInt(this.locationId))
      .then((subscriptions: Subscriptions) => {
        if (subscriptions && subscriptions.subscriptions) {
          let subscription = subscriptions.subscriptions
            .find(s => s.userPlanId.toString() === this.userPlanId);
          if (subscription) {
            this.subscription = subscription;
            this.subscriptionLocked = !!subscription.updatePlan || subscription.status !== SubscriptionStatus.DefaultOrActive || subscription.cancelled === true || !!subscription.plan?.freemium;
          }
        }
      });
  }

  private initPlans(): Promise<any> {
    return subscriptionsService.getServicePlans(parseInt(this.locationId))
      .then((plans: ServicePlans) => {
        this.servicePlans = plans?.servicePlans || [];
      })
      .catch((err) => {
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState({
          loading: false,
          uiState: "error",
          error: {
            title: errTitle,
            desc: errDesc
          }
        });
      });
  }

  private redirectToLoginPage() {
    let {location, history} = this.props;
    let redirectTo = location.pathname + QueryService.encodeQueryParams({
      ...this.urlParams,
      k: undefined,
      tempKey: undefined
    });
    let params: LoginUrlParams = {
      redirectTo: redirectTo,
      tempKey: this.urlParams.tempKey || undefined,
      user: this.urlParams.user || undefined
    };
    history.push(`/login${QueryService.encodeQueryParams(params)}`);
  }

  private cancelSubscription() {
    if (this.subscriptionLocked) {
      return;
    }
    this.setState({
      uiState: "cancelConfirmation"
    });
  }

  private cancelSubscriptionConfirmed() {
    this.setState({
      loading: true
    });
    subscriptionsService.cancelSubscription(this.subscription!.userPlanId, this.location!.id)
      .then(() => {
        this.setState({
          loading: false,
          uiState: "cancelSuccess"
        });
      }, (err) => {
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState({
          loading: false,
          uiState: "error",
          error: {
            title: errTitle,
            desc: errDesc
          }
        });
      });
  }

  private upgradeSubscription() {
    if (this.subscriptionLocked || !this.subscriptionUpgradable || !this.subscriptionUpgradePlans || this.subscriptionUpgradePlans.length === 0) {
      return;
    }
    if (this.subscriptionUpgradePlans.length > 1) {
      this.setState({
        uiState: "upgrade"
      });
    } else {
      this.choosePlanForUpgrade(this.subscriptionUpgradePlans[0]);
    }
  }

  private upgradeSubscriptionConfirmed() {
    this.setState({
      loading: true
    });
    subscriptionsService.upgradeSubscription(this.subscription!.userPlanId, this.upgradeToPlan!.id)
      .then(() => {
        this.setState({
          loading: false,
          uiState: "upgradeSuccess"
        });
      }, (err) => {
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState({
          loading: false,
          uiState: "error",
          error: {
            title: errTitle,
            desc: errDesc
          }
        });
      });
  }

  private choosePlanForUpgrade(plan: ServicePlan) {
    if (!plan) {
      return;
    }
    this.upgradeToPlan = plan;
    // Get amount
    if (plan.prices && !!plan.prices.length) {
      this.upgradeTotalAmount = plan.prices[0].totalAmount;
    }
    this.setState({
      uiState: "upgradeConfirmation"
    });
  }

  private editCardInfo() {
    if (this.subscriptionLocked) {
      return;
    }
    this.setState({
      uiState: "editPaymentMethod"
    });
  }

  private goBack() {
    if (this.state.uiState === "main" && !this.fromPage) {
      this.gotoMyAccount();
      return;
    }
    this.props.history.goBack();
  }

  private gotoMyAccount() {
    this.props.history.push('/account');
  }

  private onChargifyTokenReady(token: string) {
    this.setState({
      loading: true
    });
    subscriptionsService.updatePurchaseInfo(
      {paymentToken: token},
      this.subscription!.userPlanId,
      this.location!.id)

      .then(() => {
        this.setState({
          loading: false,
          uiState: "paymentMethodEditSuccess"
        });
      })
      .catch((err) => {
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState({
          loading: false,
          uiState: "error",
          error: {
            title: errTitle,
            desc: errDesc
          }
        });
      });
  }

  private reloadPage() {
    window.location.reload();
  }

  render() {
    const {loading, uiState} = this.state;
    const {location, subscription, servicePlan, price, subscriptionLocked, subscriptionUpgradable, subscriptionUpgradePlans} = this;
    const {t} = this.props;

    let servicePlanPriseStr;
    let subscriptionIssueDate;
    let freePlan = true;
    if (subscription) {
      subscriptionIssueDate = new Date(subscription.issueDateMs).toLocaleDateString();
      if (price && !price.free) {
        freePlan = false;
        servicePlanPriseStr = t('subscription.planMonthlyCost', {
          currencySymbol: price.currencySymbol,
          amount: price.totalAmount
        });
      } else if (price && price.free) {
        servicePlanPriseStr = t('subscription.free');
      }
    }

    let ui;

    if (!loading && !subscription) {
      ui = (
        <Text variant="lead">
          {t('subscription.notFound')}
        </Text>
      );
    } else if (uiState === "main") {
      ui = (
        <>
          {this.message && (
            <Text style={{marginTop: '1em'}} variant="lead">
              {this.message}
            </Text>
          )}
          <div className="SubscriptionBasicInfo">
            <HeadInfo icon="calendar"
                      header={subscription?.plan.name || subscription?.plan.desc}
                      subheader={location?.name || t('subscription.unknownLocation')}
                      subIcon={true}
                      loading={loading}/>

            {/* {!loading && subscriptionLocked && (
              <Text variant="h6">{t('subscription.subscriptionProcessing')}</Text>
            )} */}

            <ButtonContainer>
              {loading && (
                <>
                  <div className="ButtonSkeleton">
                    <Skeleton animated/>
                  </div>
                  <div className="ButtonSkeleton">
                    <Skeleton animated/>
                  </div>
                </>
              )}

              {!loading && subscription && (
                <>
                  {subscriptionUpgradable && (
                    <Button size="lg"
                            component="button"
                            intent="primary"
                            disabled={subscriptionLocked}
                            onClick={() => this.upgradeSubscription()}>
                      {t('subscription.btn.upgrade')}
                    </Button>
                  )}

                  <Button size="lg"
                          component="button"
                          intent="danger"
                          disabled={subscriptionLocked}
                          onClick={() => this.cancelSubscription()}>
                    {t('subscription.btn.cancel')}
                  </Button>

                  <Button size="lg"
                          className="IconOnly"
                          component="button"
                          variant="ghost"
                          onClick={() => this.goBack()}>
                    {t('subscription.btn.back')}
                  </Button>
                </>
              )}
            </ButtonContainer>
          </div>

          <div className="SubscriptionPaymentInfo">

            {/* Credit card information */}
            {!loading && !!subscription && !!subscription.cardMaskedNumber && (
              <Layer elevation="xs">
                <List>
                  <ListItem
                    className="CardInfo"
                    onClick={() => this.editCardInfo()}
                    interactive={false}
                    wrap={false}
                    contentBefore={subscription.cardMaskedNumber && (
                      <img src={creditCardService.getCardIconUrlByMask(subscription.cardMaskedNumber)}
                           className="PaymentProviderIcon"
                           alt="Card Type"/>
                    )}
                    primary={(
                      <Text variant="h5" wrap={false}>
                        {subscription.cardMaskedNumber ?
                          subscription.cardMaskedNumber
                            .replace(/[Xx×🗙⨯⨉✖✕☓╳]/g, "*")
                            .replace(/[_\-‐‑‒—―]/g, " ")
                          : "**** **** **** ****"
                        }
                      </Text>
                    )}
                    secondary={(
                      <Text muted={true}>
                        {t('subscription.cardExpires', {date: subscription?.cardExpirationDate})}
                      </Text>
                    )}
                    contentAfter={
                      <Button className="DisplayOnly"
                              component="button"
                              variant="outline"
                              intent="none"
                              disabled={subscriptionLocked}
                              onClick={() => this.editCardInfo()}>
                        {t('subscription.btn.editCardInfo')}
                      </Button>
                    }
                  />
                </List>
              </Layer>
            )}

            {/* Subscription description and issue date */}
            {!!servicePlan && !!subscription && (
              <div className="AmountInfo">
                <Text variant="lead" gutter={false}>
                  {servicePlan.desc || subscription.plan.desc}
                </Text>
                <Text muted={true}>
                  {!freePlan ? (
                      <>
                        {t("subscription.purchasedAt", {date: subscriptionIssueDate})} {servicePlanPriseStr}
                      </>) :
                    t("subscription.startedAt", {date: subscriptionIssueDate})
                  }
                </Text>
              </div>
            )}

          </div>

          {!!subscription && !!location && (
            <TransactionList locationId={location.id} userServicePlanId={subscription.userPlanId}/>
          )}
        </>
      );
    } else if (uiState === "upgrade") {
      ui = (
        <>
          <Layer className="UpgradeToPlansList"
                 elevation="xs">
            <List>
              <ListSection title={t('account.subscriptions.activeHeader')}>
                {subscriptionUpgradePlans!.map(plan => (
                  <ListItem key={plan.id}
                            className="ListItem"
                            contentBefore={
                              <IconCalendar/>
                            }
                            primary={plan.name}
                            secondary={plan.desc}
                            onClick={() => this.choosePlanForUpgrade(plan)}/>
                ))}
              </ListSection>
            </List>
          </Layer>
        </>
      );
    } else if (uiState === "upgradeConfirmation") {
      ui = (
        <>
          <div className="SubscriptionBasicInfo">
            <div className="SubscriptionInfoContainer">
              <div className="IconContainer MarginFix">
                <IconRefreshCcw size="xl"/>
              </div>

              <div className="TextContainer">
                <>
                  <Text variant="uppercase">{t("subscription.upgrade.newPlan")}</Text>
                  <Text variant="h3">
                    {this.upgradeToPlan!.name || this.upgradeToPlan!.desc}
                  </Text>
                  <Text variant="h5">
                    <IconHome size="md"/>
                    {location?.name || t('subscription.unknownLocation')}
                  </Text>
                  <div className="TotalAmountBadge">
                    <Text>{t("subscription.upgrade.amount", {amount: this.upgradeTotalAmount || "0"})}</Text>
                  </div>
                </>
              </div>
            </div>
          </div>

          <Text variant='lead' style={{marginTop: theme.spaces.lg}}>
            {t('subscription.upgradeConfirmation')}
          </Text>
          <ButtonContainer>
            <Button size="lg"
                    component="button"
                    intent="primary"
                    onClick={() => this.upgradeSubscriptionConfirmed()}>
              {t('subscription.btn.yes')}
            </Button>
            <Button size="lg"
                    variant="ghost"
                    loading={loading}
                    onClick={() => this.setState({uiState: "main"})}>
              {t('subscription.btn.no')}
            </Button>
          </ButtonContainer>
        </>
      );
    } else if (uiState === "upgradeSuccess") {
      ui = (
        <>
          <Text variant='lead'>{t('subscription.upgradeSuccess', {target: this.upgradeToPlan!.name})}</Text>
          <ButtonContainer>
            <Button size="lg"
                    component="button"
                    intent="primary"
                    onClick={() => this.gotoMyAccount()}>
              {t('subscription.btn.ok')}
            </Button>
          </ButtonContainer>
        </>
      );
    } else if (uiState === "cancelConfirmation") {
      ui = (
        <>
          <div className="SubscriptionBasicInfo">
            <div className="SubscriptionInfoContainer">
              <div className="IconContainer">
                <IconSlash size="xl"/>
              </div>

              <div className="TextContainer">
                <>
                  <Text variant="uppercase">{t("subscription.cancelPlan")}</Text>
                  <Text variant="h3">
                    {subscription?.plan.name || subscription?.plan.desc}
                  </Text>
                  <Text variant="h5">
                    <IconHome size="md"/>
                    {location?.name || t('subscription.unknownLocation')}
                  </Text>
                </>
              </div>
            </div>
          </div>

          <Text variant='lead' style={{marginTop: theme.spaces.lg}}>
            {t('subscription.cancelConfirmation')}
          </Text>
          <ButtonContainer>
            <Button size="lg"
                    component="button"
                    intent="danger"
                    onClick={() => this.cancelSubscriptionConfirmed()}>
              {t('subscription.btn.yes')}
            </Button>
            <Button size="lg"
                    variant="ghost"
                    loading={loading}
                    onClick={() => this.setState({uiState: "main"})}>
              {t('subscription.btn.no')}
            </Button>
          </ButtonContainer>
        </>
      );
    } else if (uiState === "cancelSuccess") {
      ui = (
        <>
          <Text variant='display3'>{t('subscription.cancelSuccess')}</Text>
          <ButtonContainer>
            <Button size="lg"
                    component="button"
                    intent="primary"
                    onClick={() => this.gotoMyAccount()}>
              {t('subscription.btn.ok')}
            </Button>
          </ButtonContainer>
        </>
      );
    } else if (uiState === "paymentMethodEditSuccess") {
      ui = (
        <>
          <Text variant='display3'>{t('subscription.paymentMethodEditSuccess')}</Text>
          <ButtonContainer>
            <Button size="lg"
                    component="button"
                    intent="primary"
                    onClick={() => this.reloadPage()}>
              {t('subscription.btn.ok')}
            </Button>
          </ButtonContainer>
        </>
      );
    } else if (uiState === "editPaymentMethod") {
      ui = (
        <>
          <ChargifyForm header={t('subscription.editPaymentMethod')}
                        onTokenReceived={(token) => this.onChargifyTokenReady(token)}
                        onBack={() => this.goBack()}
                        chargifySite={this.price!.site!}/>
        </>
      );
    } else if (uiState === "error") {
      ui = (
        <>
          <Alert intent="danger"
                 title={this.state.error?.title}
                 subtitle={this.state.error?.desc}
                 elevation="xs"/>

          <ButtonContainer>
            <Button size="lg"
                    component="button"
                    intent="primary"
                    onClick={() => this.goBack()}>
              {t('subscription.btn.back')}
            </Button>
          </ButtonContainer>
        </>
      );
    }

    return (
      <BrandedPageShell>
        <CenterPageContent>
          {ui}
        </CenterPageContent>
      </BrandedPageShell>
    );
  }
}

interface SubscriptionPageProps extends WithTranslation, RouteComponentProps {

}

interface SubscriptionPageState {
  loading: boolean;
  uiState: 'main' | 'upgrade' | 'cancelConfirmation' | 'upgradeConfirmation' | 'upgradeSuccess' | 'cancelSuccess' | 'error' | 'editPaymentMethod' | 'paymentMethodEditSuccess';
  error?: {
    title: string,
    desc?: string
  }
}

export interface SubscriptionPageUrlParams extends CommonUrlParams {
  /**
   * Account username
   */
  user?: string;

  userPlanId: string; // but it's a number
  msg?: string;
  upgradeToPlanId?: string; // but it's a number

  locationId: string; // but it's a number
  from?: string; // from page
}

export default withTranslation()(withRouter(SubscriptionPage));
