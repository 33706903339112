import React, { Component } from 'react';
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import { WithTranslation, withTranslation } from "react-i18next";
import QueryService from "../../services/queryService";
import { CommonUrlParams } from "../../env/models/CommonUrlParams";
import "./OAuthHost.scss";
import { Alert, Button, IconChevronRight, IconHome, Layer, ListSection, List, ListItem, Spinner, Text } from "sancho";
import AuthService from "../../services/authService";
import UserService from "../../services/userService";
import { OAUTH_CLIENTS } from "./OAuthClients";
import theme from "../../theme";
import Utils from "../../services/utils";
import utils from "../../services/utils";
import { UserInformation } from "@ppc/webcore/dist/data/services/userService";
import { UserLocation } from "../../env/models/UserLocation";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import CenterPageContent from "../../components/structural/CenterPageContent/CenterPageContent";
import { brandingService } from '../../services/brandingService';
import ButtonContainer from "../../components/structural/ButtonContainer/ButtonContainer";

class OAuthHostPage extends Component<OAuthHostPageProps, OAuthHostPageState> {

  private readonly urlParams: OAuthHostUrlParams;

  private backNavigationListenerDestructor?: () => void;

  private uiStateHistory: Array<OAuthHostPageState["uiState"]> = [];

  constructor(props: OAuthHostPageProps) {
    super(props);

    this.state = {
      uiState: "initial",
      loading: false
    };

    this.urlParams = QueryService.getQueryParams() as OAuthHostUrlParams;
  }

  translateWithFallback(keys: string[], configObj?: Object) {
    const {t, i18n} = this.props;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (i18n.exists(key)) {
        return t(key, configObj);
      }
    }
    return "";
  }

  componentDidMount() {
    const {t, location, history} = this.props;

    this.backNavigationListenerDestructor = this.props.history.listen((newLocation, action) => {
      if (action === "POP") {
        if (this.uiStateHistory.length <= 1) {
          this.props.history.goBack();
        } else {
          this.uiStateHistory.pop();
          let lastState = this.uiStateHistory[this.uiStateHistory.length - 1];
          this.setState({uiState: lastState!});
        }
      }
    });

    if (this.urlParams.resultCode) {
      if (this.urlParams.resultCode === "0") {
        this.setState(prevState => ({
          ...prevState,
          uiState: "message",
          message: this.urlParams.resultCodeMessage || t('oauth.host.error.0'),
          messageDesc: undefined
        }));
      } else {
        this.setState(prevState => ({
          ...prevState,
          uiState: "error",
          message: this.urlParams.resultCodeMessage || this.translateWithFallback([`oauth.host.error.${this.urlParams.resultCode}`, 'oauth.host.error.default']),
          messageDesc: undefined
        }));
      }
      return;
    }

    // check URL params validity
    if (!this.urlParams.client_id || !this.urlParams.response_type) {
      console.warn(`'client_id' or 'response_type' URL params is missing`);
      this.setState(prevState => ({
        ...prevState,
        uiState: "error",
        message: t('oauth.host.error.url'),
        messageDesc: undefined
      }));
      return;
    }

    if (AuthService.isAuthenticated()) {
      UserService.getCurrentUser()
        .then((user) => {
          this.setState(prevState => {
            let newState: OAuthHostPageState = {
              ...prevState,
              uiState: "approve",
              loading: false,
              message: undefined,
              messageDesc: undefined,
              user: user
            };
            if (user.locations) {
              newState.uiState = "selectLocation";
              if (this.urlParams.location_id) {
                newState.selectedLocation = user.locations.find(l => l.id.toString() === this.urlParams.location_id);
              }
              if (!newState.selectedLocation && user.locations.length === 1) {
                newState.selectedLocation = user.locations[0];
              }
            }

            if (newState.selectedLocation || !user.locations || user.locations.length === 1) {
              newState.uiState = "approve";
            }

            return newState;
          });
        }, (error) => {
          console.warn("Unable to get current user", error);
          history.push(`/login?redirectTo=${encodeURIComponent(location.pathname + location.search)}`);
        });
    } else {
      history.push(`/login?redirectTo=${encodeURIComponent(location.pathname + location.search)}`);
    }
  }

  componentWillUnmount() {
    this.backNavigationListenerDestructor && this.backNavigationListenerDestructor();
  }

  UNSAFE_componentWillUpdate(nextProps: Readonly<OAuthHostPageProps>, nextState: Readonly<OAuthHostPageState>, nextContext: any): void {
    let uiState = nextState.uiState;
    if (uiState !== 'initial' && this.state.uiState !== uiState && (this.uiStateHistory.length === 0 || this.uiStateHistory[this.uiStateHistory.length - 1] !== uiState)) {
      this.uiStateHistory.push(uiState);
      let {history, location} = this.props;
      history.push(`${location.pathname}${QueryService.encodeQueryParams({
        ...QueryService.getQueryParams(),
        location_id: nextState.selectedLocation?.id
      })}`);
    }
  }

  handleLocationSelect(location: UserLocation) {
    if (!location) {
      return;
    }
    this.setState(prevState => ({
      ...prevState,
      uiState: "approve",
      selectedLocation: location
    }));
  }

  handleApproveButtonClick(approved: boolean) {
    if (this.state.loading) {
      return;
    }

    this.setState(prevState => ({
      ...prevState,
      loading: true
    }));

    let redirectUrl: string;
    AuthService.getTempToken()
      .then((loginInfo) =>
        AuthService.getUrlToApproveOrDenyAuthorization(approved, {
          clientId: this.urlParams.client_id,
          responseType: this.urlParams.response_type,
          apiKey: loginInfo.key,
          state: this.urlParams.state,
          locationId: this.state.selectedLocation?.id,
          brand: brandingService.currentBrand.id
        }))
      .then((url: string) => {
        redirectUrl = url;
      })
      .then(() => {
        if (approved && this.state.selectedLocation) {
          return UserService.setOAuthPreferredLocation(this.urlParams.client_id, this.state.selectedLocation.id)
        }
      })
      .then(() => {
        window.location.replace(redirectUrl);
      })
      .catch((err: any) => {
        let [errTitle, errDesc] = Utils.parseApiError(err);
        this.setState(prevState => ({
          ...prevState,
          loading: false,
          uiState: "error",
          message: errTitle,
          messageDesc: errDesc
        }));
      });
  }

  render() {
    const {t} = this.props;

    let ui;

    let clientName = this.urlParams.client_name;
    const clientId = this.urlParams.client_id;
    let clientLogoUrl;
    let clientTitle;
    let clientDesc;
    let appName;
    let logoAndDesc;

    if (clientId && OAUTH_CLIENTS[clientId]) {
      clientLogoUrl = OAUTH_CLIENTS[clientId].logo;
      clientName = clientName || OAUTH_CLIENTS[clientId].name;
      clientTitle = t(`oauth.host.${OAUTH_CLIENTS[clientId].i18nKey}.title`);
      clientDesc = t(`oauth.host.${OAUTH_CLIENTS[clientId].i18nKey}.desc`);
      appName = brandingService.brandName;

      logoAndDesc = (
        <>
          {
            clientLogoUrl && (
              <div className="OAuthLogoContainer">
                <img src={clientLogoUrl} alt={`${clientName || clientId} logo`}/>
              </div>
            )
          }
        </>
      );
    }

    if (this.state.uiState === "initial") {
      ui = (
        <Spinner center delay={100} size="xl"/>
      );
    } else if (this.state.uiState === "selectLocation") {
      ui = (
        <>
          {logoAndDesc}
          <Text style={{marginTop: theme.spaces.md}} variant="h5">
            {t('oauth.host.selectLocation.title', {clientName: clientName || clientId})}
            <Text variant="paragraph"
                  style={{marginTop: theme.spaces.xs}}>
              {t('oauth.host.selectLocation.subtitle', {appName: appName})}
            </Text>
          </Text>

          <Layer className="LocationsList"
                 elevation="xs">
            <ListSection className="LocationsSection"
                         title={t('purchase.availableLocations')}>
              <List>
                {this.state.user!.locations!.map(l => (
                  <ListItem className="ListItem OverflowHidden"
                            key={l.id}
                            primary={l.name}
                            secondary={utils.getLocationAddressString(l)}
                            onPress={() => this.handleLocationSelect(l)}
                            contentBefore={<IconHome size="xl"/>}
                            contentAfter={<IconChevronRight/>}/>
                ))}
              </List>
            </ListSection>
          </Layer>
        </>
      );
    } else if (this.state.uiState === "approve") {
      ui = (
        <>
          {logoAndDesc}
          <Text style={{marginTop: theme.spaces.md}} variant="h5">
            {t('oauth.host.approve.title', {clientName: clientName || clientId})}
            <Text variant="paragraph">{t('oauth.host.approve.subtitle', {appName: appName})}</Text>
          </Text>
          {clientTitle && (
            <Alert style={{marginBottom: theme.spaces.md}}
                    intent="warning" 
                    title={clientTitle}
                    subtitle={clientDesc}
                    elevation="xs"/>
          )}
          <ButtonContainer>
            <Button size="lg"
                    component="button"
                    intent="primary"
                    onClick={() => this.handleApproveButtonClick(true)}
                    loading={this.state.loading}>
              {t('oauth.host.approve.btn')}
            </Button>
            <Button size="lg"
                    variant="ghost"
                    className="IconOnly"
                    onClick={() => this.handleApproveButtonClick(false)}>
              {t('oauth.host.approve.denyBtn')}
            </Button>
          </ButtonContainer>
        </>
      );
    } else if (this.state.uiState === "message") {
      ui = (
        <Alert intent="info"
               title={this.state.message}
               subtitle={this.state.messageDesc}
               elevation="xs"/>
      );
    } else { // error
      ui = (
        <Alert intent="danger"
               title={this.state.message}
               subtitle={this.state.messageDesc}
               elevation="xs"/>
      );
    }

    return (
      <BrandedPageShell>
        <CenterPageContent>
          <div className="OAuthHost">
            {ui}
          </div>
        </CenterPageContent>
      </BrandedPageShell>
    );
  }
}

interface OAuthHostPageProps extends WithTranslation, RouteComponentProps {

}

interface OAuthHostPageState {
  uiState: 'initial' | 'selectLocation' | 'approve' | 'message' | 'error';
  message?: string;
  messageDesc?: string;
  loading: boolean;
  user?: UserInformation;
  selectedLocation?: UserLocation;
}

interface OAuthHostUrlParams extends CommonUrlParams {

  /**
   * OAuth client name to put into Web page
   */
  client_name?: string;

  /**
   * Mandatory third-party app ID to pass to Authorisation API
   */
  client_id: string;

  /**
   * Optional location ID
   */
  location_id?: string

  /**
   * OAuth client verification code to pass to Authorisation API
   */
  state: string;

  /**
   * only "code" currently used
   */
  response_type: string;

  /**
   * Result code
   */
  resultCode: string;

  /**
   * Result code message
   */
  resultCodeMessage: string;
}

export default withTranslation()(withRouter(OAuthHostPage));

