import { Position } from "toasted-notes";
import { Theme } from 'sancho';
import React from 'react';

class ToastService {

  private onToastShowCbs: Array<OnToastShowCallback> = [];

  show(toast: ToastSettings) {
    toast = {
      intent: "info",
      duration: 8000,
      position: "top-right",
      ...toast,
    };

    this.onToastShowCbs.forEach((cb) => {
      try {
        cb(toast);
      } catch (ex) {
        console.error(ex);
      }
    });
  }

  /**
   * Subscribe on toast show event.
   * Do not use this function to show toasts - use `ToastService.show()` instead
   * @param {OnToastShowCallback} callback callback called on every toast show
   * @return {() => void} unsubscribe function
   */
  onToastShow(callback: OnToastShowCallback): () => void {
    this.onToastShowCbs.push(callback);
    return () => {
      this.onToastShowCbs = this.onToastShowCbs.filter(cb => cb !== callback);
    }
  }
}

export type OnToastShowCallback = (toast: ToastSettings) => void;

export interface RenderArgs {
  id: string;
  onClose: () => void;
}

export interface ToastSettings {
  position?: keyof typeof Position;
  duration?: number | null;
  title?: string;
  subtitle?: string;
  theme?: Theme;
  intent?: AlertIntentions;
  render?: (options: RenderArgs) => React.ReactNode;
}

export type AlertIntentions =
  | "info"
  | "success"
  | "question"
  | "danger"
  | "warning";

export const toastService = new ToastService();
