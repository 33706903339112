import theme from "../theme";
import utils from "./utils";
import { TypedEvent } from "../env/typescript/TypedEvent";

class MediaQueryService {
  private xsMedia = window.matchMedia(`(max-width: ${theme.breakpoints.sm})`);
  private smMedia = window.matchMedia(`(min-width: ${theme.breakpoints.sm}) and (max-width: ${theme.breakpoints.md})`);
  private mdMedia = window.matchMedia(`(min-width: ${theme.breakpoints.md}) and (max-width: ${theme.breakpoints.lg})`);
  private lgMedia = window.matchMedia(`(min-width: ${theme.breakpoints.lg}) and (max-width: ${theme.breakpoints.xl})`);
  private xlMedia = window.matchMedia(`(min-width: ${theme.breakpoints.xl})`);

  private _screenSize: ScreenSize = "md";
  private mediaChangedEvent: TypedEvent<ScreenSize> = new TypedEvent<ScreenSize>();

  get screenSize(): ScreenSize {
    return this._screenSize;
  }

  constructor() {
    let debouncedInitScreenSize = utils.debounce(this.initScreenSize.bind(this), 50);

    if (!!this.xsMedia.addEventListener) { // if modern browser
      ["xs", "sm", "md", "lg", "xl"]
        .forEach((m) => {
          // @ts-ignore
          let mediaList: MediaQueryList = this[`${m}Media`];
          mediaList.addEventListener('change', (mm) => {
            debouncedInitScreenSize();
          });
        });
    } else {
      window.onresize = () => {
        debouncedInitScreenSize();
      }
    }
    debouncedInitScreenSize();
  }

  /**
   * Subscribe for on screen size change event
   * @param cb callback function
   * @return unsubscribe function
   */
  public onChange(cb: (screenSize: ScreenSize) => void): () => void {
    this.mediaChangedEvent.on(cb);
    return () => {
      this.mediaChangedEvent.off(cb);
    }
  }

  private initScreenSize() {
    let newSize: ScreenSize = "md";
    if (this.xsMedia.matches) {
      newSize = "xs";
    } else if (this.smMedia.matches) {
      newSize = "sm";
    } else if (this.mdMedia.matches) {
      newSize = "md";
    } else if (this.lgMedia.matches) {
      newSize = "lg";
    } else if (this.xlMedia.matches) {
      newSize = "xl";
    }

    if (newSize !== this._screenSize) {
      this._screenSize = newSize;
      // console.log(`Screen size changed to ${this._screenSize}`);
      this.mediaChangedEvent.emit(this._screenSize);
    }
  }

}

export type ScreenSize = "xs" | "sm" | "md" | "lg" | "xl";

export const mediaQueryService = new MediaQueryService();
