import React from "react";

/**
 * Simple form controller. Allows to control natural form workflow - validation, dirty checks, etc.
 */
export class FormController {
  /**
   * true until some field was changed or form submitted
   */
  get pristine(): boolean {
    return !this._submitted && Object.values(this.fields).every(fieldController => fieldController.pristine);
  }

  /**
   * Is form is dirty (is form fields has some non-default value)
   */
  get dirty(): boolean {
    return Object.values(this.fields).some(fieldController => fieldController.dirty);
  }

  /**
   * Is form was submitted (at least once)
   */
  private _submitted: boolean = false;
  get submitted(): boolean {
    return this._submitted;
  }

  /**
   * Is form valid
   */
  get valid(): boolean {
    return this._validationMessages.length === 0
      && Object.values(this.fields).every(fieldController => fieldController.valid);
  }

  /**
   * List of form validation messages. Note that fields has their own validation messages.
   */
  private _validationMessages: string[] = [];
  get validationMessages(): string[] {
    return [...this._validationMessages];
  }

  /**
   * List of fields
   */
  readonly fields: { [fieldName: string]: FieldController<any> } = {};

  private readonly _validators: FormValidator[];
  private readonly _onSubmitCallback: (form: FormController) => void;

  constructor(options: FormControllerOptions) {
    Object.entries(options.fields).forEach(([fieldName, fieldOptions]) => {
      this.fields[fieldName] = new FieldController({
        name: fieldName,
        form: this,
        defaultValue: fieldOptions.value,
        validators: fieldOptions.validators || []
      });
    });
    this._validators = options.validators || [];
    this._onSubmitCallback = options.onSubmit;

    this.validate();
  }

  submit(event?: React.FormEvent<HTMLFormElement>) {
    event?.preventDefault();
    this._submitted = true;
    if (!this.validate()) {
      return;
    }
    this._onSubmitCallback(this);
  }

  validate(): boolean {
    this._validationMessages = [];
    this._validators?.forEach(v => {
      if (!v.validate(this)) {
        this._validationMessages.push(v.message);
      }
    });

    Object.values(this.fields)
      .forEach(fieldController => fieldController.validate());

    return this.valid;
  }

  /**
   * Set form as pristine. Marks form as unsubmitted
   */
  setPristine() {
    this._submitted = false;
    Object.values(this.fields)
      .forEach(fieldController => fieldController.setPristine());
  }

  /**
   * Clear form fields
   */
  clear() {
    Object.values(this.fields)
      .forEach(fieldController => fieldController.clear(true));
    this.validate();
  }

  /**
   * Clear form and make it pristine and non-submitted
   */
  drop() {
    Object.values(this.fields)
      .forEach(fieldController => fieldController.drop(true));
    this._submitted = false;
    this.validate();
  }
}

export class FieldController<T extends FieldTypes> {

  /**
   * true until field was changed at least once
   */
  private _pristine: boolean = true;
  get pristine(): boolean {
    return this._pristine;
  }

  /**
   * true if field has some non-default value
   */
  get dirty(): boolean {
    return this._value !== this._defaultValue;
  }

  /**
   * Field value
   */
  private _value: T;
  get value(): T {
    return this._value;
  }

  set value(value: T) {
    this._pristine = false;
    this._value = value;
    this._form.validate();
  }

  /**
   * Check if form field is valid
   */
  get valid(): boolean {
    return this._validationMessages.length === 0;
  }

  /**
   * List of validation messages
   */
  private _validationMessages: string[] = [];
  get validationMessages(): string[] {
    return [...this._validationMessages];
  }

  /**
   * Field default value;
   */
  private readonly _defaultValue: T;
  get defaultValue(): T {
    return this._defaultValue;
  }

  private readonly _name: string;
  get name(): string {
    return this._name;
  }

  private readonly _form: FormController;
  private readonly _validators: FieldValidator<T>[];

  constructor(options: FieldControllerOptions<T>) {
    this._defaultValue = options.defaultValue;
    this._form = options.form;
    this._validators = options.validators || [];
    this._value = options.defaultValue;
    this._name = options.name;
  }

  /**
   * Clear form field
   * @param {boolean} noValidation skip validation
   */
  clear(noValidation?: boolean) {
    this._value = this._defaultValue;
    if (!noValidation) {
      this._form.validate();
    }
  }

  /**
   * Clear field and make it pristine
   * @param {boolean} noValidation skip validation
   */
  drop(noValidation?: boolean) {
    this._value = this._defaultValue;
    this._pristine = true;
    if (!noValidation) {
      this._form.validate();
    }
  }

  /**
   * Validate field
   * @return true if valid
   */
  validate(): boolean {
    this._validationMessages = [];
    this._validators?.forEach(v => {
      if (!v.validate(this, this._form)) {
        this._validationMessages.push(v.message);
      }
    });
    return this.valid;
  }

  setPristine() {
    this._pristine = true;
  }
}

interface FieldControllerOptions<T extends FieldTypes> {
  form: FormController,
  name: string;
  defaultValue: T;
  validators?: FieldValidator<T>[];
}

export interface FieldValidator<T extends FieldTypes> {
  /**
   * Validator function. Should return true if field is valid
   */
  validate: (fieldCtrl: FieldController<T>, formCtrl: FormController) => boolean;

  /**
   * Validation message
   */
  message: string;
}


export interface FormValidator {
  /**
   * Validator function. Should return true if form is valid
   */
  validate: (formCtrl: FormController) => boolean;

  /**
   * Validation message
   */
  message: string;
}

export interface FormControllerOptions {
  onSubmit: (form: FormController) => void;
  fields: { [fieldName: string]: FormControllerFieldOptions<any> };
  validators?: FormValidator[];
}

export interface FormControllerFieldOptions<T extends FieldTypes> {
  value: T;
  validators?: FieldValidator<T>[];
}

export type FieldTypes = string | number | boolean;

export default FormController;
