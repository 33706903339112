import React, { Component } from "react";
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import { WithTranslation, withTranslation } from "react-i18next";
import QueryService from "../../services/queryService";
import { CommonUrlParams } from "../../env/models/CommonUrlParams";
import { Alert, Spinner } from "sancho";
import PasswordRenewRequestForm from "../../components/login/PasswordRenewRequestForm/PasswordRenewRequestForm";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import CenterPageContent from "../../components/structural/CenterPageContent/CenterPageContent";

class PasswordRenewRequestPage extends Component<PasswordRenewRequestPageProps, PasswordRenewRequestPageState> {

  private readonly urlParams: PasswordRenewRequestUrlParams;

  constructor(props: PasswordRenewRequestPageProps) {
    super(props);

    this.state = {
      uiState: "initial"
    };

    this.urlParams = QueryService.getQueryParams() as PasswordRenewRequestUrlParams;

    this.onPasswordRenew = this.onPasswordRenew.bind(this);
  }

  componentDidMount() {
    this.setState(prevState => ({
      ...prevState,
      uiState: "renewPassword",
      user: this.urlParams.user
    }));
  }

  onPasswordRenew() {
    this.setState(prevState => ({
      ...prevState,
      uiState: "renewResult"
    }));
    this.handleRedirect();
  }

  handleRedirect() {
    if (this.urlParams && this.urlParams.redirectTo) {
      this.props.history.push(this.urlParams.redirectTo);
    }
  }

  render() {
    const {t} = this.props;
    let ui;

    if (this.state.uiState === "initial") {
      ui = (
        <Spinner center delay={100} size="xl"/>
      );
    } else if (this.state.uiState === "renewResult") {
      ui = (
        <>
          <Alert
            intent="success"
            title={t(`passwordRenewRequest.messages.success.header`)}
            subtitle={t(`passwordRenewRequest.messages.success.desc`)}
            elevation="xs"/>
        </>
      );
    } else if (this.state.uiState === "renewPassword") {
      ui = (
        <>
          {/* <Text style={{margin: theme.spaces.md}} variant="h1">{t("passwordRenewRequest.form.header")}</Text> */}
          <PasswordRenewRequestForm onRenew={this.onPasswordRenew} user={this.state.user}/>
        </>
      );
    }

    return (
      <BrandedPageShell withLogo>
        <CenterPageContent>
          {ui}
        </CenterPageContent>
      </BrandedPageShell>
    );
  }
}

interface PasswordRenewRequestPageProps extends WithTranslation, RouteComponentProps {

}

interface PasswordRenewRequestPageState {
  uiState: UiState;
  user?: string;
}

type UiState = 'initial' | 'renewPassword' | 'renewResult';

interface PasswordRenewRequestUrlParams extends CommonUrlParams {
  /**
   * Account username (if provided we'll fill the Username field)
   */
  user?: string;

  /**
   * URL ro redirect to after password reset
   */
  redirectTo?: string;
}

export default withTranslation()(withRouter(PasswordRenewRequestPage));
