import React from "react";
import "./CenterPageContent.scss";

class CenterPageContent extends React.Component<CenterPageContentProps> {
  render() {
    return (
      <div className="CenterPageContent">
        <div className="Center">
          <div className="Content">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

interface CenterPageContentProps {
}

export default CenterPageContent;
