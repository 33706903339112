import React, { Component } from 'react';
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import { WithTranslation, withTranslation } from "react-i18next";
import QueryService from "../../services/queryService";
import { CommonUrlParams } from "../../env/models/CommonUrlParams";
import { Alert, Text } from "sancho";
import "./OAuthResult.scss";
import { OAUTH_HOSTS } from "./OAuthHosts";
import CenterPageContent from '../../components/structural/CenterPageContent/CenterPageContent';

class OAuthResultPage extends Component<OAuthResultPageProps> {

  private readonly urlParams: OAuthResultUrlParams;

  constructor(props: OAuthResultPageProps) {
    super(props);

    this.urlParams = QueryService.getQueryParams() as OAuthResultUrlParams;
  }

  componentDidMount() {

  }

  translateWithFallback(keys: string[], configObj?: Object) {
    const {t, i18n} = this.props;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (i18n.exists(key)) {
        return t(key, configObj);
      }
    }
    return "";
  }

  render() {
    // const {t} = this.props;

    let host = OAUTH_HOSTS[this.urlParams.appId];

    let i18nKey = (host && host.i18nKey) || "default";
    let logoUrl = host && host.logo;
    let appName = host && host.name;
    let resultCode = this.urlParams.resultCode;
    let resultCodeMessage = this.urlParams.resultCodeMessage;

    let title = this.translateWithFallback([`oauth.result.${i18nKey}.title`, `oauth.result.default.title`], {appName: appName});

    let ui;
    if (resultCode === '0') {
      let success = this.translateWithFallback([`oauth.result.${i18nKey}.success`, `oauth.result.default.success`], {appName: appName});
      if (resultCodeMessage) {
        ui = (<Alert intent="success" title={resultCodeMessage} subtitle={success}/>);
      } else {
        ui = (<Alert intent="success" title={success}/>);
      }
    } else {
      let errorMessageHeader = resultCodeMessage || this.translateWithFallback([
        `oauth.result.${i18nKey}.error.header`,
        `oauth.result.default.error.header`,
      ], {appName: appName});
      let errorMessage = this.translateWithFallback([
        `oauth.result.${i18nKey}.error.${resultCode}`,
        `oauth.result.${i18nKey}.error.default`,
        `oauth.result.default.error.${resultCode}`,
        `oauth.result.default.error.default`
      ], {appName: appName});

      ui = (
        <Alert intent="danger" title={errorMessageHeader} subtitle={errorMessage}/>
      );
    }

    return (
      <BrandedPageShell>
        <CenterPageContent>
          <div className="OAuthResult">
            {
              logoUrl && (
                <div className="OAuthLogoContainer">
                  <img src={logoUrl} alt={`${appName} logo`}/>
                </div>
              )
            }
            <Text variant="h5">{title}</Text>
            {ui}
          </div>
        </CenterPageContent>
      </BrandedPageShell>
    );
  }
}

interface OAuthResultPageProps extends WithTranslation {

}

interface OAuthResultUrlParams extends CommonUrlParams {
  /**
   * Third-party application ID for logo and description
   */
  appId: string;

  /**
   * Result code (e.g. 400, 401, 500)
   */
  resultCode?: string;

  /**
   * Message
   */
  resultCodeMessage?: string;
}

export default withTranslation()(OAuthResultPage);

