import React from 'react';
import BrandedPageShell from "../../components/structural/BrandedPageShell/BrandedPageShell";
import googlePlayBadge from "../../assets/images/google-play-badge.svg";
import appStoreBadge from "../../assets/images/app-store-badge.svg";
import { brandingService } from "../../services/brandingService";
import { WithTranslation, withTranslation } from "react-i18next";
import AuthService from "../../services/authService";
import UserService from "../../services/userService";
import { UserInformation } from "@ppc/webcore/dist/data/services/userService";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import CenterPageContent from "../../components/structural/CenterPageContent/CenterPageContent";

class HomePage extends React.Component<HomePageProps, HomePageState> {
  constructor(props: HomePageProps) {
    super(props);

    this.state = {
      loading: true,
      isAuthenticated: false
    };
  }

  componentDidMount(): void {
    this.initUser();
  }

  private initUser(): Promise<any> {
    if (AuthService.isAuthenticated()) {
      return UserService.getCurrentUser()
        .then((userInfo: UserInformation) => {
          this.setState({isAuthenticated: true, loading: false});
        }, (error) => {
          this.setState({isAuthenticated: false, loading: false});
          //return Promise.reject();
        });
    } else {
      this.setState({isAuthenticated: false, loading: false});
      return Promise.resolve();
    }
  }

  private gotoAccount() {
    let {history} = this.props;
    history.push(`/account`);
  }

  private gotoLogin() {
    let {history} = this.props;
    history.push(`/login`);
  }

  render() {
    return (
      <>
        <div className="FullScreenBackgroundContainer">
          <div className="FullScreenBackground"/>
          <div className="FullScreenBackgroundOverlay">
            <BrandedPageShell withLogo lightLogo transparent>
              <CenterPageContent>
                <div className="StoreBadgesContainer">
                  <div className="Badge">
                    <a href={brandingService.currentBrand.googlePlayHref}>
                      <img src={googlePlayBadge} alt="Download mobile app on Google Play"/>
                    </a>
                  </div>
                  <div className="Badge">
                    <a href={brandingService.currentBrand.appStoreHref}>
                      <img src={appStoreBadge} alt="Download mobile app on App Store"/>
                    </a>
                  </div>
                </div>
              </CenterPageContent>
            </BrandedPageShell>
          </div>
        </div>
      </>
    );
  }
}

interface HomePageProps extends WithTranslation, RouteComponentProps {

}

interface HomePageState {
  loading: boolean;
  isAuthenticated: boolean;
}


export default withTranslation()(withRouter(HomePage));
