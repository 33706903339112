import React from "react";

class ButtonContainer extends React.Component<ButtonContainerProps> {
  render() {
    let {children, className = "", align = 'right', style = 'row'} = this.props;
    let classes = [
      "ButtonContainerComponent",
      `Style-${style}`,
      `Align-${align}`,
      className
    ].join(" ");

    return (
      <div className={classes}>
        {children}
      </div>
    );
  }
}

interface ButtonContainerProps {
  className?: string;

  /**
   * Button alignment
   */
  align?: 'left' | 'center' | 'right';

  /**
   * Button style
   */
  style?: 'row' | 'column' | 'full-width-column';
}

export default ButtonContainer
