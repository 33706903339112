/**
 * Applications that require access to our app, key is client_id,
 * Used at /oauth to get the permissions
 * @type {object}
 */
export const OAUTH_CLIENTS: OAuthClients = {
  "AmazonEcho": {
    name: "Amazon Echo",
    i18nKey: "alexa",
    logo: "/assets/images/oauth/logo-alexa.svg"
  },
  "AmazonEchoPepco": {
    name: "Amazon Echo",
    i18nKey: "alexa",
    logo: "/assets/images/oauth/logo-alexa.svg"
  },
  "AmazonEchoDelmarva": {
    name: "Amazon Echo",
    i18nKey: "alexa",
    logo: "/assets/images/oauth/logo-alexa.svg"
  },
  "AmazonEchoBGE": {
    name: "Amazon Echo",
    i18nKey: "alexa",
    logo: "/assets/images/oauth/logo-alexa.svg"
  },
  "GoogleHome": {
    name: "Google Home",
    i18nKey: "google",
    logo: "/assets/images/oauth/logo-google.svg"
  },
  "GoogleHomePepco": {
    name: "Google Home",
    i18nKey: "google",
    logo: "/assets/images/oauth/logo-google.svg"
  },
  "GoogleHomeDelmarva": {
    name: "Google Home",
    i18nKey: "google",
    logo: "/assets/images/oauth/logo-google.svg"
  },
  "GoogleHomeBGE": {
    name: "Google Home",
    i18nKey: "google",
    logo: "/assets/images/oauth/logo-google.svg"
  }
};

export interface OAuthClients {
  [clientName: string]: {
    /**
     * OAuth client logo URL
     */
    logo: string

    /**
     * Translation key for OAuth client
     */
    i18nKey: string

    /**
     * OAuth client human-readable name
     */
    name: string;
  }
}
