import { KeyboardEvent } from "react";
import { UserLocation } from "../env/models/UserLocation";

class Utils {

  /**
   * Parse API error and return human-readable error
   * @param {[string, string]} error tuple of translation keys for error title and description
   */
  parseApiError(error: undefined | { resultCode?: number, resultCodeDesc?: string, resultCodeMessage?: string, response?: { status?: number } }): [string, string] {
    if (error) {
      console.warn(error);
    }

    if (error && error.resultCode != null) {
      if (error.resultCodeDesc || error.resultCodeMessage) {
        return [error.resultCodeDesc || '', error.resultCodeMessage || ''];
      }
      return [`errorCodes.${error.resultCode}.title`, `errorCodes.${error.resultCode}.desc`];
    } else {
      return ["errorCodes.unknown.title", "errorCodes.unknown.desc"];
    }
  }

  /**
   * Checks if Enter key was pressed. Use this function with `onKeyPress={myCallback}`
   * @param {KeyboardEvent} event
   */
  ifEnterPressed(event: KeyboardEvent) {
    return event && !event.defaultPrevented && (event.key === "Enter" || event.which === 13 || event.keyCode === 13);
  }

  getLocationAddressString(location: UserLocation): string {
    return [
      location.addrStreet1,
      location.addrStreet2,
      location.addrCity,
      location.state ? location.state.name : undefined,
      location.zip,
      location.country ? location.country.name : undefined
    ]
      .filter(i => !!i)
      .join(', ');
  }

  debounce<F extends Function>(func: F, wait: number): F {
    let timeoutID: number;

    if (!Number.isInteger(wait)) {
      wait = 100;
    }

    // conversion through any necessary as it wont satisfy criteria otherwise
    return function (this: any, ...args: any[]) {
      clearTimeout(timeoutID);
      const context = this;

      timeoutID = window.setTimeout(function () {
        func.apply(context, args);
      }, wait);
    } as any;
  };

  isSbox(): boolean {
    const sboxSynonyms: string[] = [
      'localhost',
      '127.0.0.1',
      '0.0.0.0',
      '::',
      'family.presencepro.com'
    ];
    return process.env.NODE_ENV === 'development' || sboxSynonyms.some((s) => window.location.hostname.indexOf(s) !== -1);
  };
}

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default new Utils();
